/* eslint-disable */
// source: user_proto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_proto_pb = require('./common_proto_pb.js');
goog.object.extend(proto, common_proto_pb);
goog.exportSymbol('proto.AccountUserMessage', null, global);
goog.exportSymbol('proto.AddStationUserRequest', null, global);
goog.exportSymbol('proto.AddStationUserResponse', null, global);
goog.exportSymbol('proto.AuthUserMessage', null, global);
goog.exportSymbol('proto.ChangePasswordUserRequest', null, global);
goog.exportSymbol('proto.ChangePasswordUserResponse', null, global);
goog.exportSymbol('proto.ChannelState', null, global);
goog.exportSymbol('proto.ControlLaneUserRequest', null, global);
goog.exportSymbol('proto.ControlLaneUserResponse', null, global);
goog.exportSymbol('proto.CreateNewAccountUserRequest', null, global);
goog.exportSymbol('proto.CreateNewAccountUserResponse', null, global);
goog.exportSymbol('proto.DeleteAccessoryUserRequest', null, global);
goog.exportSymbol('proto.DeleteAccessoryUserResponse', null, global);
goog.exportSymbol('proto.DeleteAccountUserRequest', null, global);
goog.exportSymbol('proto.DeleteAccountUserResponse', null, global);
goog.exportSymbol('proto.DeleteStationUserRequest', null, global);
goog.exportSymbol('proto.DeleteStationUserResponse', null, global);
goog.exportSymbol('proto.DeviceControlUserRequest', null, global);
goog.exportSymbol('proto.DeviceControlUserResponse', null, global);
goog.exportSymbol('proto.DeviceOnchangeUserMessage', null, global);
goog.exportSymbol('proto.DeviceStateModel', null, global);
goog.exportSymbol('proto.DeviceUserMessage', null, global);
goog.exportSymbol('proto.EditAccessoryUserRequest', null, global);
goog.exportSymbol('proto.EditAccessoryUserResponse', null, global);
goog.exportSymbol('proto.EditNumberCardUserRequest', null, global);
goog.exportSymbol('proto.EditNumberCardUserResponse', null, global);
goog.exportSymbol('proto.ErrorStatusUserMessage', null, global);
goog.exportSymbol('proto.GetAccessTokenUserRequest', null, global);
goog.exportSymbol('proto.GetAccessTokenUserResponse', null, global);
goog.exportSymbol('proto.GetAccessoriesUserRequest', null, global);
goog.exportSymbol('proto.GetAccessoriesUserResponse', null, global);
goog.exportSymbol('proto.GetAccountsUserRequest', null, global);
goog.exportSymbol('proto.GetAccountsUserResponse', null, global);
goog.exportSymbol('proto.GetCoordinateUserRequest', null, global);
goog.exportSymbol('proto.GetCoordinateUserResponse', null, global);
goog.exportSymbol('proto.GetDeviceUserRequest', null, global);
goog.exportSymbol('proto.GetDeviceUserResponse', null, global);
goog.exportSymbol('proto.GetLatestFirmwareUserRequest', null, global);
goog.exportSymbol('proto.GetLatestFirmwareUserResponse', null, global);
goog.exportSymbol('proto.GetLogFileUserRequest', null, global);
goog.exportSymbol('proto.GetLogFileUserResponse', null, global);
goog.exportSymbol('proto.GetMeterInDayUserRequest', null, global);
goog.exportSymbol('proto.GetMeterInDayUserResponse', null, global);
goog.exportSymbol('proto.GetMeterInMonthUserRequest', null, global);
goog.exportSymbol('proto.GetMeterInMonthUserResponse', null, global);
goog.exportSymbol('proto.GetMeterInYearUserRequest', null, global);
goog.exportSymbol('proto.GetMeterInYearUserResponse', null, global);
goog.exportSymbol('proto.GetScheduleUserRequest', null, global);
goog.exportSymbol('proto.GetScheduleUserResponse', null, global);
goog.exportSymbol('proto.GetStationListUserRequest', null, global);
goog.exportSymbol('proto.GetStationListUserResponse', null, global);
goog.exportSymbol('proto.GetStationUserRequest', null, global);
goog.exportSymbol('proto.GetStationUserResponse', null, global);
goog.exportSymbol('proto.InsertAccessoryUserRequest', null, global);
goog.exportSymbol('proto.InsertAccessoryUserResponse', null, global);
goog.exportSymbol('proto.LogUpdateUserMessage', null, global);
goog.exportSymbol('proto.MeterItemType', null, global);
goog.exportSymbol('proto.RequireStreamUserRequest', null, global);
goog.exportSymbol('proto.RequireStreamUserResponse', null, global);
goog.exportSymbol('proto.SetActiveTimeUserRequest', null, global);
goog.exportSymbol('proto.SetActiveTimeUserResponse', null, global);
goog.exportSymbol('proto.SetCoordinateUserRequest', null, global);
goog.exportSymbol('proto.SetCoordinateUserResponse', null, global);
goog.exportSymbol('proto.SetCycleConfigUserRequest', null, global);
goog.exportSymbol('proto.SetCycleConfigUserResponse', null, global);
goog.exportSymbol('proto.SetSignSettingUserRequest', null, global);
goog.exportSymbol('proto.SetSignSettingUserResponse', null, global);
goog.exportSymbol('proto.StateOnChangeUserMessage', null, global);
goog.exportSymbol('proto.StationUserMessage', null, global);
goog.exportSymbol('proto.SyncTimeUserRequest', null, global);
goog.exportSymbol('proto.SyncTimeUserResponse', null, global);
goog.exportSymbol('proto.UpdateAlertInfoUserRequest', null, global);
goog.exportSymbol('proto.UpdateAlertInfoUserResponse', null, global);
goog.exportSymbol('proto.UpdateDeviceConnectionState', null, global);
goog.exportSymbol('proto.UpdateDiaryLogUserRequest', null, global);
goog.exportSymbol('proto.UpdateDiaryLogUserResponse', null, global);
goog.exportSymbol('proto.UpdateErrorHandlingUserRequest', null, global);
goog.exportSymbol('proto.UpdateMeterDataUserMessage', null, global);
goog.exportSymbol('proto.UpdateModeUserMessage', null, global);
goog.exportSymbol('proto.UpdatePowerStateUserMessage', null, global);
goog.exportSymbol('proto.UpdateScheduleUserMessage', null, global);
goog.exportSymbol('proto.UpdateScheduleUserRequest', null, global);
goog.exportSymbol('proto.UpdateScheduleUserResponse', null, global);
goog.exportSymbol('proto.UserLoginRequest', null, global);
goog.exportSymbol('proto.UserLoginResponse', null, global);
goog.exportSymbol('proto.UserMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChannelState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChannelState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChannelState.displayName = 'proto.ChannelState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceStateModel = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DeviceStateModel.repeatedFields_, null);
};
goog.inherits(proto.DeviceStateModel, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceStateModel.displayName = 'proto.DeviceStateModel';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserLoginRequest.displayName = 'proto.UserLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserLoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.UserLoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserLoginResponse.displayName = 'proto.UserLoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAccessTokenUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetAccessTokenUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAccessTokenUserRequest.displayName = 'proto.GetAccessTokenUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAccessTokenUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.GetAccessTokenUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAccessTokenUserResponse.displayName = 'proto.GetAccessTokenUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AuthUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AuthUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AuthUserMessage.displayName = 'proto.AuthUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequireStreamUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequireStreamUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequireStreamUserRequest.displayName = 'proto.RequireStreamUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequireStreamUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.RequireStreamUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequireStreamUserResponse.displayName = 'proto.RequireStreamUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlLaneUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ControlLaneUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlLaneUserRequest.displayName = 'proto.ControlLaneUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlLaneUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.ControlLaneUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlLaneUserResponse.displayName = 'proto.ControlLaneUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetActiveTimeUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SetActiveTimeUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetActiveTimeUserRequest.displayName = 'proto.SetActiveTimeUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetActiveTimeUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.SetActiveTimeUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetActiveTimeUserResponse.displayName = 'proto.SetActiveTimeUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetCycleConfigUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SetCycleConfigUserRequest.repeatedFields_, null);
};
goog.inherits(proto.SetCycleConfigUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetCycleConfigUserRequest.displayName = 'proto.SetCycleConfigUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetCycleConfigUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SetCycleConfigUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetCycleConfigUserResponse.displayName = 'proto.SetCycleConfigUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetCoordinateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SetCoordinateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetCoordinateUserRequest.displayName = 'proto.SetCoordinateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetCoordinateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SetCoordinateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetCoordinateUserResponse.displayName = 'proto.SetCoordinateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceControlUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceControlUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceControlUserRequest.displayName = 'proto.DeviceControlUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceControlUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceControlUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceControlUserResponse.displayName = 'proto.DeviceControlUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StateOnChangeUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StateOnChangeUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StateOnChangeUserMessage.displayName = 'proto.StateOnChangeUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateDeviceConnectionState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateDeviceConnectionState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateDeviceConnectionState.displayName = 'proto.UpdateDeviceConnectionState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.LogUpdateUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.LogUpdateUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.LogUpdateUserMessage.displayName = 'proto.LogUpdateUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ErrorStatusUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ErrorStatusUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ErrorStatusUserMessage.displayName = 'proto.ErrorStatusUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdatePowerStateUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdatePowerStateUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdatePowerStateUserMessage.displayName = 'proto.UpdatePowerStateUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateScheduleUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateScheduleUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateScheduleUserMessage.displayName = 'proto.UpdateScheduleUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateModeUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateModeUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateModeUserMessage.displayName = 'proto.UpdateModeUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateMeterDataUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateMeterDataUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateMeterDataUserMessage.displayName = 'proto.UpdateMeterDataUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceOnchangeUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceOnchangeUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceOnchangeUserMessage.displayName = 'proto.DeviceOnchangeUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SyncTimeUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SyncTimeUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SyncTimeUserRequest.displayName = 'proto.SyncTimeUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SyncTimeUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.SyncTimeUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SyncTimeUserResponse.displayName = 'proto.SyncTimeUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDeviceUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetDeviceUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDeviceUserRequest.displayName = 'proto.GetDeviceUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetDeviceUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.GetDeviceUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetDeviceUserResponse.displayName = 'proto.GetDeviceUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AddStationUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AddStationUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AddStationUserRequest.displayName = 'proto.AddStationUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AddStationUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.AddStationUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AddStationUserResponse.displayName = 'proto.AddStationUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetCoordinateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetCoordinateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetCoordinateUserRequest.displayName = 'proto.GetCoordinateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetCoordinateUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.GetCoordinateUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetCoordinateUserResponse.displayName = 'proto.GetCoordinateUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMeterInYearUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetMeterInYearUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMeterInYearUserRequest.displayName = 'proto.GetMeterInYearUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMeterInYearUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetMeterInYearUserResponse.repeatedFields_, null);
};
goog.inherits(proto.GetMeterInYearUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMeterInYearUserResponse.displayName = 'proto.GetMeterInYearUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMeterInMonthUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetMeterInMonthUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMeterInMonthUserRequest.displayName = 'proto.GetMeterInMonthUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMeterInMonthUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetMeterInMonthUserResponse.repeatedFields_, null);
};
goog.inherits(proto.GetMeterInMonthUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMeterInMonthUserResponse.displayName = 'proto.GetMeterInMonthUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMeterInDayUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetMeterInDayUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMeterInDayUserRequest.displayName = 'proto.GetMeterInDayUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetMeterInDayUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GetMeterInDayUserResponse.repeatedFields_, null);
};
goog.inherits(proto.GetMeterInDayUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetMeterInDayUserResponse.displayName = 'proto.GetMeterInDayUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUserMessage.displayName = 'proto.DeviceUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetStationUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetStationUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetStationUserRequest.displayName = 'proto.GetStationUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetStationUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetStationUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetStationUserResponse.displayName = 'proto.GetStationUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetStationListUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetStationListUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetStationListUserRequest.displayName = 'proto.GetStationListUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetStationListUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.GetStationListUserResponse.repeatedFields_, null);
};
goog.inherits(proto.GetStationListUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetStationListUserResponse.displayName = 'proto.GetStationListUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetSignSettingUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SetSignSettingUserRequest.repeatedFields_, null);
};
goog.inherits(proto.SetSignSettingUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetSignSettingUserRequest.displayName = 'proto.SetSignSettingUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetSignSettingUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.SetSignSettingUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetSignSettingUserResponse.displayName = 'proto.SetSignSettingUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetLatestFirmwareUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetLatestFirmwareUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetLatestFirmwareUserRequest.displayName = 'proto.GetLatestFirmwareUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetLatestFirmwareUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.GetLatestFirmwareUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetLatestFirmwareUserResponse.displayName = 'proto.GetLatestFirmwareUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetLogFileUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetLogFileUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetLogFileUserRequest.displayName = 'proto.GetLogFileUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetLogFileUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.GetLogFileUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetLogFileUserResponse.displayName = 'proto.GetLogFileUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteStationUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteStationUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteStationUserRequest.displayName = 'proto.DeleteStationUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteStationUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeleteStationUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteStationUserResponse.displayName = 'proto.DeleteStationUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EditNumberCardUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EditNumberCardUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EditNumberCardUserRequest.displayName = 'proto.EditNumberCardUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EditNumberCardUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.EditNumberCardUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EditNumberCardUserResponse.displayName = 'proto.EditNumberCardUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateErrorHandlingUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateErrorHandlingUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateErrorHandlingUserRequest.displayName = 'proto.UpdateErrorHandlingUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateDiaryLogUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateDiaryLogUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateDiaryLogUserRequest.displayName = 'proto.UpdateDiaryLogUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateDiaryLogUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.UpdateDiaryLogUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateDiaryLogUserResponse.displayName = 'proto.UpdateDiaryLogUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InsertAccessoryUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InsertAccessoryUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InsertAccessoryUserRequest.displayName = 'proto.InsertAccessoryUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InsertAccessoryUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.InsertAccessoryUserResponse.repeatedFields_, null);
};
goog.inherits(proto.InsertAccessoryUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InsertAccessoryUserResponse.displayName = 'proto.InsertAccessoryUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAccessoriesUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetAccessoriesUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAccessoriesUserRequest.displayName = 'proto.GetAccessoriesUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAccessoriesUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.GetAccessoriesUserResponse.repeatedFields_, null);
};
goog.inherits(proto.GetAccessoriesUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAccessoriesUserResponse.displayName = 'proto.GetAccessoriesUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteAccessoryUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteAccessoryUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteAccessoryUserRequest.displayName = 'proto.DeleteAccessoryUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteAccessoryUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.DeleteAccessoryUserResponse.repeatedFields_, null);
};
goog.inherits(proto.DeleteAccessoryUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteAccessoryUserResponse.displayName = 'proto.DeleteAccessoryUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EditAccessoryUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.EditAccessoryUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EditAccessoryUserRequest.displayName = 'proto.EditAccessoryUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.EditAccessoryUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.EditAccessoryUserResponse.repeatedFields_, null);
};
goog.inherits(proto.EditAccessoryUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.EditAccessoryUserResponse.displayName = 'proto.EditAccessoryUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateScheduleUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateScheduleUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateScheduleUserRequest.displayName = 'proto.UpdateScheduleUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateScheduleUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.UpdateScheduleUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateScheduleUserResponse.displayName = 'proto.UpdateScheduleUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetScheduleUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetScheduleUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetScheduleUserRequest.displayName = 'proto.GetScheduleUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetScheduleUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.GetScheduleUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetScheduleUserResponse.displayName = 'proto.GetScheduleUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StationUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StationUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StationUserMessage.displayName = 'proto.StationUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAccountsUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetAccountsUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAccountsUserRequest.displayName = 'proto.GetAccountsUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetAccountsUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.GetAccountsUserResponse.repeatedFields_, null);
};
goog.inherits(proto.GetAccountsUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetAccountsUserResponse.displayName = 'proto.GetAccountsUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteAccountUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeleteAccountUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteAccountUserRequest.displayName = 'proto.DeleteAccountUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeleteAccountUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeleteAccountUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeleteAccountUserResponse.displayName = 'proto.DeleteAccountUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateNewAccountUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.CreateNewAccountUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateNewAccountUserRequest.displayName = 'proto.CreateNewAccountUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.CreateNewAccountUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.CreateNewAccountUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.CreateNewAccountUserResponse.displayName = 'proto.CreateNewAccountUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangePasswordUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ChangePasswordUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangePasswordUserRequest.displayName = 'proto.ChangePasswordUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ChangePasswordUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.ChangePasswordUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ChangePasswordUserResponse.displayName = 'proto.ChangePasswordUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateAlertInfoUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateAlertInfoUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateAlertInfoUserRequest.displayName = 'proto.UpdateAlertInfoUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateAlertInfoUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.UpdateAlertInfoUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateAlertInfoUserResponse.displayName = 'proto.UpdateAlertInfoUserResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AccountUserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AccountUserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AccountUserMessage.displayName = 'proto.AccountUserMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserMessage.displayName = 'proto.UserMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChannelState.prototype.toObject = function(opt_includeInstance) {
  return proto.ChannelState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChannelState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChannelState.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: jspb.Message.getFieldWithDefault(msg, 1, 0),
    counter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lightstate: jspb.Message.getFieldWithDefault(msg, 3, 0),
    walkingstate: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mappingstate: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChannelState}
 */
proto.ChannelState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChannelState;
  return proto.ChannelState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChannelState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChannelState}
 */
proto.ChannelState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCounter(value);
      break;
    case 3:
      var value = /** @type {!proto.TrafficLightState} */ (reader.readEnum());
      msg.setLightstate(value);
      break;
    case 4:
      var value = /** @type {!proto.WalkingState} */ (reader.readEnum());
      msg.setWalkingstate(value);
      break;
    case 5:
      var value = /** @type {!proto.TrafficLightState} */ (reader.readEnum());
      msg.setMappingstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChannelState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChannelState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChannelState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChannelState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndex();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCounter();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getLightstate();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getWalkingstate();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMappingstate();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional uint32 index = 1;
 * @return {number}
 */
proto.ChannelState.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ChannelState} returns this
 */
proto.ChannelState.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 counter = 2;
 * @return {number}
 */
proto.ChannelState.prototype.getCounter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ChannelState} returns this
 */
proto.ChannelState.prototype.setCounter = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional TrafficLightState lightState = 3;
 * @return {!proto.TrafficLightState}
 */
proto.ChannelState.prototype.getLightstate = function() {
  return /** @type {!proto.TrafficLightState} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.TrafficLightState} value
 * @return {!proto.ChannelState} returns this
 */
proto.ChannelState.prototype.setLightstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional WalkingState walkingState = 4;
 * @return {!proto.WalkingState}
 */
proto.ChannelState.prototype.getWalkingstate = function() {
  return /** @type {!proto.WalkingState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.WalkingState} value
 * @return {!proto.ChannelState} returns this
 */
proto.ChannelState.prototype.setWalkingstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional TrafficLightState mappingState = 5;
 * @return {!proto.TrafficLightState}
 */
proto.ChannelState.prototype.getMappingstate = function() {
  return /** @type {!proto.TrafficLightState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.TrafficLightState} value
 * @return {!proto.ChannelState} returns this
 */
proto.ChannelState.prototype.setMappingstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DeviceStateModel.repeatedFields_ = [3,4,5,7,16,23,27];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceStateModel.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceStateModel.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceStateModel} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceStateModel.toObject = function(includeInstance, msg) {
  var f, obj = {
    connectionstate: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numPhase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    signalList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    mappingList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    countdownList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    outputSensorList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    walkingSignal: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    railwaySignal: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    cpuActiveMode: jspb.Message.getFieldWithDefault(msg, 10, 0),
    idxTimeForm: jspb.Message.getFieldWithDefault(msg, 13, 0),
    idxTimeline: jspb.Message.getFieldWithDefault(msg, 14, 0),
    idxCycleForm: jspb.Message.getFieldWithDefault(msg, 15, 0),
    lanestateList: jspb.Message.toObjectList(msg.getLanestateList(),
    common_proto_pb.LaneState.toObject, includeInstance),
    cputime: jspb.Message.getFieldWithDefault(msg, 17, ""),
    laneactive: jspb.Message.getFieldWithDefault(msg, 18, 0),
    cpuonoff: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    iswalkingsignalenable: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    israilwaysignalenable: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    cputype: jspb.Message.getFieldWithDefault(msg, 22, ""),
    sidetimeList: jspb.Message.toObjectList(msg.getSidetimeList(),
    common_proto_pb.OneSideLampTime.toObject, includeInstance),
    clearancetime: jspb.Message.getFieldWithDefault(msg, 24, 0),
    activebegin: jspb.Message.getFieldWithDefault(msg, 25, ""),
    activeend: jspb.Message.getFieldWithDefault(msg, 26, ""),
    channelstateList: jspb.Message.toObjectList(msg.getChannelstateList(),
    proto.ChannelState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceStateModel}
 */
proto.DeviceStateModel.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceStateModel;
  return proto.DeviceStateModel.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceStateModel} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceStateModel}
 */
proto.DeviceStateModel.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ConnectionState} */ (reader.readEnum());
      msg.setConnectionstate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumPhase(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setSignalList(value);
      break;
    case 4:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setMappingList(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setCountdownList(value);
      break;
    case 7:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setOutputSensorList(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWalkingSignal(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRailwaySignal(value);
      break;
    case 10:
      var value = /** @type {!proto.MTFCMainWorkState} */ (reader.readEnum());
      msg.setCpuActiveMode(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdxTimeForm(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdxTimeline(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIdxCycleForm(value);
      break;
    case 16:
      var value = new common_proto_pb.LaneState;
      reader.readMessage(value,common_proto_pb.LaneState.deserializeBinaryFromReader);
      msg.addLanestate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCputime(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLaneactive(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCpuonoff(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIswalkingsignalenable(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsrailwaysignalenable(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCputype(value);
      break;
    case 23:
      var value = new common_proto_pb.OneSideLampTime;
      reader.readMessage(value,common_proto_pb.OneSideLampTime.deserializeBinaryFromReader);
      msg.addSidetime(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClearancetime(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivebegin(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveend(value);
      break;
    case 27:
      var value = new proto.ChannelState;
      reader.readMessage(value,proto.ChannelState.deserializeBinaryFromReader);
      msg.addChannelstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceStateModel.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceStateModel.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceStateModel} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceStateModel.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnectionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getNumPhase();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSignalList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
  f = message.getMappingList();
  if (f.length > 0) {
    writer.writePackedUint32(
      4,
      f
    );
  }
  f = message.getCountdownList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
  f = message.getOutputSensorList();
  if (f.length > 0) {
    writer.writePackedUint32(
      7,
      f
    );
  }
  f = message.getWalkingSignal();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRailwaySignal();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getCpuActiveMode();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getIdxTimeForm();
  if (f !== 0) {
    writer.writeUint32(
      13,
      f
    );
  }
  f = message.getIdxTimeline();
  if (f !== 0) {
    writer.writeUint32(
      14,
      f
    );
  }
  f = message.getIdxCycleForm();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getLanestateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      common_proto_pb.LaneState.serializeBinaryToWriter
    );
  }
  f = message.getCputime();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLaneactive();
  if (f !== 0) {
    writer.writeUint32(
      18,
      f
    );
  }
  f = message.getCpuonoff();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getIswalkingsignalenable();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getIsrailwaysignalenable();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getCputype();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSidetimeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      common_proto_pb.OneSideLampTime.serializeBinaryToWriter
    );
  }
  f = message.getClearancetime();
  if (f !== 0) {
    writer.writeUint32(
      24,
      f
    );
  }
  f = message.getActivebegin();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getActiveend();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getChannelstateList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      27,
      f,
      proto.ChannelState.serializeBinaryToWriter
    );
  }
};


/**
 * optional ConnectionState connectionState = 1;
 * @return {!proto.ConnectionState}
 */
proto.DeviceStateModel.prototype.getConnectionstate = function() {
  return /** @type {!proto.ConnectionState} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ConnectionState} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setConnectionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional uint32 num_phase = 2;
 * @return {number}
 */
proto.DeviceStateModel.prototype.getNumPhase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setNumPhase = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated uint32 signal = 3;
 * @return {!Array<number>}
 */
proto.DeviceStateModel.prototype.getSignalList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setSignalList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.addSignal = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.clearSignalList = function() {
  return this.setSignalList([]);
};


/**
 * repeated uint32 mapping = 4;
 * @return {!Array<number>}
 */
proto.DeviceStateModel.prototype.getMappingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setMappingList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.addMapping = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.clearMappingList = function() {
  return this.setMappingList([]);
};


/**
 * repeated uint32 countdown = 5;
 * @return {!Array<number>}
 */
proto.DeviceStateModel.prototype.getCountdownList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setCountdownList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.addCountdown = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.clearCountdownList = function() {
  return this.setCountdownList([]);
};


/**
 * repeated uint32 output_sensor = 7;
 * @return {!Array<number>}
 */
proto.DeviceStateModel.prototype.getOutputSensorList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setOutputSensorList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.addOutputSensor = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.clearOutputSensorList = function() {
  return this.setOutputSensorList([]);
};


/**
 * optional bool walking_signal = 8;
 * @return {boolean}
 */
proto.DeviceStateModel.prototype.getWalkingSignal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setWalkingSignal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool railway_signal = 9;
 * @return {boolean}
 */
proto.DeviceStateModel.prototype.getRailwaySignal = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setRailwaySignal = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional MTFCMainWorkState cpu_active_mode = 10;
 * @return {!proto.MTFCMainWorkState}
 */
proto.DeviceStateModel.prototype.getCpuActiveMode = function() {
  return /** @type {!proto.MTFCMainWorkState} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.MTFCMainWorkState} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setCpuActiveMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional uint32 idx_time_form = 13;
 * @return {number}
 */
proto.DeviceStateModel.prototype.getIdxTimeForm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setIdxTimeForm = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional uint32 idx_timeline = 14;
 * @return {number}
 */
proto.DeviceStateModel.prototype.getIdxTimeline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setIdxTimeline = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional uint32 idx_cycle_form = 15;
 * @return {number}
 */
proto.DeviceStateModel.prototype.getIdxCycleForm = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setIdxCycleForm = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * repeated LaneState laneState = 16;
 * @return {!Array<!proto.LaneState>}
 */
proto.DeviceStateModel.prototype.getLanestateList = function() {
  return /** @type{!Array<!proto.LaneState>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.LaneState, 16));
};


/**
 * @param {!Array<!proto.LaneState>} value
 * @return {!proto.DeviceStateModel} returns this
*/
proto.DeviceStateModel.prototype.setLanestateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.LaneState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.LaneState}
 */
proto.DeviceStateModel.prototype.addLanestate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.LaneState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.clearLanestateList = function() {
  return this.setLanestateList([]);
};


/**
 * optional string cpuTime = 17;
 * @return {string}
 */
proto.DeviceStateModel.prototype.getCputime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setCputime = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint32 laneActive = 18;
 * @return {number}
 */
proto.DeviceStateModel.prototype.getLaneactive = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setLaneactive = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional bool cpuOnOff = 19;
 * @return {boolean}
 */
proto.DeviceStateModel.prototype.getCpuonoff = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setCpuonoff = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional bool isWalkingSignalEnable = 20;
 * @return {boolean}
 */
proto.DeviceStateModel.prototype.getIswalkingsignalenable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setIswalkingsignalenable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool isRailwaySignalEnable = 21;
 * @return {boolean}
 */
proto.DeviceStateModel.prototype.getIsrailwaysignalenable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setIsrailwaysignalenable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional string cpuType = 22;
 * @return {string}
 */
proto.DeviceStateModel.prototype.getCputype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setCputype = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated OneSideLampTime sideTime = 23;
 * @return {!Array<!proto.OneSideLampTime>}
 */
proto.DeviceStateModel.prototype.getSidetimeList = function() {
  return /** @type{!Array<!proto.OneSideLampTime>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.OneSideLampTime, 23));
};


/**
 * @param {!Array<!proto.OneSideLampTime>} value
 * @return {!proto.DeviceStateModel} returns this
*/
proto.DeviceStateModel.prototype.setSidetimeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.OneSideLampTime=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OneSideLampTime}
 */
proto.DeviceStateModel.prototype.addSidetime = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.OneSideLampTime, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.clearSidetimeList = function() {
  return this.setSidetimeList([]);
};


/**
 * optional uint32 clearanceTime = 24;
 * @return {number}
 */
proto.DeviceStateModel.prototype.getClearancetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setClearancetime = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string activeBegin = 25;
 * @return {string}
 */
proto.DeviceStateModel.prototype.getActivebegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setActivebegin = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string activeEnd = 26;
 * @return {string}
 */
proto.DeviceStateModel.prototype.getActiveend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.setActiveend = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * repeated ChannelState channelState = 27;
 * @return {!Array<!proto.ChannelState>}
 */
proto.DeviceStateModel.prototype.getChannelstateList = function() {
  return /** @type{!Array<!proto.ChannelState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ChannelState, 27));
};


/**
 * @param {!Array<!proto.ChannelState>} value
 * @return {!proto.DeviceStateModel} returns this
*/
proto.DeviceStateModel.prototype.setChannelstateList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 27, value);
};


/**
 * @param {!proto.ChannelState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ChannelState}
 */
proto.DeviceStateModel.prototype.addChannelstate = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 27, opt_value, proto.ChannelState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceStateModel} returns this
 */
proto.DeviceStateModel.prototype.clearChannelstateList = function() {
  return this.setChannelstateList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UserLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    assignedstationid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    isweb: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserLoginRequest}
 */
proto.UserLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserLoginRequest;
  return proto.UserLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserLoginRequest}
 */
proto.UserLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssignedstationid(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsweb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAssignedstationid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIsweb();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string userName = 1;
 * @return {string}
 */
proto.UserLoginRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginRequest} returns this
 */
proto.UserLoginRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.UserLoginRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginRequest} returns this
 */
proto.UserLoginRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string userId = 3;
 * @return {string}
 */
proto.UserLoginRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginRequest} returns this
 */
proto.UserLoginRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string assignedStationId = 4;
 * @return {string}
 */
proto.UserLoginRequest.prototype.getAssignedstationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginRequest} returns this
 */
proto.UserLoginRequest.prototype.setAssignedstationid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool isWeb = 5;
 * @return {boolean}
 */
proto.UserLoginRequest.prototype.getIsweb = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UserLoginRequest} returns this
 */
proto.UserLoginRequest.prototype.setIsweb = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserLoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UserLoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserLoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0),
    mapkey: jspb.Message.getFieldWithDefault(msg, 4, ""),
    email: jspb.Message.getFieldWithDefault(msg, 5, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 6, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserLoginResponse}
 */
proto.UserLoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserLoginResponse;
  return proto.UserLoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserLoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserLoginResponse}
 */
proto.UserLoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMapkey(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserLoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserLoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserLoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserLoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMapkey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string userId = 2;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 role = 3;
 * @return {number}
 */
proto.UserLoginResponse.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string mapKey = 4;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getMapkey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setMapkey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string email = 5;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string phone = 6;
 * @return {string}
 */
proto.UserLoginResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.UserLoginResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.UserLoginResponse} returns this
*/
proto.UserLoginResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserLoginResponse} returns this
 */
proto.UserLoginResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserLoginResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAccessTokenUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAccessTokenUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAccessTokenUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccessTokenUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAccessTokenUserRequest}
 */
proto.GetAccessTokenUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAccessTokenUserRequest;
  return proto.GetAccessTokenUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAccessTokenUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAccessTokenUserRequest}
 */
proto.GetAccessTokenUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAccessTokenUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAccessTokenUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAccessTokenUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccessTokenUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAccessTokenUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAccessTokenUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAccessTokenUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccessTokenUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accesstoken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAccessTokenUserResponse}
 */
proto.GetAccessTokenUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAccessTokenUserResponse;
  return proto.GetAccessTokenUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAccessTokenUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAccessTokenUserResponse}
 */
proto.GetAccessTokenUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccesstoken(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAccessTokenUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAccessTokenUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAccessTokenUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccessTokenUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccesstoken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string accessToken = 1;
 * @return {string}
 */
proto.GetAccessTokenUserResponse.prototype.getAccesstoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAccessTokenUserResponse} returns this
 */
proto.GetAccessTokenUserResponse.prototype.setAccesstoken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetAccessTokenUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetAccessTokenUserResponse} returns this
*/
proto.GetAccessTokenUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetAccessTokenUserResponse} returns this
 */
proto.GetAccessTokenUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetAccessTokenUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AuthUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.AuthUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AuthUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AuthUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    userloginrequest: (f = msg.getUserloginrequest()) && proto.UserLoginRequest.toObject(includeInstance, f),
    userloginresponse: (f = msg.getUserloginresponse()) && proto.UserLoginResponse.toObject(includeInstance, f),
    getaccesstokenuserrequest: (f = msg.getGetaccesstokenuserrequest()) && proto.GetAccessTokenUserRequest.toObject(includeInstance, f),
    getaccesstokenuserresponse: (f = msg.getGetaccesstokenuserresponse()) && proto.GetAccessTokenUserResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AuthUserMessage}
 */
proto.AuthUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AuthUserMessage;
  return proto.AuthUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AuthUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AuthUserMessage}
 */
proto.AuthUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UserLoginRequest;
      reader.readMessage(value,proto.UserLoginRequest.deserializeBinaryFromReader);
      msg.setUserloginrequest(value);
      break;
    case 2:
      var value = new proto.UserLoginResponse;
      reader.readMessage(value,proto.UserLoginResponse.deserializeBinaryFromReader);
      msg.setUserloginresponse(value);
      break;
    case 3:
      var value = new proto.GetAccessTokenUserRequest;
      reader.readMessage(value,proto.GetAccessTokenUserRequest.deserializeBinaryFromReader);
      msg.setGetaccesstokenuserrequest(value);
      break;
    case 4:
      var value = new proto.GetAccessTokenUserResponse;
      reader.readMessage(value,proto.GetAccessTokenUserResponse.deserializeBinaryFromReader);
      msg.setGetaccesstokenuserresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AuthUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AuthUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AuthUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AuthUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserloginrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UserLoginRequest.serializeBinaryToWriter
    );
  }
  f = message.getUserloginresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.UserLoginResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetaccesstokenuserrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.GetAccessTokenUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetaccesstokenuserresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.GetAccessTokenUserResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserLoginRequest userLoginRequest = 1;
 * @return {?proto.UserLoginRequest}
 */
proto.AuthUserMessage.prototype.getUserloginrequest = function() {
  return /** @type{?proto.UserLoginRequest} */ (
    jspb.Message.getWrapperField(this, proto.UserLoginRequest, 1));
};


/**
 * @param {?proto.UserLoginRequest|undefined} value
 * @return {!proto.AuthUserMessage} returns this
*/
proto.AuthUserMessage.prototype.setUserloginrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AuthUserMessage} returns this
 */
proto.AuthUserMessage.prototype.clearUserloginrequest = function() {
  return this.setUserloginrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AuthUserMessage.prototype.hasUserloginrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserLoginResponse userLoginResponse = 2;
 * @return {?proto.UserLoginResponse}
 */
proto.AuthUserMessage.prototype.getUserloginresponse = function() {
  return /** @type{?proto.UserLoginResponse} */ (
    jspb.Message.getWrapperField(this, proto.UserLoginResponse, 2));
};


/**
 * @param {?proto.UserLoginResponse|undefined} value
 * @return {!proto.AuthUserMessage} returns this
*/
proto.AuthUserMessage.prototype.setUserloginresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AuthUserMessage} returns this
 */
proto.AuthUserMessage.prototype.clearUserloginresponse = function() {
  return this.setUserloginresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AuthUserMessage.prototype.hasUserloginresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GetAccessTokenUserRequest getAccessTokenUserRequest = 3;
 * @return {?proto.GetAccessTokenUserRequest}
 */
proto.AuthUserMessage.prototype.getGetaccesstokenuserrequest = function() {
  return /** @type{?proto.GetAccessTokenUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetAccessTokenUserRequest, 3));
};


/**
 * @param {?proto.GetAccessTokenUserRequest|undefined} value
 * @return {!proto.AuthUserMessage} returns this
*/
proto.AuthUserMessage.prototype.setGetaccesstokenuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AuthUserMessage} returns this
 */
proto.AuthUserMessage.prototype.clearGetaccesstokenuserrequest = function() {
  return this.setGetaccesstokenuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AuthUserMessage.prototype.hasGetaccesstokenuserrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GetAccessTokenUserResponse getAccessTokenUserResponse = 4;
 * @return {?proto.GetAccessTokenUserResponse}
 */
proto.AuthUserMessage.prototype.getGetaccesstokenuserresponse = function() {
  return /** @type{?proto.GetAccessTokenUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetAccessTokenUserResponse, 4));
};


/**
 * @param {?proto.GetAccessTokenUserResponse|undefined} value
 * @return {!proto.AuthUserMessage} returns this
*/
proto.AuthUserMessage.prototype.setGetaccesstokenuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AuthUserMessage} returns this
 */
proto.AuthUserMessage.prototype.clearGetaccesstokenuserresponse = function() {
  return this.setGetaccesstokenuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AuthUserMessage.prototype.hasGetaccesstokenuserresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequireStreamUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RequireStreamUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequireStreamUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    isweb: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequireStreamUserRequest}
 */
proto.RequireStreamUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequireStreamUserRequest;
  return proto.RequireStreamUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequireStreamUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequireStreamUserRequest}
 */
proto.RequireStreamUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsweb(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequireStreamUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequireStreamUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequireStreamUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getIsweb();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.RequireStreamUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequireStreamUserRequest} returns this
 */
proto.RequireStreamUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enable = 2;
 * @return {boolean}
 */
proto.RequireStreamUserRequest.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RequireStreamUserRequest} returns this
 */
proto.RequireStreamUserRequest.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool isWeb = 3;
 * @return {boolean}
 */
proto.RequireStreamUserRequest.prototype.getIsweb = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RequireStreamUserRequest} returns this
 */
proto.RequireStreamUserRequest.prototype.setIsweb = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequireStreamUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RequireStreamUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequireStreamUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequireStreamUserResponse}
 */
proto.RequireStreamUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequireStreamUserResponse;
  return proto.RequireStreamUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequireStreamUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequireStreamUserResponse}
 */
proto.RequireStreamUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequireStreamUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequireStreamUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequireStreamUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.RequireStreamUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.RequireStreamUserResponse} returns this
*/
proto.RequireStreamUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequireStreamUserResponse} returns this
 */
proto.RequireStreamUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequireStreamUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlLaneUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlLaneUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlLaneUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlLaneUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    laneindex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ispoweron: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlLaneUserRequest}
 */
proto.ControlLaneUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlLaneUserRequest;
  return proto.ControlLaneUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlLaneUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlLaneUserRequest}
 */
proto.ControlLaneUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLaneindex(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspoweron(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlLaneUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlLaneUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlLaneUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlLaneUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLaneindex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIspoweron();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.ControlLaneUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ControlLaneUserRequest} returns this
 */
proto.ControlLaneUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 laneIndex = 2;
 * @return {number}
 */
proto.ControlLaneUserRequest.prototype.getLaneindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ControlLaneUserRequest} returns this
 */
proto.ControlLaneUserRequest.prototype.setLaneindex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool isPowerOn = 3;
 * @return {boolean}
 */
proto.ControlLaneUserRequest.prototype.getIspoweron = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ControlLaneUserRequest} returns this
 */
proto.ControlLaneUserRequest.prototype.setIspoweron = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlLaneUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlLaneUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlLaneUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlLaneUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlLaneUserResponse}
 */
proto.ControlLaneUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlLaneUserResponse;
  return proto.ControlLaneUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlLaneUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlLaneUserResponse}
 */
proto.ControlLaneUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlLaneUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlLaneUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlLaneUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlLaneUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.ControlLaneUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.ControlLaneUserResponse} returns this
*/
proto.ControlLaneUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ControlLaneUserResponse} returns this
 */
proto.ControlLaneUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ControlLaneUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetActiveTimeUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SetActiveTimeUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetActiveTimeUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activelamp: (f = msg.getActivelamp()) && common_proto_pb.ActiveLamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetActiveTimeUserRequest}
 */
proto.SetActiveTimeUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetActiveTimeUserRequest;
  return proto.SetActiveTimeUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetActiveTimeUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetActiveTimeUserRequest}
 */
proto.SetActiveTimeUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.ActiveLamp;
      reader.readMessage(value,common_proto_pb.ActiveLamp.deserializeBinaryFromReader);
      msg.setActivelamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetActiveTimeUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetActiveTimeUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetActiveTimeUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivelamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.ActiveLamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.SetActiveTimeUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SetActiveTimeUserRequest} returns this
 */
proto.SetActiveTimeUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActiveLamp activeLamp = 2;
 * @return {?proto.ActiveLamp}
 */
proto.SetActiveTimeUserRequest.prototype.getActivelamp = function() {
  return /** @type{?proto.ActiveLamp} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.ActiveLamp, 2));
};


/**
 * @param {?proto.ActiveLamp|undefined} value
 * @return {!proto.SetActiveTimeUserRequest} returns this
*/
proto.SetActiveTimeUserRequest.prototype.setActivelamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SetActiveTimeUserRequest} returns this
 */
proto.SetActiveTimeUserRequest.prototype.clearActivelamp = function() {
  return this.setActivelamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SetActiveTimeUserRequest.prototype.hasActivelamp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetActiveTimeUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SetActiveTimeUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetActiveTimeUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetActiveTimeUserResponse}
 */
proto.SetActiveTimeUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetActiveTimeUserResponse;
  return proto.SetActiveTimeUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetActiveTimeUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetActiveTimeUserResponse}
 */
proto.SetActiveTimeUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetActiveTimeUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetActiveTimeUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetActiveTimeUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.SetActiveTimeUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.SetActiveTimeUserResponse} returns this
*/
proto.SetActiveTimeUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SetActiveTimeUserResponse} returns this
 */
proto.SetActiveTimeUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SetActiveTimeUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SetCycleConfigUserRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetCycleConfigUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SetCycleConfigUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetCycleConfigUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCycleConfigUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numphase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    yellowtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    clearancetime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    greentimeList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetCycleConfigUserRequest}
 */
proto.SetCycleConfigUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetCycleConfigUserRequest;
  return proto.SetCycleConfigUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetCycleConfigUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetCycleConfigUserRequest}
 */
proto.SetCycleConfigUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumphase(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellowtime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClearancetime(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setGreentimeList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetCycleConfigUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetCycleConfigUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetCycleConfigUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCycleConfigUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumphase();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getYellowtime();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getClearancetime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGreentimeList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.SetCycleConfigUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SetCycleConfigUserRequest} returns this
 */
proto.SetCycleConfigUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 numPhase = 2;
 * @return {number}
 */
proto.SetCycleConfigUserRequest.prototype.getNumphase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SetCycleConfigUserRequest} returns this
 */
proto.SetCycleConfigUserRequest.prototype.setNumphase = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 yellowTime = 3;
 * @return {number}
 */
proto.SetCycleConfigUserRequest.prototype.getYellowtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.SetCycleConfigUserRequest} returns this
 */
proto.SetCycleConfigUserRequest.prototype.setYellowtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 clearanceTime = 4;
 * @return {number}
 */
proto.SetCycleConfigUserRequest.prototype.getClearancetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SetCycleConfigUserRequest} returns this
 */
proto.SetCycleConfigUserRequest.prototype.setClearancetime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated uint32 greenTime = 5;
 * @return {!Array<number>}
 */
proto.SetCycleConfigUserRequest.prototype.getGreentimeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.SetCycleConfigUserRequest} returns this
 */
proto.SetCycleConfigUserRequest.prototype.setGreentimeList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SetCycleConfigUserRequest} returns this
 */
proto.SetCycleConfigUserRequest.prototype.addGreentime = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetCycleConfigUserRequest} returns this
 */
proto.SetCycleConfigUserRequest.prototype.clearGreentimeList = function() {
  return this.setGreentimeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetCycleConfigUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SetCycleConfigUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetCycleConfigUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCycleConfigUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetCycleConfigUserResponse}
 */
proto.SetCycleConfigUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetCycleConfigUserResponse;
  return proto.SetCycleConfigUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetCycleConfigUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetCycleConfigUserResponse}
 */
proto.SetCycleConfigUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetCycleConfigUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetCycleConfigUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetCycleConfigUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCycleConfigUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetCoordinateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SetCoordinateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetCoordinateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCoordinateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    colat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    colong: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetCoordinateUserRequest}
 */
proto.SetCoordinateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetCoordinateUserRequest;
  return proto.SetCoordinateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetCoordinateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetCoordinateUserRequest}
 */
proto.SetCoordinateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setColat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setColong(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetCoordinateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetCoordinateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetCoordinateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCoordinateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColat();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getColong();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.SetCoordinateUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SetCoordinateUserRequest} returns this
 */
proto.SetCoordinateUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double coLat = 2;
 * @return {number}
 */
proto.SetCoordinateUserRequest.prototype.getColat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SetCoordinateUserRequest} returns this
 */
proto.SetCoordinateUserRequest.prototype.setColat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double coLong = 3;
 * @return {number}
 */
proto.SetCoordinateUserRequest.prototype.getColong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SetCoordinateUserRequest} returns this
 */
proto.SetCoordinateUserRequest.prototype.setColong = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetCoordinateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SetCoordinateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetCoordinateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCoordinateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetCoordinateUserResponse}
 */
proto.SetCoordinateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetCoordinateUserResponse;
  return proto.SetCoordinateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetCoordinateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetCoordinateUserResponse}
 */
proto.SetCoordinateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetCoordinateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetCoordinateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetCoordinateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCoordinateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceControlUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceControlUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceControlUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceControlUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ispoweron: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceControlUserRequest}
 */
proto.DeviceControlUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceControlUserRequest;
  return proto.DeviceControlUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceControlUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceControlUserRequest}
 */
proto.DeviceControlUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspoweron(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceControlUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceControlUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceControlUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceControlUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIspoweron();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceControlUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceControlUserRequest} returns this
 */
proto.DeviceControlUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isPowerOn = 2;
 * @return {boolean}
 */
proto.DeviceControlUserRequest.prototype.getIspoweron = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceControlUserRequest} returns this
 */
proto.DeviceControlUserRequest.prototype.setIspoweron = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceControlUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceControlUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceControlUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceControlUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceControlUserResponse}
 */
proto.DeviceControlUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceControlUserResponse;
  return proto.DeviceControlUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceControlUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceControlUserResponse}
 */
proto.DeviceControlUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceControlUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceControlUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceControlUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceControlUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StateOnChangeUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.StateOnChangeUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StateOnChangeUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StateOnChangeUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    update1second: (f = msg.getUpdate1second()) && proto.DeviceStateModel.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StateOnChangeUserMessage}
 */
proto.StateOnChangeUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StateOnChangeUserMessage;
  return proto.StateOnChangeUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StateOnChangeUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StateOnChangeUserMessage}
 */
proto.StateOnChangeUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new proto.DeviceStateModel;
      reader.readMessage(value,proto.DeviceStateModel.deserializeBinaryFromReader);
      msg.setUpdate1second(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StateOnChangeUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StateOnChangeUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StateOnChangeUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StateOnChangeUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdate1second();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeviceStateModel.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.StateOnChangeUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StateOnChangeUserMessage} returns this
 */
proto.StateOnChangeUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceStateModel update1Second = 2;
 * @return {?proto.DeviceStateModel}
 */
proto.StateOnChangeUserMessage.prototype.getUpdate1second = function() {
  return /** @type{?proto.DeviceStateModel} */ (
    jspb.Message.getWrapperField(this, proto.DeviceStateModel, 2));
};


/**
 * @param {?proto.DeviceStateModel|undefined} value
 * @return {!proto.StateOnChangeUserMessage} returns this
*/
proto.StateOnChangeUserMessage.prototype.setUpdate1second = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StateOnChangeUserMessage} returns this
 */
proto.StateOnChangeUserMessage.prototype.clearUpdate1second = function() {
  return this.setUpdate1second(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StateOnChangeUserMessage.prototype.hasUpdate1second = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateDeviceConnectionState.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateDeviceConnectionState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateDeviceConnectionState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDeviceConnectionState.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connectionstate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateDeviceConnectionState}
 */
proto.UpdateDeviceConnectionState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateDeviceConnectionState;
  return proto.UpdateDeviceConnectionState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateDeviceConnectionState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateDeviceConnectionState}
 */
proto.UpdateDeviceConnectionState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {!proto.ConnectionState} */ (reader.readEnum());
      msg.setConnectionstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateDeviceConnectionState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateDeviceConnectionState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateDeviceConnectionState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDeviceConnectionState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateDeviceConnectionState.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateDeviceConnectionState} returns this
 */
proto.UpdateDeviceConnectionState.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ConnectionState connectionState = 2;
 * @return {!proto.ConnectionState}
 */
proto.UpdateDeviceConnectionState.prototype.getConnectionstate = function() {
  return /** @type {!proto.ConnectionState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ConnectionState} value
 * @return {!proto.UpdateDeviceConnectionState} returns this
 */
proto.UpdateDeviceConnectionState.prototype.setConnectionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.LogUpdateUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.LogUpdateUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.LogUpdateUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LogUpdateUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.LogUpdateUserMessage}
 */
proto.LogUpdateUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.LogUpdateUserMessage;
  return proto.LogUpdateUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.LogUpdateUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.LogUpdateUserMessage}
 */
proto.LogUpdateUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.LogUpdateUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.LogUpdateUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.LogUpdateUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.LogUpdateUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.LogUpdateUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.LogUpdateUserMessage} returns this
 */
proto.LogUpdateUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.LogUpdateUserMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.LogUpdateUserMessage} returns this
 */
proto.LogUpdateUserMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ErrorStatusUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ErrorStatusUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ErrorStatusUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ErrorStatusUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorstatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    notelist: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ErrorStatusUserMessage}
 */
proto.ErrorStatusUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ErrorStatusUserMessage;
  return proto.ErrorStatusUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ErrorStatusUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ErrorStatusUserMessage}
 */
proto.ErrorStatusUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setErrorstatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotelist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ErrorStatusUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ErrorStatusUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ErrorStatusUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ErrorStatusUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorstatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNotelist();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.ErrorStatusUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ErrorStatusUserMessage} returns this
 */
proto.ErrorStatusUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 errorStatus = 2;
 * @return {number}
 */
proto.ErrorStatusUserMessage.prototype.getErrorstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ErrorStatusUserMessage} returns this
 */
proto.ErrorStatusUserMessage.prototype.setErrorstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string noteList = 3;
 * @return {string}
 */
proto.ErrorStatusUserMessage.prototype.getNotelist = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ErrorStatusUserMessage} returns this
 */
proto.ErrorStatusUserMessage.prototype.setNotelist = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdatePowerStateUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdatePowerStateUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdatePowerStateUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdatePowerStateUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ison: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdatePowerStateUserMessage}
 */
proto.UpdatePowerStateUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdatePowerStateUserMessage;
  return proto.UpdatePowerStateUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdatePowerStateUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdatePowerStateUserMessage}
 */
proto.UpdatePowerStateUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIson(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdatePowerStateUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdatePowerStateUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdatePowerStateUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdatePowerStateUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIson();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdatePowerStateUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdatePowerStateUserMessage} returns this
 */
proto.UpdatePowerStateUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool isOn = 2;
 * @return {boolean}
 */
proto.UpdatePowerStateUserMessage.prototype.getIson = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.UpdatePowerStateUserMessage} returns this
 */
proto.UpdatePowerStateUserMessage.prototype.setIson = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateScheduleUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateScheduleUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateScheduleUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activebegin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    activeend: jspb.Message.getFieldWithDefault(msg, 3, ""),
    activebegine: jspb.Message.getFieldWithDefault(msg, 4, ""),
    activeende: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateScheduleUserMessage}
 */
proto.UpdateScheduleUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateScheduleUserMessage;
  return proto.UpdateScheduleUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateScheduleUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateScheduleUserMessage}
 */
proto.UpdateScheduleUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivebegin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveend(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivebegine(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActiveende(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateScheduleUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateScheduleUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateScheduleUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivebegin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getActiveend();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActivebegine();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActiveende();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateScheduleUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateScheduleUserMessage} returns this
 */
proto.UpdateScheduleUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string activeBegin = 2;
 * @return {string}
 */
proto.UpdateScheduleUserMessage.prototype.getActivebegin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateScheduleUserMessage} returns this
 */
proto.UpdateScheduleUserMessage.prototype.setActivebegin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string activeEnd = 3;
 * @return {string}
 */
proto.UpdateScheduleUserMessage.prototype.getActiveend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateScheduleUserMessage} returns this
 */
proto.UpdateScheduleUserMessage.prototype.setActiveend = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string activeBeginE = 4;
 * @return {string}
 */
proto.UpdateScheduleUserMessage.prototype.getActivebegine = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateScheduleUserMessage} returns this
 */
proto.UpdateScheduleUserMessage.prototype.setActivebegine = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string activeEndE = 5;
 * @return {string}
 */
proto.UpdateScheduleUserMessage.prototype.getActiveende = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateScheduleUserMessage} returns this
 */
proto.UpdateScheduleUserMessage.prototype.setActiveende = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateModeUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateModeUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateModeUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateModeUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mode: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateModeUserMessage}
 */
proto.UpdateModeUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateModeUserMessage;
  return proto.UpdateModeUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateModeUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateModeUserMessage}
 */
proto.UpdateModeUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateModeUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateModeUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateModeUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateModeUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMode();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateModeUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateModeUserMessage} returns this
 */
proto.UpdateModeUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 mode = 2;
 * @return {number}
 */
proto.UpdateModeUserMessage.prototype.getMode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateModeUserMessage} returns this
 */
proto.UpdateModeUserMessage.prototype.setMode = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateMeterDataUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateMeterDataUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateMeterDataUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateMeterDataUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    v1: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    v2: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    v3: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    i1: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    i2: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    i3: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    p1: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    p2: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    p3: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    a1: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    a2: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    a3: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateMeterDataUserMessage}
 */
proto.UpdateMeterDataUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateMeterDataUserMessage;
  return proto.UpdateMeterDataUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateMeterDataUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateMeterDataUserMessage}
 */
proto.UpdateMeterDataUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV2(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setV3(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI1(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI2(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setI3(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP1(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP2(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setP3(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA1(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA2(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setA3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateMeterDataUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateMeterDataUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateMeterDataUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateMeterDataUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getV1();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getV2();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getV3();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getI1();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getI2();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getI3();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getP1();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getP2();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getP3();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getA1();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getA2();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getA3();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateMeterDataUserMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double v1 = 2;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getV1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setV1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double v2 = 3;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getV2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setV2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double v3 = 4;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getV3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setV3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double i1 = 5;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getI1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setI1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double i2 = 6;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getI2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setI2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double i3 = 7;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getI3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setI3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double p1 = 8;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getP1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setP1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double p2 = 9;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getP2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setP2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double p3 = 10;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getP3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setP3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double a1 = 11;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getA1 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setA1 = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional double a2 = 12;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getA2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setA2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double a3 = 13;
 * @return {number}
 */
proto.UpdateMeterDataUserMessage.prototype.getA3 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateMeterDataUserMessage} returns this
 */
proto.UpdateMeterDataUserMessage.prototype.setA3 = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceOnchangeUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceOnchangeUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceOnchangeUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceOnchangeUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatedeviceconnectionstate: (f = msg.getUpdatedeviceconnectionstate()) && proto.UpdateDeviceConnectionState.toObject(includeInstance, f),
    devicestateonchange: (f = msg.getDevicestateonchange()) && proto.StateOnChangeUserMessage.toObject(includeInstance, f),
    logupdate: (f = msg.getLogupdate()) && proto.LogUpdateUserMessage.toObject(includeInstance, f),
    errorstatususermessage: (f = msg.getErrorstatususermessage()) && proto.ErrorStatusUserMessage.toObject(includeInstance, f),
    updatepowerstateusermessage: (f = msg.getUpdatepowerstateusermessage()) && proto.UpdatePowerStateUserMessage.toObject(includeInstance, f),
    updatescheduleusermessage: (f = msg.getUpdatescheduleusermessage()) && proto.UpdateScheduleUserMessage.toObject(includeInstance, f),
    updatemodeusermessage: (f = msg.getUpdatemodeusermessage()) && proto.UpdateModeUserMessage.toObject(includeInstance, f),
    updatemeterdatausermessage: (f = msg.getUpdatemeterdatausermessage()) && proto.UpdateMeterDataUserMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceOnchangeUserMessage}
 */
proto.DeviceOnchangeUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceOnchangeUserMessage;
  return proto.DeviceOnchangeUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceOnchangeUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceOnchangeUserMessage}
 */
proto.DeviceOnchangeUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.UpdateDeviceConnectionState;
      reader.readMessage(value,proto.UpdateDeviceConnectionState.deserializeBinaryFromReader);
      msg.setUpdatedeviceconnectionstate(value);
      break;
    case 2:
      var value = new proto.StateOnChangeUserMessage;
      reader.readMessage(value,proto.StateOnChangeUserMessage.deserializeBinaryFromReader);
      msg.setDevicestateonchange(value);
      break;
    case 3:
      var value = new proto.LogUpdateUserMessage;
      reader.readMessage(value,proto.LogUpdateUserMessage.deserializeBinaryFromReader);
      msg.setLogupdate(value);
      break;
    case 4:
      var value = new proto.ErrorStatusUserMessage;
      reader.readMessage(value,proto.ErrorStatusUserMessage.deserializeBinaryFromReader);
      msg.setErrorstatususermessage(value);
      break;
    case 5:
      var value = new proto.UpdatePowerStateUserMessage;
      reader.readMessage(value,proto.UpdatePowerStateUserMessage.deserializeBinaryFromReader);
      msg.setUpdatepowerstateusermessage(value);
      break;
    case 6:
      var value = new proto.UpdateScheduleUserMessage;
      reader.readMessage(value,proto.UpdateScheduleUserMessage.deserializeBinaryFromReader);
      msg.setUpdatescheduleusermessage(value);
      break;
    case 7:
      var value = new proto.UpdateModeUserMessage;
      reader.readMessage(value,proto.UpdateModeUserMessage.deserializeBinaryFromReader);
      msg.setUpdatemodeusermessage(value);
      break;
    case 8:
      var value = new proto.UpdateMeterDataUserMessage;
      reader.readMessage(value,proto.UpdateMeterDataUserMessage.deserializeBinaryFromReader);
      msg.setUpdatemeterdatausermessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceOnchangeUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceOnchangeUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceOnchangeUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceOnchangeUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatedeviceconnectionstate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.UpdateDeviceConnectionState.serializeBinaryToWriter
    );
  }
  f = message.getDevicestateonchange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.StateOnChangeUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getLogupdate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.LogUpdateUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getErrorstatususermessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ErrorStatusUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getUpdatepowerstateusermessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.UpdatePowerStateUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getUpdatescheduleusermessage();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.UpdateScheduleUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getUpdatemodeusermessage();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.UpdateModeUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getUpdatemeterdatausermessage();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.UpdateMeterDataUserMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional UpdateDeviceConnectionState updateDeviceConnectionState = 1;
 * @return {?proto.UpdateDeviceConnectionState}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdatedeviceconnectionstate = function() {
  return /** @type{?proto.UpdateDeviceConnectionState} */ (
    jspb.Message.getWrapperField(this, proto.UpdateDeviceConnectionState, 1));
};


/**
 * @param {?proto.UpdateDeviceConnectionState|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdatedeviceconnectionstate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdatedeviceconnectionstate = function() {
  return this.setUpdatedeviceconnectionstate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdatedeviceconnectionstate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StateOnChangeUserMessage deviceStateOnChange = 2;
 * @return {?proto.StateOnChangeUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getDevicestateonchange = function() {
  return /** @type{?proto.StateOnChangeUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.StateOnChangeUserMessage, 2));
};


/**
 * @param {?proto.StateOnChangeUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setDevicestateonchange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearDevicestateonchange = function() {
  return this.setDevicestateonchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasDevicestateonchange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional LogUpdateUserMessage logUpdate = 3;
 * @return {?proto.LogUpdateUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getLogupdate = function() {
  return /** @type{?proto.LogUpdateUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.LogUpdateUserMessage, 3));
};


/**
 * @param {?proto.LogUpdateUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setLogupdate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearLogupdate = function() {
  return this.setLogupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasLogupdate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ErrorStatusUserMessage errorStatusUserMessage = 4;
 * @return {?proto.ErrorStatusUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getErrorstatususermessage = function() {
  return /** @type{?proto.ErrorStatusUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.ErrorStatusUserMessage, 4));
};


/**
 * @param {?proto.ErrorStatusUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setErrorstatususermessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearErrorstatususermessage = function() {
  return this.setErrorstatususermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasErrorstatususermessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UpdatePowerStateUserMessage updatePowerStateUserMessage = 5;
 * @return {?proto.UpdatePowerStateUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdatepowerstateusermessage = function() {
  return /** @type{?proto.UpdatePowerStateUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.UpdatePowerStateUserMessage, 5));
};


/**
 * @param {?proto.UpdatePowerStateUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdatepowerstateusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdatepowerstateusermessage = function() {
  return this.setUpdatepowerstateusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdatepowerstateusermessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UpdateScheduleUserMessage updateScheduleUserMessage = 6;
 * @return {?proto.UpdateScheduleUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdatescheduleusermessage = function() {
  return /** @type{?proto.UpdateScheduleUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.UpdateScheduleUserMessage, 6));
};


/**
 * @param {?proto.UpdateScheduleUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdatescheduleusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdatescheduleusermessage = function() {
  return this.setUpdatescheduleusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdatescheduleusermessage = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional UpdateModeUserMessage updateModeUserMessage = 7;
 * @return {?proto.UpdateModeUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdatemodeusermessage = function() {
  return /** @type{?proto.UpdateModeUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.UpdateModeUserMessage, 7));
};


/**
 * @param {?proto.UpdateModeUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdatemodeusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdatemodeusermessage = function() {
  return this.setUpdatemodeusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdatemodeusermessage = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional UpdateMeterDataUserMessage updateMeterDataUserMessage = 8;
 * @return {?proto.UpdateMeterDataUserMessage}
 */
proto.DeviceOnchangeUserMessage.prototype.getUpdatemeterdatausermessage = function() {
  return /** @type{?proto.UpdateMeterDataUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.UpdateMeterDataUserMessage, 8));
};


/**
 * @param {?proto.UpdateMeterDataUserMessage|undefined} value
 * @return {!proto.DeviceOnchangeUserMessage} returns this
*/
proto.DeviceOnchangeUserMessage.prototype.setUpdatemeterdatausermessage = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceOnchangeUserMessage} returns this
 */
proto.DeviceOnchangeUserMessage.prototype.clearUpdatemeterdatausermessage = function() {
  return this.setUpdatemeterdatausermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceOnchangeUserMessage.prototype.hasUpdatemeterdatausermessage = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SyncTimeUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SyncTimeUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SyncTimeUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SyncTimeUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SyncTimeUserRequest}
 */
proto.SyncTimeUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SyncTimeUserRequest;
  return proto.SyncTimeUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SyncTimeUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SyncTimeUserRequest}
 */
proto.SyncTimeUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SyncTimeUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SyncTimeUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SyncTimeUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SyncTimeUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.SyncTimeUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SyncTimeUserRequest} returns this
 */
proto.SyncTimeUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SyncTimeUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SyncTimeUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SyncTimeUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SyncTimeUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SyncTimeUserResponse}
 */
proto.SyncTimeUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SyncTimeUserResponse;
  return proto.SyncTimeUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SyncTimeUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SyncTimeUserResponse}
 */
proto.SyncTimeUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SyncTimeUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SyncTimeUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SyncTimeUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SyncTimeUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.SyncTimeUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.SyncTimeUserResponse} returns this
*/
proto.SyncTimeUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SyncTimeUserResponse} returns this
 */
proto.SyncTimeUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SyncTimeUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDeviceUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDeviceUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDeviceUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDeviceUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hardwareid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDeviceUserRequest}
 */
proto.GetDeviceUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDeviceUserRequest;
  return proto.GetDeviceUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDeviceUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDeviceUserRequest}
 */
proto.GetDeviceUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDeviceUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDeviceUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDeviceUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDeviceUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetDeviceUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDeviceUserRequest} returns this
 */
proto.GetDeviceUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hardwareId = 2;
 * @return {string}
 */
proto.GetDeviceUserRequest.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetDeviceUserRequest} returns this
 */
proto.GetDeviceUserRequest.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetDeviceUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetDeviceUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetDeviceUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDeviceUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    device: (f = msg.getDevice()) && common_proto_pb.Device.toObject(includeInstance, f),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetDeviceUserResponse}
 */
proto.GetDeviceUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetDeviceUserResponse;
  return proto.GetDeviceUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetDeviceUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetDeviceUserResponse}
 */
proto.GetDeviceUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Device;
      reader.readMessage(value,common_proto_pb.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetDeviceUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetDeviceUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetDeviceUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetDeviceUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_proto_pb.Device.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional Device device = 1;
 * @return {?proto.Device}
 */
proto.GetDeviceUserResponse.prototype.getDevice = function() {
  return /** @type{?proto.Device} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.Device, 1));
};


/**
 * @param {?proto.Device|undefined} value
 * @return {!proto.GetDeviceUserResponse} returns this
*/
proto.GetDeviceUserResponse.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetDeviceUserResponse} returns this
 */
proto.GetDeviceUserResponse.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetDeviceUserResponse.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetDeviceUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetDeviceUserResponse} returns this
*/
proto.GetDeviceUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetDeviceUserResponse} returns this
 */
proto.GetDeviceUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetDeviceUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AddStationUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AddStationUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AddStationUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AddStationUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hardwareid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stationlat: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    stationlong: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AddStationUserRequest}
 */
proto.AddStationUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AddStationUserRequest;
  return proto.AddStationUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AddStationUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AddStationUserRequest}
 */
proto.AddStationUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStationlat(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setStationlong(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AddStationUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AddStationUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AddStationUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AddStationUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStationlat();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getStationlong();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
};


/**
 * optional string stationName = 1;
 * @return {string}
 */
proto.AddStationUserRequest.prototype.getStationname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AddStationUserRequest} returns this
 */
proto.AddStationUserRequest.prototype.setStationname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hardwareId = 2;
 * @return {string}
 */
proto.AddStationUserRequest.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AddStationUserRequest} returns this
 */
proto.AddStationUserRequest.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional float stationLat = 3;
 * @return {number}
 */
proto.AddStationUserRequest.prototype.getStationlat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AddStationUserRequest} returns this
 */
proto.AddStationUserRequest.prototype.setStationlat = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float stationLong = 4;
 * @return {number}
 */
proto.AddStationUserRequest.prototype.getStationlong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.AddStationUserRequest} returns this
 */
proto.AddStationUserRequest.prototype.setStationlong = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AddStationUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.AddStationUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AddStationUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AddStationUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AddStationUserResponse}
 */
proto.AddStationUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AddStationUserResponse;
  return proto.AddStationUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AddStationUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AddStationUserResponse}
 */
proto.AddStationUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AddStationUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AddStationUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AddStationUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AddStationUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.AddStationUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.AddStationUserResponse} returns this
*/
proto.AddStationUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AddStationUserResponse} returns this
 */
proto.AddStationUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AddStationUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetCoordinateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetCoordinateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetCoordinateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCoordinateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hardwareid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetCoordinateUserRequest}
 */
proto.GetCoordinateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetCoordinateUserRequest;
  return proto.GetCoordinateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetCoordinateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetCoordinateUserRequest}
 */
proto.GetCoordinateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetCoordinateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetCoordinateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetCoordinateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCoordinateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetCoordinateUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetCoordinateUserRequest} returns this
 */
proto.GetCoordinateUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hardwareId = 2;
 * @return {string}
 */
proto.GetCoordinateUserRequest.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetCoordinateUserRequest} returns this
 */
proto.GetCoordinateUserRequest.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetCoordinateUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetCoordinateUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetCoordinateUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCoordinateUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    log: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetCoordinateUserResponse}
 */
proto.GetCoordinateUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetCoordinateUserResponse;
  return proto.GetCoordinateUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetCoordinateUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetCoordinateUserResponse}
 */
proto.GetCoordinateUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLog(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetCoordinateUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetCoordinateUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetCoordinateUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCoordinateUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLog();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional float lat = 1;
 * @return {number}
 */
proto.GetCoordinateUserResponse.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetCoordinateUserResponse} returns this
 */
proto.GetCoordinateUserResponse.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float log = 2;
 * @return {number}
 */
proto.GetCoordinateUserResponse.prototype.getLog = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetCoordinateUserResponse} returns this
 */
proto.GetCoordinateUserResponse.prototype.setLog = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetCoordinateUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetCoordinateUserResponse} returns this
*/
proto.GetCoordinateUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetCoordinateUserResponse} returns this
 */
proto.GetCoordinateUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetCoordinateUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMeterInYearUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMeterInYearUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMeterInYearUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInYearUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMeterInYearUserRequest}
 */
proto.GetMeterInYearUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMeterInYearUserRequest;
  return proto.GetMeterInYearUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMeterInYearUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMeterInYearUserRequest}
 */
proto.GetMeterInYearUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMeterInYearUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMeterInYearUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMeterInYearUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInYearUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetMeterInYearUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMeterInYearUserRequest} returns this
 */
proto.GetMeterInYearUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetMeterInYearUserResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMeterInYearUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMeterInYearUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMeterInYearUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInYearUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meteritemsList: jspb.Message.toObjectList(msg.getMeteritemsList(),
    common_proto_pb.MeterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMeterInYearUserResponse}
 */
proto.GetMeterInYearUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMeterInYearUserResponse;
  return proto.GetMeterInYearUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMeterInYearUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMeterInYearUserResponse}
 */
proto.GetMeterInYearUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.MeterItem;
      reader.readMessage(value,common_proto_pb.MeterItem.deserializeBinaryFromReader);
      msg.addMeteritems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMeterInYearUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMeterInYearUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMeterInYearUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInYearUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeteritemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_proto_pb.MeterItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetMeterInYearUserResponse.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMeterInYearUserResponse} returns this
 */
proto.GetMeterInYearUserResponse.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MeterItem meterItems = 2;
 * @return {!Array<!proto.MeterItem>}
 */
proto.GetMeterInYearUserResponse.prototype.getMeteritemsList = function() {
  return /** @type{!Array<!proto.MeterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.MeterItem, 2));
};


/**
 * @param {!Array<!proto.MeterItem>} value
 * @return {!proto.GetMeterInYearUserResponse} returns this
*/
proto.GetMeterInYearUserResponse.prototype.setMeteritemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.MeterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MeterItem}
 */
proto.GetMeterInYearUserResponse.prototype.addMeteritems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.MeterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetMeterInYearUserResponse} returns this
 */
proto.GetMeterInYearUserResponse.prototype.clearMeteritemsList = function() {
  return this.setMeteritemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMeterInMonthUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMeterInMonthUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMeterInMonthUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInMonthUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    month: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMeterInMonthUserRequest}
 */
proto.GetMeterInMonthUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMeterInMonthUserRequest;
  return proto.GetMeterInMonthUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMeterInMonthUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMeterInMonthUserRequest}
 */
proto.GetMeterInMonthUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMeterInMonthUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMeterInMonthUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMeterInMonthUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInMonthUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetMeterInMonthUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMeterInMonthUserRequest} returns this
 */
proto.GetMeterInMonthUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string month = 2;
 * @return {string}
 */
proto.GetMeterInMonthUserRequest.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMeterInMonthUserRequest} returns this
 */
proto.GetMeterInMonthUserRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetMeterInMonthUserResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMeterInMonthUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMeterInMonthUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMeterInMonthUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInMonthUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meteritemsList: jspb.Message.toObjectList(msg.getMeteritemsList(),
    common_proto_pb.MeterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMeterInMonthUserResponse}
 */
proto.GetMeterInMonthUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMeterInMonthUserResponse;
  return proto.GetMeterInMonthUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMeterInMonthUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMeterInMonthUserResponse}
 */
proto.GetMeterInMonthUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.MeterItem;
      reader.readMessage(value,common_proto_pb.MeterItem.deserializeBinaryFromReader);
      msg.addMeteritems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMeterInMonthUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMeterInMonthUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMeterInMonthUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInMonthUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeteritemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_proto_pb.MeterItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetMeterInMonthUserResponse.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMeterInMonthUserResponse} returns this
 */
proto.GetMeterInMonthUserResponse.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MeterItem meterItems = 2;
 * @return {!Array<!proto.MeterItem>}
 */
proto.GetMeterInMonthUserResponse.prototype.getMeteritemsList = function() {
  return /** @type{!Array<!proto.MeterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.MeterItem, 2));
};


/**
 * @param {!Array<!proto.MeterItem>} value
 * @return {!proto.GetMeterInMonthUserResponse} returns this
*/
proto.GetMeterInMonthUserResponse.prototype.setMeteritemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.MeterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MeterItem}
 */
proto.GetMeterInMonthUserResponse.prototype.addMeteritems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.MeterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetMeterInMonthUserResponse} returns this
 */
proto.GetMeterInMonthUserResponse.prototype.clearMeteritemsList = function() {
  return this.setMeteritemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMeterInDayUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMeterInDayUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMeterInDayUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInDayUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMeterInDayUserRequest}
 */
proto.GetMeterInDayUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMeterInDayUserRequest;
  return proto.GetMeterInDayUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMeterInDayUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMeterInDayUserRequest}
 */
proto.GetMeterInDayUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMeterInDayUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMeterInDayUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMeterInDayUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInDayUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetMeterInDayUserRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMeterInDayUserRequest} returns this
 */
proto.GetMeterInDayUserRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date = 2;
 * @return {string}
 */
proto.GetMeterInDayUserRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMeterInDayUserRequest} returns this
 */
proto.GetMeterInDayUserRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetMeterInDayUserResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetMeterInDayUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetMeterInDayUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetMeterInDayUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInDayUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    meteritemsList: jspb.Message.toObjectList(msg.getMeteritemsList(),
    common_proto_pb.MeterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetMeterInDayUserResponse}
 */
proto.GetMeterInDayUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetMeterInDayUserResponse;
  return proto.GetMeterInDayUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetMeterInDayUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetMeterInDayUserResponse}
 */
proto.GetMeterInDayUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.MeterItem;
      reader.readMessage(value,common_proto_pb.MeterItem.deserializeBinaryFromReader);
      msg.addMeteritems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetMeterInDayUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetMeterInDayUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetMeterInDayUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetMeterInDayUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMeteritemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_proto_pb.MeterItem.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetMeterInDayUserResponse.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetMeterInDayUserResponse} returns this
 */
proto.GetMeterInDayUserResponse.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated MeterItem meterItems = 2;
 * @return {!Array<!proto.MeterItem>}
 */
proto.GetMeterInDayUserResponse.prototype.getMeteritemsList = function() {
  return /** @type{!Array<!proto.MeterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.MeterItem, 2));
};


/**
 * @param {!Array<!proto.MeterItem>} value
 * @return {!proto.GetMeterInDayUserResponse} returns this
*/
proto.GetMeterInDayUserResponse.prototype.setMeteritemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.MeterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MeterItem}
 */
proto.GetMeterInDayUserResponse.prototype.addMeteritems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.MeterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetMeterInDayUserResponse} returns this
 */
proto.GetMeterInDayUserResponse.prototype.clearMeteritemsList = function() {
  return this.setMeteritemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicecontroluserrequest: (f = msg.getDevicecontroluserrequest()) && proto.DeviceControlUserRequest.toObject(includeInstance, f),
    devicecontroluserresponse: (f = msg.getDevicecontroluserresponse()) && proto.DeviceControlUserResponse.toObject(includeInstance, f),
    requirestreamuserrequest: (f = msg.getRequirestreamuserrequest()) && proto.RequireStreamUserRequest.toObject(includeInstance, f),
    requirestreamuserresponse: (f = msg.getRequirestreamuserresponse()) && proto.RequireStreamUserResponse.toObject(includeInstance, f),
    controllaneuserrequest: (f = msg.getControllaneuserrequest()) && proto.ControlLaneUserRequest.toObject(includeInstance, f),
    controllaneuserresponse: (f = msg.getControllaneuserresponse()) && proto.ControlLaneUserResponse.toObject(includeInstance, f),
    setactivetimeuserrequest: (f = msg.getSetactivetimeuserrequest()) && proto.SetActiveTimeUserRequest.toObject(includeInstance, f),
    setactivetimeuserresponse: (f = msg.getSetactivetimeuserresponse()) && proto.SetActiveTimeUserResponse.toObject(includeInstance, f),
    setcycleconfiguserrequest: (f = msg.getSetcycleconfiguserrequest()) && proto.SetCycleConfigUserRequest.toObject(includeInstance, f),
    setcycleconfiguserresponse: (f = msg.getSetcycleconfiguserresponse()) && proto.SetCycleConfigUserResponse.toObject(includeInstance, f),
    setcoordinateuserrequest: (f = msg.getSetcoordinateuserrequest()) && proto.SetCoordinateUserRequest.toObject(includeInstance, f),
    setcoordinateuserresponse: (f = msg.getSetcoordinateuserresponse()) && proto.SetCoordinateUserResponse.toObject(includeInstance, f),
    synctimeuserrequest: (f = msg.getSynctimeuserrequest()) && proto.SyncTimeUserRequest.toObject(includeInstance, f),
    synctimeuserresponse: (f = msg.getSynctimeuserresponse()) && proto.SyncTimeUserResponse.toObject(includeInstance, f),
    getdeviceuserrequest: (f = msg.getGetdeviceuserrequest()) && proto.GetDeviceUserRequest.toObject(includeInstance, f),
    getdeviceuserresponse: (f = msg.getGetdeviceuserresponse()) && proto.GetDeviceUserResponse.toObject(includeInstance, f),
    getcoordinateuserrequest: (f = msg.getGetcoordinateuserrequest()) && proto.GetCoordinateUserRequest.toObject(includeInstance, f),
    getcoordinateuserresponse: (f = msg.getGetcoordinateuserresponse()) && proto.GetCoordinateUserResponse.toObject(includeInstance, f),
    getmeterindayuserrequest: (f = msg.getGetmeterindayuserrequest()) && proto.GetMeterInDayUserRequest.toObject(includeInstance, f),
    getmeterindayuserresponse: (f = msg.getGetmeterindayuserresponse()) && proto.GetMeterInDayUserResponse.toObject(includeInstance, f),
    getmeterinmonthuserrequest: (f = msg.getGetmeterinmonthuserrequest()) && proto.GetMeterInMonthUserRequest.toObject(includeInstance, f),
    getmeterinmonthuserresponse: (f = msg.getGetmeterinmonthuserresponse()) && proto.GetMeterInMonthUserResponse.toObject(includeInstance, f),
    getmeterinyearuserrequest: (f = msg.getGetmeterinyearuserrequest()) && proto.GetMeterInYearUserRequest.toObject(includeInstance, f),
    getmeterinyearuserresponse: (f = msg.getGetmeterinyearuserresponse()) && proto.GetMeterInYearUserResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUserMessage}
 */
proto.DeviceUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUserMessage;
  return proto.DeviceUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUserMessage}
 */
proto.DeviceUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DeviceControlUserRequest;
      reader.readMessage(value,proto.DeviceControlUserRequest.deserializeBinaryFromReader);
      msg.setDevicecontroluserrequest(value);
      break;
    case 2:
      var value = new proto.DeviceControlUserResponse;
      reader.readMessage(value,proto.DeviceControlUserResponse.deserializeBinaryFromReader);
      msg.setDevicecontroluserresponse(value);
      break;
    case 3:
      var value = new proto.RequireStreamUserRequest;
      reader.readMessage(value,proto.RequireStreamUserRequest.deserializeBinaryFromReader);
      msg.setRequirestreamuserrequest(value);
      break;
    case 4:
      var value = new proto.RequireStreamUserResponse;
      reader.readMessage(value,proto.RequireStreamUserResponse.deserializeBinaryFromReader);
      msg.setRequirestreamuserresponse(value);
      break;
    case 5:
      var value = new proto.ControlLaneUserRequest;
      reader.readMessage(value,proto.ControlLaneUserRequest.deserializeBinaryFromReader);
      msg.setControllaneuserrequest(value);
      break;
    case 6:
      var value = new proto.ControlLaneUserResponse;
      reader.readMessage(value,proto.ControlLaneUserResponse.deserializeBinaryFromReader);
      msg.setControllaneuserresponse(value);
      break;
    case 7:
      var value = new proto.SetActiveTimeUserRequest;
      reader.readMessage(value,proto.SetActiveTimeUserRequest.deserializeBinaryFromReader);
      msg.setSetactivetimeuserrequest(value);
      break;
    case 8:
      var value = new proto.SetActiveTimeUserResponse;
      reader.readMessage(value,proto.SetActiveTimeUserResponse.deserializeBinaryFromReader);
      msg.setSetactivetimeuserresponse(value);
      break;
    case 9:
      var value = new proto.SetCycleConfigUserRequest;
      reader.readMessage(value,proto.SetCycleConfigUserRequest.deserializeBinaryFromReader);
      msg.setSetcycleconfiguserrequest(value);
      break;
    case 10:
      var value = new proto.SetCycleConfigUserResponse;
      reader.readMessage(value,proto.SetCycleConfigUserResponse.deserializeBinaryFromReader);
      msg.setSetcycleconfiguserresponse(value);
      break;
    case 11:
      var value = new proto.SetCoordinateUserRequest;
      reader.readMessage(value,proto.SetCoordinateUserRequest.deserializeBinaryFromReader);
      msg.setSetcoordinateuserrequest(value);
      break;
    case 12:
      var value = new proto.SetCoordinateUserResponse;
      reader.readMessage(value,proto.SetCoordinateUserResponse.deserializeBinaryFromReader);
      msg.setSetcoordinateuserresponse(value);
      break;
    case 13:
      var value = new proto.SyncTimeUserRequest;
      reader.readMessage(value,proto.SyncTimeUserRequest.deserializeBinaryFromReader);
      msg.setSynctimeuserrequest(value);
      break;
    case 14:
      var value = new proto.SyncTimeUserResponse;
      reader.readMessage(value,proto.SyncTimeUserResponse.deserializeBinaryFromReader);
      msg.setSynctimeuserresponse(value);
      break;
    case 15:
      var value = new proto.GetDeviceUserRequest;
      reader.readMessage(value,proto.GetDeviceUserRequest.deserializeBinaryFromReader);
      msg.setGetdeviceuserrequest(value);
      break;
    case 16:
      var value = new proto.GetDeviceUserResponse;
      reader.readMessage(value,proto.GetDeviceUserResponse.deserializeBinaryFromReader);
      msg.setGetdeviceuserresponse(value);
      break;
    case 17:
      var value = new proto.GetCoordinateUserRequest;
      reader.readMessage(value,proto.GetCoordinateUserRequest.deserializeBinaryFromReader);
      msg.setGetcoordinateuserrequest(value);
      break;
    case 18:
      var value = new proto.GetCoordinateUserResponse;
      reader.readMessage(value,proto.GetCoordinateUserResponse.deserializeBinaryFromReader);
      msg.setGetcoordinateuserresponse(value);
      break;
    case 19:
      var value = new proto.GetMeterInDayUserRequest;
      reader.readMessage(value,proto.GetMeterInDayUserRequest.deserializeBinaryFromReader);
      msg.setGetmeterindayuserrequest(value);
      break;
    case 20:
      var value = new proto.GetMeterInDayUserResponse;
      reader.readMessage(value,proto.GetMeterInDayUserResponse.deserializeBinaryFromReader);
      msg.setGetmeterindayuserresponse(value);
      break;
    case 21:
      var value = new proto.GetMeterInMonthUserRequest;
      reader.readMessage(value,proto.GetMeterInMonthUserRequest.deserializeBinaryFromReader);
      msg.setGetmeterinmonthuserrequest(value);
      break;
    case 22:
      var value = new proto.GetMeterInMonthUserResponse;
      reader.readMessage(value,proto.GetMeterInMonthUserResponse.deserializeBinaryFromReader);
      msg.setGetmeterinmonthuserresponse(value);
      break;
    case 23:
      var value = new proto.GetMeterInYearUserRequest;
      reader.readMessage(value,proto.GetMeterInYearUserRequest.deserializeBinaryFromReader);
      msg.setGetmeterinyearuserrequest(value);
      break;
    case 24:
      var value = new proto.GetMeterInYearUserResponse;
      reader.readMessage(value,proto.GetMeterInYearUserResponse.deserializeBinaryFromReader);
      msg.setGetmeterinyearuserresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicecontroluserrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DeviceControlUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getDevicecontroluserresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeviceControlUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getRequirestreamuserrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.RequireStreamUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getRequirestreamuserresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.RequireStreamUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getControllaneuserrequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ControlLaneUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getControllaneuserresponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.ControlLaneUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getSetactivetimeuserrequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.SetActiveTimeUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetactivetimeuserresponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.SetActiveTimeUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getSetcycleconfiguserrequest();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.SetCycleConfigUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetcycleconfiguserresponse();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.SetCycleConfigUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getSetcoordinateuserrequest();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.SetCoordinateUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetcoordinateuserresponse();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.SetCoordinateUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getSynctimeuserrequest();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.SyncTimeUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getSynctimeuserresponse();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.SyncTimeUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetdeviceuserrequest();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.GetDeviceUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetdeviceuserresponse();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.GetDeviceUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetcoordinateuserrequest();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.GetCoordinateUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetcoordinateuserresponse();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.GetCoordinateUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetmeterindayuserrequest();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.GetMeterInDayUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetmeterindayuserresponse();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.GetMeterInDayUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetmeterinmonthuserrequest();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.GetMeterInMonthUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetmeterinmonthuserresponse();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.GetMeterInMonthUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetmeterinyearuserrequest();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.GetMeterInYearUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetmeterinyearuserresponse();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.GetMeterInYearUserResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceControlUserRequest deviceControlUserRequest = 1;
 * @return {?proto.DeviceControlUserRequest}
 */
proto.DeviceUserMessage.prototype.getDevicecontroluserrequest = function() {
  return /** @type{?proto.DeviceControlUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeviceControlUserRequest, 1));
};


/**
 * @param {?proto.DeviceControlUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setDevicecontroluserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearDevicecontroluserrequest = function() {
  return this.setDevicecontroluserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasDevicecontroluserrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceControlUserResponse deviceControlUserResponse = 2;
 * @return {?proto.DeviceControlUserResponse}
 */
proto.DeviceUserMessage.prototype.getDevicecontroluserresponse = function() {
  return /** @type{?proto.DeviceControlUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeviceControlUserResponse, 2));
};


/**
 * @param {?proto.DeviceControlUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setDevicecontroluserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearDevicecontroluserresponse = function() {
  return this.setDevicecontroluserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasDevicecontroluserresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional RequireStreamUserRequest requireStreamUserRequest = 3;
 * @return {?proto.RequireStreamUserRequest}
 */
proto.DeviceUserMessage.prototype.getRequirestreamuserrequest = function() {
  return /** @type{?proto.RequireStreamUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.RequireStreamUserRequest, 3));
};


/**
 * @param {?proto.RequireStreamUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setRequirestreamuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearRequirestreamuserrequest = function() {
  return this.setRequirestreamuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasRequirestreamuserrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional RequireStreamUserResponse requireStreamUserResponse = 4;
 * @return {?proto.RequireStreamUserResponse}
 */
proto.DeviceUserMessage.prototype.getRequirestreamuserresponse = function() {
  return /** @type{?proto.RequireStreamUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.RequireStreamUserResponse, 4));
};


/**
 * @param {?proto.RequireStreamUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setRequirestreamuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearRequirestreamuserresponse = function() {
  return this.setRequirestreamuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasRequirestreamuserresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ControlLaneUserRequest controlLaneUserRequest = 5;
 * @return {?proto.ControlLaneUserRequest}
 */
proto.DeviceUserMessage.prototype.getControllaneuserrequest = function() {
  return /** @type{?proto.ControlLaneUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.ControlLaneUserRequest, 5));
};


/**
 * @param {?proto.ControlLaneUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setControllaneuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearControllaneuserrequest = function() {
  return this.setControllaneuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasControllaneuserrequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ControlLaneUserResponse controlLaneUserResponse = 6;
 * @return {?proto.ControlLaneUserResponse}
 */
proto.DeviceUserMessage.prototype.getControllaneuserresponse = function() {
  return /** @type{?proto.ControlLaneUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.ControlLaneUserResponse, 6));
};


/**
 * @param {?proto.ControlLaneUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setControllaneuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearControllaneuserresponse = function() {
  return this.setControllaneuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasControllaneuserresponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SetActiveTimeUserRequest setActiveTimeUserRequest = 7;
 * @return {?proto.SetActiveTimeUserRequest}
 */
proto.DeviceUserMessage.prototype.getSetactivetimeuserrequest = function() {
  return /** @type{?proto.SetActiveTimeUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.SetActiveTimeUserRequest, 7));
};


/**
 * @param {?proto.SetActiveTimeUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setSetactivetimeuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearSetactivetimeuserrequest = function() {
  return this.setSetactivetimeuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasSetactivetimeuserrequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SetActiveTimeUserResponse setActiveTimeUserResponse = 8;
 * @return {?proto.SetActiveTimeUserResponse}
 */
proto.DeviceUserMessage.prototype.getSetactivetimeuserresponse = function() {
  return /** @type{?proto.SetActiveTimeUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.SetActiveTimeUserResponse, 8));
};


/**
 * @param {?proto.SetActiveTimeUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setSetactivetimeuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearSetactivetimeuserresponse = function() {
  return this.setSetactivetimeuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasSetactivetimeuserresponse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SetCycleConfigUserRequest setCycleConfigUserRequest = 9;
 * @return {?proto.SetCycleConfigUserRequest}
 */
proto.DeviceUserMessage.prototype.getSetcycleconfiguserrequest = function() {
  return /** @type{?proto.SetCycleConfigUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.SetCycleConfigUserRequest, 9));
};


/**
 * @param {?proto.SetCycleConfigUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setSetcycleconfiguserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearSetcycleconfiguserrequest = function() {
  return this.setSetcycleconfiguserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasSetcycleconfiguserrequest = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SetCycleConfigUserResponse setCycleConfigUserResponse = 10;
 * @return {?proto.SetCycleConfigUserResponse}
 */
proto.DeviceUserMessage.prototype.getSetcycleconfiguserresponse = function() {
  return /** @type{?proto.SetCycleConfigUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.SetCycleConfigUserResponse, 10));
};


/**
 * @param {?proto.SetCycleConfigUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setSetcycleconfiguserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearSetcycleconfiguserresponse = function() {
  return this.setSetcycleconfiguserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasSetcycleconfiguserresponse = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional SetCoordinateUserRequest setCoordinateUserRequest = 11;
 * @return {?proto.SetCoordinateUserRequest}
 */
proto.DeviceUserMessage.prototype.getSetcoordinateuserrequest = function() {
  return /** @type{?proto.SetCoordinateUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.SetCoordinateUserRequest, 11));
};


/**
 * @param {?proto.SetCoordinateUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setSetcoordinateuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearSetcoordinateuserrequest = function() {
  return this.setSetcoordinateuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasSetcoordinateuserrequest = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional SetCoordinateUserResponse setCoordinateUserResponse = 12;
 * @return {?proto.SetCoordinateUserResponse}
 */
proto.DeviceUserMessage.prototype.getSetcoordinateuserresponse = function() {
  return /** @type{?proto.SetCoordinateUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.SetCoordinateUserResponse, 12));
};


/**
 * @param {?proto.SetCoordinateUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setSetcoordinateuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearSetcoordinateuserresponse = function() {
  return this.setSetcoordinateuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasSetcoordinateuserresponse = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional SyncTimeUserRequest syncTimeUserRequest = 13;
 * @return {?proto.SyncTimeUserRequest}
 */
proto.DeviceUserMessage.prototype.getSynctimeuserrequest = function() {
  return /** @type{?proto.SyncTimeUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.SyncTimeUserRequest, 13));
};


/**
 * @param {?proto.SyncTimeUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setSynctimeuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearSynctimeuserrequest = function() {
  return this.setSynctimeuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasSynctimeuserrequest = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional SyncTimeUserResponse syncTimeUserResponse = 14;
 * @return {?proto.SyncTimeUserResponse}
 */
proto.DeviceUserMessage.prototype.getSynctimeuserresponse = function() {
  return /** @type{?proto.SyncTimeUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.SyncTimeUserResponse, 14));
};


/**
 * @param {?proto.SyncTimeUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setSynctimeuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearSynctimeuserresponse = function() {
  return this.setSynctimeuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasSynctimeuserresponse = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional GetDeviceUserRequest getDeviceUserRequest = 15;
 * @return {?proto.GetDeviceUserRequest}
 */
proto.DeviceUserMessage.prototype.getGetdeviceuserrequest = function() {
  return /** @type{?proto.GetDeviceUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetDeviceUserRequest, 15));
};


/**
 * @param {?proto.GetDeviceUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetdeviceuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetdeviceuserrequest = function() {
  return this.setGetdeviceuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetdeviceuserrequest = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional GetDeviceUserResponse getDeviceUserResponse = 16;
 * @return {?proto.GetDeviceUserResponse}
 */
proto.DeviceUserMessage.prototype.getGetdeviceuserresponse = function() {
  return /** @type{?proto.GetDeviceUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetDeviceUserResponse, 16));
};


/**
 * @param {?proto.GetDeviceUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetdeviceuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetdeviceuserresponse = function() {
  return this.setGetdeviceuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetdeviceuserresponse = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional GetCoordinateUserRequest getCoordinateUserRequest = 17;
 * @return {?proto.GetCoordinateUserRequest}
 */
proto.DeviceUserMessage.prototype.getGetcoordinateuserrequest = function() {
  return /** @type{?proto.GetCoordinateUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetCoordinateUserRequest, 17));
};


/**
 * @param {?proto.GetCoordinateUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetcoordinateuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetcoordinateuserrequest = function() {
  return this.setGetcoordinateuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetcoordinateuserrequest = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional GetCoordinateUserResponse getCoordinateUserResponse = 18;
 * @return {?proto.GetCoordinateUserResponse}
 */
proto.DeviceUserMessage.prototype.getGetcoordinateuserresponse = function() {
  return /** @type{?proto.GetCoordinateUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetCoordinateUserResponse, 18));
};


/**
 * @param {?proto.GetCoordinateUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetcoordinateuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetcoordinateuserresponse = function() {
  return this.setGetcoordinateuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetcoordinateuserresponse = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional GetMeterInDayUserRequest getMeterInDayUserRequest = 19;
 * @return {?proto.GetMeterInDayUserRequest}
 */
proto.DeviceUserMessage.prototype.getGetmeterindayuserrequest = function() {
  return /** @type{?proto.GetMeterInDayUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetMeterInDayUserRequest, 19));
};


/**
 * @param {?proto.GetMeterInDayUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetmeterindayuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetmeterindayuserrequest = function() {
  return this.setGetmeterindayuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetmeterindayuserrequest = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional GetMeterInDayUserResponse getMeterInDayUserResponse = 20;
 * @return {?proto.GetMeterInDayUserResponse}
 */
proto.DeviceUserMessage.prototype.getGetmeterindayuserresponse = function() {
  return /** @type{?proto.GetMeterInDayUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetMeterInDayUserResponse, 20));
};


/**
 * @param {?proto.GetMeterInDayUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetmeterindayuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetmeterindayuserresponse = function() {
  return this.setGetmeterindayuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetmeterindayuserresponse = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional GetMeterInMonthUserRequest getMeterInMonthUserRequest = 21;
 * @return {?proto.GetMeterInMonthUserRequest}
 */
proto.DeviceUserMessage.prototype.getGetmeterinmonthuserrequest = function() {
  return /** @type{?proto.GetMeterInMonthUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetMeterInMonthUserRequest, 21));
};


/**
 * @param {?proto.GetMeterInMonthUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetmeterinmonthuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetmeterinmonthuserrequest = function() {
  return this.setGetmeterinmonthuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetmeterinmonthuserrequest = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional GetMeterInMonthUserResponse getMeterInMonthUserResponse = 22;
 * @return {?proto.GetMeterInMonthUserResponse}
 */
proto.DeviceUserMessage.prototype.getGetmeterinmonthuserresponse = function() {
  return /** @type{?proto.GetMeterInMonthUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetMeterInMonthUserResponse, 22));
};


/**
 * @param {?proto.GetMeterInMonthUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetmeterinmonthuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetmeterinmonthuserresponse = function() {
  return this.setGetmeterinmonthuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetmeterinmonthuserresponse = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional GetMeterInYearUserRequest getMeterInYearUserRequest = 23;
 * @return {?proto.GetMeterInYearUserRequest}
 */
proto.DeviceUserMessage.prototype.getGetmeterinyearuserrequest = function() {
  return /** @type{?proto.GetMeterInYearUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetMeterInYearUserRequest, 23));
};


/**
 * @param {?proto.GetMeterInYearUserRequest|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetmeterinyearuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetmeterinyearuserrequest = function() {
  return this.setGetmeterinyearuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetmeterinyearuserrequest = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional GetMeterInYearUserResponse getMeterInYearUserResponse = 24;
 * @return {?proto.GetMeterInYearUserResponse}
 */
proto.DeviceUserMessage.prototype.getGetmeterinyearuserresponse = function() {
  return /** @type{?proto.GetMeterInYearUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetMeterInYearUserResponse, 24));
};


/**
 * @param {?proto.GetMeterInYearUserResponse|undefined} value
 * @return {!proto.DeviceUserMessage} returns this
*/
proto.DeviceUserMessage.prototype.setGetmeterinyearuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUserMessage} returns this
 */
proto.DeviceUserMessage.prototype.clearGetmeterinyearuserresponse = function() {
  return this.setGetmeterinyearuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUserMessage.prototype.hasGetmeterinyearuserresponse = function() {
  return jspb.Message.getField(this, 24) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetStationUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetStationUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetStationUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetStationUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetStationUserRequest}
 */
proto.GetStationUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetStationUserRequest;
  return proto.GetStationUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetStationUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetStationUserRequest}
 */
proto.GetStationUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetStationUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetStationUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetStationUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetStationUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.GetStationUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetStationUserRequest} returns this
 */
proto.GetStationUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetStationUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetStationUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetStationUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetStationUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    station: (f = msg.getStation()) && common_proto_pb.Station.toObject(includeInstance, f),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetStationUserResponse}
 */
proto.GetStationUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetStationUserResponse;
  return proto.GetStationUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetStationUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetStationUserResponse}
 */
proto.GetStationUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Station;
      reader.readMessage(value,common_proto_pb.Station.deserializeBinaryFromReader);
      msg.setStation(value);
      break;
    case 2:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetStationUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetStationUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetStationUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetStationUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_proto_pb.Station.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional Station station = 1;
 * @return {?proto.Station}
 */
proto.GetStationUserResponse.prototype.getStation = function() {
  return /** @type{?proto.Station} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.Station, 1));
};


/**
 * @param {?proto.Station|undefined} value
 * @return {!proto.GetStationUserResponse} returns this
*/
proto.GetStationUserResponse.prototype.setStation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetStationUserResponse} returns this
 */
proto.GetStationUserResponse.prototype.clearStation = function() {
  return this.setStation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetStationUserResponse.prototype.hasStation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusCode statusCode = 2;
 * @return {?proto.StatusCode}
 */
proto.GetStationUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 2));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetStationUserResponse} returns this
*/
proto.GetStationUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetStationUserResponse} returns this
 */
proto.GetStationUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetStationUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetStationListUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetStationListUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetStationListUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetStationListUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetStationListUserRequest}
 */
proto.GetStationListUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetStationListUserRequest;
  return proto.GetStationListUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetStationListUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetStationListUserRequest}
 */
proto.GetStationListUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetStationListUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetStationListUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetStationListUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetStationListUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.GetStationListUserRequest.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetStationListUserRequest} returns this
 */
proto.GetStationListUserRequest.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetStationListUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetStationListUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetStationListUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetStationListUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetStationListUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationsList: jspb.Message.toObjectList(msg.getStationsList(),
    common_proto_pb.Station.toObject, includeInstance),
    originlat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    originlong: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetStationListUserResponse}
 */
proto.GetStationListUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetStationListUserResponse;
  return proto.GetStationListUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetStationListUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetStationListUserResponse}
 */
proto.GetStationListUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Station;
      reader.readMessage(value,common_proto_pb.Station.deserializeBinaryFromReader);
      msg.addStations(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOriginlat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setOriginlong(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetStationListUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetStationListUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetStationListUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetStationListUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_proto_pb.Station.serializeBinaryToWriter
    );
  }
  f = message.getOriginlat();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getOriginlong();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Station stations = 1;
 * @return {!Array<!proto.Station>}
 */
proto.GetStationListUserResponse.prototype.getStationsList = function() {
  return /** @type{!Array<!proto.Station>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.Station, 1));
};


/**
 * @param {!Array<!proto.Station>} value
 * @return {!proto.GetStationListUserResponse} returns this
*/
proto.GetStationListUserResponse.prototype.setStationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Station=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Station}
 */
proto.GetStationListUserResponse.prototype.addStations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Station, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetStationListUserResponse} returns this
 */
proto.GetStationListUserResponse.prototype.clearStationsList = function() {
  return this.setStationsList([]);
};


/**
 * optional float originLat = 2;
 * @return {number}
 */
proto.GetStationListUserResponse.prototype.getOriginlat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetStationListUserResponse} returns this
 */
proto.GetStationListUserResponse.prototype.setOriginlat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float originLong = 3;
 * @return {number}
 */
proto.GetStationListUserResponse.prototype.getOriginlong = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetStationListUserResponse} returns this
 */
proto.GetStationListUserResponse.prototype.setOriginlong = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetStationListUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetStationListUserResponse} returns this
*/
proto.GetStationListUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetStationListUserResponse} returns this
 */
proto.GetStationListUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetStationListUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SetSignSettingUserRequest.repeatedFields_ = [2,3,4,5,6,7,8,9,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetSignSettingUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SetSignSettingUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetSignSettingUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetSignSettingUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    channelrailwayList: jspb.Message.toObjectList(msg.getChannelrailwayList(),
    common_proto_pb.SignParam.toObject, includeInstance),
    channelwalkingList: jspb.Message.toObjectList(msg.getChannelwalkingList(),
    common_proto_pb.SignParam.toObject, includeInstance),
    channelturnleftList: jspb.Message.toObjectList(msg.getChannelturnleftList(),
    common_proto_pb.SignParam.toObject, includeInstance),
    channelturnrightList: jspb.Message.toObjectList(msg.getChannelturnrightList(),
    common_proto_pb.SignParam.toObject, includeInstance),
    channelgoaheadList: jspb.Message.toObjectList(msg.getChannelgoaheadList(),
    common_proto_pb.SignParam.toObject, includeInstance),
    channeltrafficlightList: jspb.Message.toObjectList(msg.getChanneltrafficlightList(),
    common_proto_pb.SignParam.toObject, includeInstance),
    channelcounterList: jspb.Message.toObjectList(msg.getChannelcounterList(),
    common_proto_pb.SignParam.toObject, includeInstance),
    channellabelList: jspb.Message.toObjectList(msg.getChannellabelList(),
    common_proto_pb.SignParam.toObject, includeInstance),
    backgroundsrc: jspb.Message.getFieldWithDefault(msg, 10, ""),
    channeltrafficlightleftList: jspb.Message.toObjectList(msg.getChanneltrafficlightleftList(),
    common_proto_pb.SignParam.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetSignSettingUserRequest}
 */
proto.SetSignSettingUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetSignSettingUserRequest;
  return proto.SetSignSettingUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetSignSettingUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetSignSettingUserRequest}
 */
proto.SetSignSettingUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChannelrailway(value);
      break;
    case 3:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChannelwalking(value);
      break;
    case 4:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChannelturnleft(value);
      break;
    case 5:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChannelturnright(value);
      break;
    case 6:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChannelgoahead(value);
      break;
    case 7:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChanneltrafficlight(value);
      break;
    case 8:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChannelcounter(value);
      break;
    case 9:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChannellabel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBackgroundsrc(value);
      break;
    case 11:
      var value = new common_proto_pb.SignParam;
      reader.readMessage(value,common_proto_pb.SignParam.deserializeBinaryFromReader);
      msg.addChanneltrafficlightleft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetSignSettingUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetSignSettingUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetSignSettingUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetSignSettingUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChannelrailwayList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getChannelwalkingList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getChannelturnleftList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getChannelturnrightList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getChannelgoaheadList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getChanneltrafficlightList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getChannelcounterList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getChannellabelList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
  f = message.getBackgroundsrc();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getChanneltrafficlightleftList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      common_proto_pb.SignParam.serializeBinaryToWriter
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.SetSignSettingUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated SignParam channelRailway = 2;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChannelrailwayList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 2));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChannelrailwayList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChannelrailway = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChannelrailwayList = function() {
  return this.setChannelrailwayList([]);
};


/**
 * repeated SignParam channelWalking = 3;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChannelwalkingList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 3));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChannelwalkingList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChannelwalking = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChannelwalkingList = function() {
  return this.setChannelwalkingList([]);
};


/**
 * repeated SignParam channelTurnLeft = 4;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChannelturnleftList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 4));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChannelturnleftList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChannelturnleft = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChannelturnleftList = function() {
  return this.setChannelturnleftList([]);
};


/**
 * repeated SignParam channelTurnRight = 5;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChannelturnrightList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 5));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChannelturnrightList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChannelturnright = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChannelturnrightList = function() {
  return this.setChannelturnrightList([]);
};


/**
 * repeated SignParam channelGoAhead = 6;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChannelgoaheadList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 6));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChannelgoaheadList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChannelgoahead = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChannelgoaheadList = function() {
  return this.setChannelgoaheadList([]);
};


/**
 * repeated SignParam channelTrafficLight = 7;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChanneltrafficlightList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 7));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChanneltrafficlightList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChanneltrafficlight = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChanneltrafficlightList = function() {
  return this.setChanneltrafficlightList([]);
};


/**
 * repeated SignParam channelCounter = 8;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChannelcounterList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 8));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChannelcounterList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChannelcounter = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChannelcounterList = function() {
  return this.setChannelcounterList([]);
};


/**
 * repeated SignParam channelLabel = 9;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChannellabelList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 9));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChannellabelList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChannellabel = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChannellabelList = function() {
  return this.setChannellabelList([]);
};


/**
 * optional string backgroundSrc = 10;
 * @return {string}
 */
proto.SetSignSettingUserRequest.prototype.getBackgroundsrc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.setBackgroundsrc = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated SignParam channelTrafficLightLeft = 11;
 * @return {!Array<!proto.SignParam>}
 */
proto.SetSignSettingUserRequest.prototype.getChanneltrafficlightleftList = function() {
  return /** @type{!Array<!proto.SignParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.SignParam, 11));
};


/**
 * @param {!Array<!proto.SignParam>} value
 * @return {!proto.SetSignSettingUserRequest} returns this
*/
proto.SetSignSettingUserRequest.prototype.setChanneltrafficlightleftList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.SignParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SignParam}
 */
proto.SetSignSettingUserRequest.prototype.addChanneltrafficlightleft = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.SignParam, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetSignSettingUserRequest} returns this
 */
proto.SetSignSettingUserRequest.prototype.clearChanneltrafficlightleftList = function() {
  return this.setChanneltrafficlightleftList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetSignSettingUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SetSignSettingUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetSignSettingUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetSignSettingUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetSignSettingUserResponse}
 */
proto.SetSignSettingUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetSignSettingUserResponse;
  return proto.SetSignSettingUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetSignSettingUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetSignSettingUserResponse}
 */
proto.SetSignSettingUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetSignSettingUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetSignSettingUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetSignSettingUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetSignSettingUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.SetSignSettingUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.SetSignSettingUserResponse} returns this
*/
proto.SetSignSettingUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SetSignSettingUserResponse} returns this
 */
proto.SetSignSettingUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SetSignSettingUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetLatestFirmwareUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetLatestFirmwareUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetLatestFirmwareUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLatestFirmwareUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetLatestFirmwareUserRequest}
 */
proto.GetLatestFirmwareUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetLatestFirmwareUserRequest;
  return proto.GetLatestFirmwareUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetLatestFirmwareUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetLatestFirmwareUserRequest}
 */
proto.GetLatestFirmwareUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetLatestFirmwareUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetLatestFirmwareUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetLatestFirmwareUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLatestFirmwareUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetLatestFirmwareUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetLatestFirmwareUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetLatestFirmwareUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLatestFirmwareUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    latestfirmware: jspb.Message.getFieldWithDefault(msg, 1, ""),
    latestappversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetLatestFirmwareUserResponse}
 */
proto.GetLatestFirmwareUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetLatestFirmwareUserResponse;
  return proto.GetLatestFirmwareUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetLatestFirmwareUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetLatestFirmwareUserResponse}
 */
proto.GetLatestFirmwareUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestfirmware(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLatestappversion(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetLatestFirmwareUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetLatestFirmwareUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetLatestFirmwareUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLatestFirmwareUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLatestfirmware();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLatestappversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string latestFirmware = 1;
 * @return {string}
 */
proto.GetLatestFirmwareUserResponse.prototype.getLatestfirmware = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLatestFirmwareUserResponse} returns this
 */
proto.GetLatestFirmwareUserResponse.prototype.setLatestfirmware = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string latestAppVersion = 2;
 * @return {string}
 */
proto.GetLatestFirmwareUserResponse.prototype.getLatestappversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLatestFirmwareUserResponse} returns this
 */
proto.GetLatestFirmwareUserResponse.prototype.setLatestappversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetLatestFirmwareUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetLatestFirmwareUserResponse} returns this
*/
proto.GetLatestFirmwareUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetLatestFirmwareUserResponse} returns this
 */
proto.GetLatestFirmwareUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetLatestFirmwareUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetLogFileUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetLogFileUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetLogFileUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLogFileUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    date: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetLogFileUserRequest}
 */
proto.GetLogFileUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetLogFileUserRequest;
  return proto.GetLogFileUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetLogFileUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetLogFileUserRequest}
 */
proto.GetLogFileUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetLogFileUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetLogFileUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetLogFileUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLogFileUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.GetLogFileUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLogFileUserRequest} returns this
 */
proto.GetLogFileUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string date = 2;
 * @return {string}
 */
proto.GetLogFileUserRequest.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLogFileUserRequest} returns this
 */
proto.GetLogFileUserRequest.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetLogFileUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetLogFileUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetLogFileUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLogFileUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    log: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetLogFileUserResponse}
 */
proto.GetLogFileUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetLogFileUserResponse;
  return proto.GetLogFileUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetLogFileUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetLogFileUserResponse}
 */
proto.GetLogFileUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetLogFileUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetLogFileUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetLogFileUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetLogFileUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string log = 1;
 * @return {string}
 */
proto.GetLogFileUserResponse.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetLogFileUserResponse} returns this
 */
proto.GetLogFileUserResponse.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetLogFileUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetLogFileUserResponse} returns this
*/
proto.GetLogFileUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetLogFileUserResponse} returns this
 */
proto.GetLogFileUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetLogFileUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteStationUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteStationUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteStationUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteStationUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteStationUserRequest}
 */
proto.DeleteStationUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteStationUserRequest;
  return proto.DeleteStationUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteStationUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteStationUserRequest}
 */
proto.DeleteStationUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteStationUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteStationUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteStationUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteStationUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.DeleteStationUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteStationUserRequest} returns this
 */
proto.DeleteStationUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteStationUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteStationUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteStationUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteStationUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteStationUserResponse}
 */
proto.DeleteStationUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteStationUserResponse;
  return proto.DeleteStationUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteStationUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteStationUserResponse}
 */
proto.DeleteStationUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteStationUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteStationUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteStationUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteStationUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeleteStationUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeleteStationUserResponse} returns this
*/
proto.DeleteStationUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeleteStationUserResponse} returns this
 */
proto.DeleteStationUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeleteStationUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EditNumberCardUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.EditNumberCardUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EditNumberCardUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditNumberCardUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ncard: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EditNumberCardUserRequest}
 */
proto.EditNumberCardUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EditNumberCardUserRequest;
  return proto.EditNumberCardUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EditNumberCardUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EditNumberCardUserRequest}
 */
proto.EditNumberCardUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNcard(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EditNumberCardUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EditNumberCardUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EditNumberCardUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditNumberCardUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNcard();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.EditNumberCardUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.EditNumberCardUserRequest} returns this
 */
proto.EditNumberCardUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 nCard = 2;
 * @return {number}
 */
proto.EditNumberCardUserRequest.prototype.getNcard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.EditNumberCardUserRequest} returns this
 */
proto.EditNumberCardUserRequest.prototype.setNcard = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EditNumberCardUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.EditNumberCardUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EditNumberCardUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditNumberCardUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ncard: jspb.Message.getFieldWithDefault(msg, 1, 0),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EditNumberCardUserResponse}
 */
proto.EditNumberCardUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EditNumberCardUserResponse;
  return proto.EditNumberCardUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EditNumberCardUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EditNumberCardUserResponse}
 */
proto.EditNumberCardUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNcard(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EditNumberCardUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EditNumberCardUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EditNumberCardUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditNumberCardUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNcard();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 nCard = 1;
 * @return {number}
 */
proto.EditNumberCardUserResponse.prototype.getNcard = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.EditNumberCardUserResponse} returns this
 */
proto.EditNumberCardUserResponse.prototype.setNcard = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.EditNumberCardUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.EditNumberCardUserResponse} returns this
*/
proto.EditNumberCardUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EditNumberCardUserResponse} returns this
 */
proto.EditNumberCardUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EditNumberCardUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateErrorHandlingUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateErrorHandlingUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateErrorHandlingUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateErrorHandlingUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorstatus: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateErrorHandlingUserRequest}
 */
proto.UpdateErrorHandlingUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateErrorHandlingUserRequest;
  return proto.UpdateErrorHandlingUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateErrorHandlingUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateErrorHandlingUserRequest}
 */
proto.UpdateErrorHandlingUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setErrorstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateErrorHandlingUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateErrorHandlingUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateErrorHandlingUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateErrorHandlingUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorstatus();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.UpdateErrorHandlingUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateErrorHandlingUserRequest} returns this
 */
proto.UpdateErrorHandlingUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 errorStatus = 2;
 * @return {number}
 */
proto.UpdateErrorHandlingUserRequest.prototype.getErrorstatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateErrorHandlingUserRequest} returns this
 */
proto.UpdateErrorHandlingUserRequest.prototype.setErrorstatus = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateDiaryLogUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateDiaryLogUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateDiaryLogUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDiaryLogUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateDiaryLogUserRequest}
 */
proto.UpdateDiaryLogUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateDiaryLogUserRequest;
  return proto.UpdateDiaryLogUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateDiaryLogUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateDiaryLogUserRequest}
 */
proto.UpdateDiaryLogUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateDiaryLogUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateDiaryLogUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateDiaryLogUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDiaryLogUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.UpdateDiaryLogUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateDiaryLogUserRequest} returns this
 */
proto.UpdateDiaryLogUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.UpdateDiaryLogUserRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateDiaryLogUserRequest} returns this
 */
proto.UpdateDiaryLogUserRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateDiaryLogUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateDiaryLogUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateDiaryLogUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDiaryLogUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fulllog: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateDiaryLogUserResponse}
 */
proto.UpdateDiaryLogUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateDiaryLogUserResponse;
  return proto.UpdateDiaryLogUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateDiaryLogUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateDiaryLogUserResponse}
 */
proto.UpdateDiaryLogUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFulllog(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateDiaryLogUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateDiaryLogUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateDiaryLogUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateDiaryLogUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFulllog();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string fullLog = 1;
 * @return {string}
 */
proto.UpdateDiaryLogUserResponse.prototype.getFulllog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateDiaryLogUserResponse} returns this
 */
proto.UpdateDiaryLogUserResponse.prototype.setFulllog = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.UpdateDiaryLogUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.UpdateDiaryLogUserResponse} returns this
*/
proto.UpdateDiaryLogUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateDiaryLogUserResponse} returns this
 */
proto.UpdateDiaryLogUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateDiaryLogUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InsertAccessoryUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.InsertAccessoryUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InsertAccessoryUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InsertAccessoryUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessory: (f = msg.getAccessory()) && common_proto_pb.Accessory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InsertAccessoryUserRequest}
 */
proto.InsertAccessoryUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InsertAccessoryUserRequest;
  return proto.InsertAccessoryUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InsertAccessoryUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InsertAccessoryUserRequest}
 */
proto.InsertAccessoryUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Accessory;
      reader.readMessage(value,common_proto_pb.Accessory.deserializeBinaryFromReader);
      msg.setAccessory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InsertAccessoryUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InsertAccessoryUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InsertAccessoryUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InsertAccessoryUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessory();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_proto_pb.Accessory.serializeBinaryToWriter
    );
  }
};


/**
 * optional Accessory accessory = 1;
 * @return {?proto.Accessory}
 */
proto.InsertAccessoryUserRequest.prototype.getAccessory = function() {
  return /** @type{?proto.Accessory} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.Accessory, 1));
};


/**
 * @param {?proto.Accessory|undefined} value
 * @return {!proto.InsertAccessoryUserRequest} returns this
*/
proto.InsertAccessoryUserRequest.prototype.setAccessory = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InsertAccessoryUserRequest} returns this
 */
proto.InsertAccessoryUserRequest.prototype.clearAccessory = function() {
  return this.setAccessory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InsertAccessoryUserRequest.prototype.hasAccessory = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.InsertAccessoryUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InsertAccessoryUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.InsertAccessoryUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InsertAccessoryUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InsertAccessoryUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessoriesList: jspb.Message.toObjectList(msg.getAccessoriesList(),
    common_proto_pb.Accessory.toObject, includeInstance),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InsertAccessoryUserResponse}
 */
proto.InsertAccessoryUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InsertAccessoryUserResponse;
  return proto.InsertAccessoryUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InsertAccessoryUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InsertAccessoryUserResponse}
 */
proto.InsertAccessoryUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Accessory;
      reader.readMessage(value,common_proto_pb.Accessory.deserializeBinaryFromReader);
      msg.addAccessories(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InsertAccessoryUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InsertAccessoryUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InsertAccessoryUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InsertAccessoryUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_proto_pb.Accessory.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Accessory accessories = 1;
 * @return {!Array<!proto.Accessory>}
 */
proto.InsertAccessoryUserResponse.prototype.getAccessoriesList = function() {
  return /** @type{!Array<!proto.Accessory>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.Accessory, 1));
};


/**
 * @param {!Array<!proto.Accessory>} value
 * @return {!proto.InsertAccessoryUserResponse} returns this
*/
proto.InsertAccessoryUserResponse.prototype.setAccessoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Accessory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Accessory}
 */
proto.InsertAccessoryUserResponse.prototype.addAccessories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Accessory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.InsertAccessoryUserResponse} returns this
 */
proto.InsertAccessoryUserResponse.prototype.clearAccessoriesList = function() {
  return this.setAccessoriesList([]);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.InsertAccessoryUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.InsertAccessoryUserResponse} returns this
*/
proto.InsertAccessoryUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.InsertAccessoryUserResponse} returns this
 */
proto.InsertAccessoryUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.InsertAccessoryUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAccessoriesUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAccessoriesUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAccessoriesUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccessoriesUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAccessoriesUserRequest}
 */
proto.GetAccessoriesUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAccessoriesUserRequest;
  return proto.GetAccessoriesUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAccessoriesUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAccessoriesUserRequest}
 */
proto.GetAccessoriesUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAccessoriesUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAccessoriesUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAccessoriesUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccessoriesUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.GetAccessoriesUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAccessoriesUserRequest} returns this
 */
proto.GetAccessoriesUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetAccessoriesUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAccessoriesUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAccessoriesUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAccessoriesUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccessoriesUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessoriesList: jspb.Message.toObjectList(msg.getAccessoriesList(),
    common_proto_pb.Accessory.toObject, includeInstance),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAccessoriesUserResponse}
 */
proto.GetAccessoriesUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAccessoriesUserResponse;
  return proto.GetAccessoriesUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAccessoriesUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAccessoriesUserResponse}
 */
proto.GetAccessoriesUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Accessory;
      reader.readMessage(value,common_proto_pb.Accessory.deserializeBinaryFromReader);
      msg.addAccessories(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAccessoriesUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAccessoriesUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAccessoriesUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccessoriesUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_proto_pb.Accessory.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Accessory accessories = 1;
 * @return {!Array<!proto.Accessory>}
 */
proto.GetAccessoriesUserResponse.prototype.getAccessoriesList = function() {
  return /** @type{!Array<!proto.Accessory>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.Accessory, 1));
};


/**
 * @param {!Array<!proto.Accessory>} value
 * @return {!proto.GetAccessoriesUserResponse} returns this
*/
proto.GetAccessoriesUserResponse.prototype.setAccessoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Accessory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Accessory}
 */
proto.GetAccessoriesUserResponse.prototype.addAccessories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Accessory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetAccessoriesUserResponse} returns this
 */
proto.GetAccessoriesUserResponse.prototype.clearAccessoriesList = function() {
  return this.setAccessoriesList([]);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetAccessoriesUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetAccessoriesUserResponse} returns this
*/
proto.GetAccessoriesUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetAccessoriesUserResponse} returns this
 */
proto.GetAccessoriesUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetAccessoriesUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteAccessoryUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteAccessoryUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteAccessoryUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteAccessoryUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessoryid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteAccessoryUserRequest}
 */
proto.DeleteAccessoryUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteAccessoryUserRequest;
  return proto.DeleteAccessoryUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteAccessoryUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteAccessoryUserRequest}
 */
proto.DeleteAccessoryUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessoryid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteAccessoryUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteAccessoryUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteAccessoryUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteAccessoryUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessoryid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.DeleteAccessoryUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteAccessoryUserRequest} returns this
 */
proto.DeleteAccessoryUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string accessoryId = 2;
 * @return {string}
 */
proto.DeleteAccessoryUserRequest.prototype.getAccessoryid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteAccessoryUserRequest} returns this
 */
proto.DeleteAccessoryUserRequest.prototype.setAccessoryid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DeleteAccessoryUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteAccessoryUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteAccessoryUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteAccessoryUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteAccessoryUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessoriesList: jspb.Message.toObjectList(msg.getAccessoriesList(),
    common_proto_pb.Accessory.toObject, includeInstance),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteAccessoryUserResponse}
 */
proto.DeleteAccessoryUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteAccessoryUserResponse;
  return proto.DeleteAccessoryUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteAccessoryUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteAccessoryUserResponse}
 */
proto.DeleteAccessoryUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Accessory;
      reader.readMessage(value,common_proto_pb.Accessory.deserializeBinaryFromReader);
      msg.addAccessories(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteAccessoryUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteAccessoryUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteAccessoryUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteAccessoryUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_proto_pb.Accessory.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Accessory accessories = 1;
 * @return {!Array<!proto.Accessory>}
 */
proto.DeleteAccessoryUserResponse.prototype.getAccessoriesList = function() {
  return /** @type{!Array<!proto.Accessory>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.Accessory, 1));
};


/**
 * @param {!Array<!proto.Accessory>} value
 * @return {!proto.DeleteAccessoryUserResponse} returns this
*/
proto.DeleteAccessoryUserResponse.prototype.setAccessoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Accessory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Accessory}
 */
proto.DeleteAccessoryUserResponse.prototype.addAccessories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Accessory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeleteAccessoryUserResponse} returns this
 */
proto.DeleteAccessoryUserResponse.prototype.clearAccessoriesList = function() {
  return this.setAccessoriesList([]);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeleteAccessoryUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeleteAccessoryUserResponse} returns this
*/
proto.DeleteAccessoryUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeleteAccessoryUserResponse} returns this
 */
proto.DeleteAccessoryUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeleteAccessoryUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EditAccessoryUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.EditAccessoryUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EditAccessoryUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditAccessoryUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessories: (f = msg.getAccessories()) && common_proto_pb.Accessory.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EditAccessoryUserRequest}
 */
proto.EditAccessoryUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EditAccessoryUserRequest;
  return proto.EditAccessoryUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EditAccessoryUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EditAccessoryUserRequest}
 */
proto.EditAccessoryUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Accessory;
      reader.readMessage(value,common_proto_pb.Accessory.deserializeBinaryFromReader);
      msg.setAccessories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EditAccessoryUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EditAccessoryUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EditAccessoryUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditAccessoryUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessories();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_proto_pb.Accessory.serializeBinaryToWriter
    );
  }
};


/**
 * optional Accessory accessories = 1;
 * @return {?proto.Accessory}
 */
proto.EditAccessoryUserRequest.prototype.getAccessories = function() {
  return /** @type{?proto.Accessory} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.Accessory, 1));
};


/**
 * @param {?proto.Accessory|undefined} value
 * @return {!proto.EditAccessoryUserRequest} returns this
*/
proto.EditAccessoryUserRequest.prototype.setAccessories = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EditAccessoryUserRequest} returns this
 */
proto.EditAccessoryUserRequest.prototype.clearAccessories = function() {
  return this.setAccessories(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EditAccessoryUserRequest.prototype.hasAccessories = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.EditAccessoryUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.EditAccessoryUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.EditAccessoryUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.EditAccessoryUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditAccessoryUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessoriesList: jspb.Message.toObjectList(msg.getAccessoriesList(),
    common_proto_pb.Accessory.toObject, includeInstance),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.EditAccessoryUserResponse}
 */
proto.EditAccessoryUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.EditAccessoryUserResponse;
  return proto.EditAccessoryUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.EditAccessoryUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.EditAccessoryUserResponse}
 */
proto.EditAccessoryUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Accessory;
      reader.readMessage(value,common_proto_pb.Accessory.deserializeBinaryFromReader);
      msg.addAccessories(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.EditAccessoryUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.EditAccessoryUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.EditAccessoryUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.EditAccessoryUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_proto_pb.Accessory.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Accessory accessories = 1;
 * @return {!Array<!proto.Accessory>}
 */
proto.EditAccessoryUserResponse.prototype.getAccessoriesList = function() {
  return /** @type{!Array<!proto.Accessory>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.Accessory, 1));
};


/**
 * @param {!Array<!proto.Accessory>} value
 * @return {!proto.EditAccessoryUserResponse} returns this
*/
proto.EditAccessoryUserResponse.prototype.setAccessoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Accessory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Accessory}
 */
proto.EditAccessoryUserResponse.prototype.addAccessories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Accessory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.EditAccessoryUserResponse} returns this
 */
proto.EditAccessoryUserResponse.prototype.clearAccessoriesList = function() {
  return this.setAccessoriesList([]);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.EditAccessoryUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.EditAccessoryUserResponse} returns this
*/
proto.EditAccessoryUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.EditAccessoryUserResponse} returns this
 */
proto.EditAccessoryUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.EditAccessoryUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateScheduleUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateScheduleUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateScheduleUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timelampform: (f = msg.getTimelampform()) && common_proto_pb.TimeLampForm.toObject(includeInstance, f),
    cycle: (f = msg.getCycle()) && common_proto_pb.CycleLampForm.toObject(includeInstance, f),
    days: (f = msg.getDays()) && common_proto_pb.DayLampForm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateScheduleUserRequest}
 */
proto.UpdateScheduleUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateScheduleUserRequest;
  return proto.UpdateScheduleUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateScheduleUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateScheduleUserRequest}
 */
proto.UpdateScheduleUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = new common_proto_pb.TimeLampForm;
      reader.readMessage(value,common_proto_pb.TimeLampForm.deserializeBinaryFromReader);
      msg.setTimelampform(value);
      break;
    case 3:
      var value = new common_proto_pb.CycleLampForm;
      reader.readMessage(value,common_proto_pb.CycleLampForm.deserializeBinaryFromReader);
      msg.setCycle(value);
      break;
    case 4:
      var value = new common_proto_pb.DayLampForm;
      reader.readMessage(value,common_proto_pb.DayLampForm.deserializeBinaryFromReader);
      msg.setDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateScheduleUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateScheduleUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateScheduleUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimelampform();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.TimeLampForm.serializeBinaryToWriter
    );
  }
  f = message.getCycle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_proto_pb.CycleLampForm.serializeBinaryToWriter
    );
  }
  f = message.getDays();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_proto_pb.DayLampForm.serializeBinaryToWriter
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.UpdateScheduleUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateScheduleUserRequest} returns this
 */
proto.UpdateScheduleUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TimeLampForm timeLampForm = 2;
 * @return {?proto.TimeLampForm}
 */
proto.UpdateScheduleUserRequest.prototype.getTimelampform = function() {
  return /** @type{?proto.TimeLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.TimeLampForm, 2));
};


/**
 * @param {?proto.TimeLampForm|undefined} value
 * @return {!proto.UpdateScheduleUserRequest} returns this
*/
proto.UpdateScheduleUserRequest.prototype.setTimelampform = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserRequest} returns this
 */
proto.UpdateScheduleUserRequest.prototype.clearTimelampform = function() {
  return this.setTimelampform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserRequest.prototype.hasTimelampform = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CycleLampForm cycle = 3;
 * @return {?proto.CycleLampForm}
 */
proto.UpdateScheduleUserRequest.prototype.getCycle = function() {
  return /** @type{?proto.CycleLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.CycleLampForm, 3));
};


/**
 * @param {?proto.CycleLampForm|undefined} value
 * @return {!proto.UpdateScheduleUserRequest} returns this
*/
proto.UpdateScheduleUserRequest.prototype.setCycle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserRequest} returns this
 */
proto.UpdateScheduleUserRequest.prototype.clearCycle = function() {
  return this.setCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserRequest.prototype.hasCycle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DayLampForm days = 4;
 * @return {?proto.DayLampForm}
 */
proto.UpdateScheduleUserRequest.prototype.getDays = function() {
  return /** @type{?proto.DayLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DayLampForm, 4));
};


/**
 * @param {?proto.DayLampForm|undefined} value
 * @return {!proto.UpdateScheduleUserRequest} returns this
*/
proto.UpdateScheduleUserRequest.prototype.setDays = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserRequest} returns this
 */
proto.UpdateScheduleUserRequest.prototype.clearDays = function() {
  return this.setDays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserRequest.prototype.hasDays = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateScheduleUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateScheduleUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateScheduleUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateScheduleUserResponse}
 */
proto.UpdateScheduleUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateScheduleUserResponse;
  return proto.UpdateScheduleUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateScheduleUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateScheduleUserResponse}
 */
proto.UpdateScheduleUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateScheduleUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateScheduleUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateScheduleUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.UpdateScheduleUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.UpdateScheduleUserResponse} returns this
*/
proto.UpdateScheduleUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserResponse} returns this
 */
proto.UpdateScheduleUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetScheduleUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetScheduleUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetScheduleUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetScheduleUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetScheduleUserRequest}
 */
proto.GetScheduleUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetScheduleUserRequest;
  return proto.GetScheduleUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetScheduleUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetScheduleUserRequest}
 */
proto.GetScheduleUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetScheduleUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetScheduleUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetScheduleUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetScheduleUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.GetScheduleUserRequest.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetScheduleUserRequest} returns this
 */
proto.GetScheduleUserRequest.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetScheduleUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetScheduleUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetScheduleUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetScheduleUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stationid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timelampform: (f = msg.getTimelampform()) && common_proto_pb.TimeLampForm.toObject(includeInstance, f),
    cycle: (f = msg.getCycle()) && common_proto_pb.CycleLampForm.toObject(includeInstance, f),
    days: (f = msg.getDays()) && common_proto_pb.DayLampForm.toObject(includeInstance, f),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetScheduleUserResponse}
 */
proto.GetScheduleUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetScheduleUserResponse;
  return proto.GetScheduleUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetScheduleUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetScheduleUserResponse}
 */
proto.GetScheduleUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStationid(value);
      break;
    case 2:
      var value = new common_proto_pb.TimeLampForm;
      reader.readMessage(value,common_proto_pb.TimeLampForm.deserializeBinaryFromReader);
      msg.setTimelampform(value);
      break;
    case 3:
      var value = new common_proto_pb.CycleLampForm;
      reader.readMessage(value,common_proto_pb.CycleLampForm.deserializeBinaryFromReader);
      msg.setCycle(value);
      break;
    case 4:
      var value = new common_proto_pb.DayLampForm;
      reader.readMessage(value,common_proto_pb.DayLampForm.deserializeBinaryFromReader);
      msg.setDays(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetScheduleUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetScheduleUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetScheduleUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetScheduleUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStationid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimelampform();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.TimeLampForm.serializeBinaryToWriter
    );
  }
  f = message.getCycle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_proto_pb.CycleLampForm.serializeBinaryToWriter
    );
  }
  f = message.getDays();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_proto_pb.DayLampForm.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string stationId = 1;
 * @return {string}
 */
proto.GetScheduleUserResponse.prototype.getStationid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetScheduleUserResponse} returns this
 */
proto.GetScheduleUserResponse.prototype.setStationid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TimeLampForm timeLampForm = 2;
 * @return {?proto.TimeLampForm}
 */
proto.GetScheduleUserResponse.prototype.getTimelampform = function() {
  return /** @type{?proto.TimeLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.TimeLampForm, 2));
};


/**
 * @param {?proto.TimeLampForm|undefined} value
 * @return {!proto.GetScheduleUserResponse} returns this
*/
proto.GetScheduleUserResponse.prototype.setTimelampform = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetScheduleUserResponse} returns this
 */
proto.GetScheduleUserResponse.prototype.clearTimelampform = function() {
  return this.setTimelampform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetScheduleUserResponse.prototype.hasTimelampform = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CycleLampForm cycle = 3;
 * @return {?proto.CycleLampForm}
 */
proto.GetScheduleUserResponse.prototype.getCycle = function() {
  return /** @type{?proto.CycleLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.CycleLampForm, 3));
};


/**
 * @param {?proto.CycleLampForm|undefined} value
 * @return {!proto.GetScheduleUserResponse} returns this
*/
proto.GetScheduleUserResponse.prototype.setCycle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetScheduleUserResponse} returns this
 */
proto.GetScheduleUserResponse.prototype.clearCycle = function() {
  return this.setCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetScheduleUserResponse.prototype.hasCycle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DayLampForm days = 4;
 * @return {?proto.DayLampForm}
 */
proto.GetScheduleUserResponse.prototype.getDays = function() {
  return /** @type{?proto.DayLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DayLampForm, 4));
};


/**
 * @param {?proto.DayLampForm|undefined} value
 * @return {!proto.GetScheduleUserResponse} returns this
*/
proto.GetScheduleUserResponse.prototype.setDays = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetScheduleUserResponse} returns this
 */
proto.GetScheduleUserResponse.prototype.clearDays = function() {
  return this.setDays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetScheduleUserResponse.prototype.hasDays = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetScheduleUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetScheduleUserResponse} returns this
*/
proto.GetScheduleUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetScheduleUserResponse} returns this
 */
proto.GetScheduleUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetScheduleUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StationUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.StationUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StationUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StationUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    getstationuserrequest: (f = msg.getGetstationuserrequest()) && proto.GetStationUserRequest.toObject(includeInstance, f),
    getstationuserresponse: (f = msg.getGetstationuserresponse()) && proto.GetStationUserResponse.toObject(includeInstance, f),
    getstationlistuserrequest: (f = msg.getGetstationlistuserrequest()) && proto.GetStationListUserRequest.toObject(includeInstance, f),
    getstationlistuserresponse: (f = msg.getGetstationlistuserresponse()) && proto.GetStationListUserResponse.toObject(includeInstance, f),
    addstationuserrequest: (f = msg.getAddstationuserrequest()) && proto.AddStationUserRequest.toObject(includeInstance, f),
    addstationuserresponse: (f = msg.getAddstationuserresponse()) && proto.AddStationUserResponse.toObject(includeInstance, f),
    setsignsettinguserrequest: (f = msg.getSetsignsettinguserrequest()) && proto.SetSignSettingUserRequest.toObject(includeInstance, f),
    setsignsettinguserresponse: (f = msg.getSetsignsettinguserresponse()) && proto.SetSignSettingUserResponse.toObject(includeInstance, f),
    getlatestfirmwareuserrequest: (f = msg.getGetlatestfirmwareuserrequest()) && proto.GetLatestFirmwareUserRequest.toObject(includeInstance, f),
    getlatestfirmwareuserresponse: (f = msg.getGetlatestfirmwareuserresponse()) && proto.GetLatestFirmwareUserResponse.toObject(includeInstance, f),
    getlogfileuserrequest: (f = msg.getGetlogfileuserrequest()) && proto.GetLogFileUserRequest.toObject(includeInstance, f),
    getlogfileuserresponse: (f = msg.getGetlogfileuserresponse()) && proto.GetLogFileUserResponse.toObject(includeInstance, f),
    deletestationuserrequest: (f = msg.getDeletestationuserrequest()) && proto.DeleteStationUserRequest.toObject(includeInstance, f),
    deletestationuserresponse: (f = msg.getDeletestationuserresponse()) && proto.DeleteStationUserResponse.toObject(includeInstance, f),
    editnumbercarduserrequest: (f = msg.getEditnumbercarduserrequest()) && proto.EditNumberCardUserRequest.toObject(includeInstance, f),
    editnumbercarduserresponse: (f = msg.getEditnumbercarduserresponse()) && proto.EditNumberCardUserResponse.toObject(includeInstance, f),
    updateerrorhandlinguserrequest: (f = msg.getUpdateerrorhandlinguserrequest()) && proto.UpdateErrorHandlingUserRequest.toObject(includeInstance, f),
    updatediaryloguserrequest: (f = msg.getUpdatediaryloguserrequest()) && proto.UpdateDiaryLogUserRequest.toObject(includeInstance, f),
    updatediaryloguserresponse: (f = msg.getUpdatediaryloguserresponse()) && proto.UpdateDiaryLogUserResponse.toObject(includeInstance, f),
    insertaccessoryuserrequest: (f = msg.getInsertaccessoryuserrequest()) && proto.InsertAccessoryUserRequest.toObject(includeInstance, f),
    insertaccessoryuserresponse: (f = msg.getInsertaccessoryuserresponse()) && proto.InsertAccessoryUserResponse.toObject(includeInstance, f),
    getaccessoriesuserrequest: (f = msg.getGetaccessoriesuserrequest()) && proto.GetAccessoriesUserRequest.toObject(includeInstance, f),
    getaccessoriesuserresponse: (f = msg.getGetaccessoriesuserresponse()) && proto.GetAccessoriesUserResponse.toObject(includeInstance, f),
    deleteaccessoryuserrequest: (f = msg.getDeleteaccessoryuserrequest()) && proto.DeleteAccessoryUserRequest.toObject(includeInstance, f),
    deleteaccessoryuserresponse: (f = msg.getDeleteaccessoryuserresponse()) && proto.DeleteAccessoryUserResponse.toObject(includeInstance, f),
    editaccessoryuserrequest: (f = msg.getEditaccessoryuserrequest()) && proto.EditAccessoryUserRequest.toObject(includeInstance, f),
    editaccessoryuserresponse: (f = msg.getEditaccessoryuserresponse()) && proto.EditAccessoryUserResponse.toObject(includeInstance, f),
    updatescheduleuserrequest: (f = msg.getUpdatescheduleuserrequest()) && proto.UpdateScheduleUserRequest.toObject(includeInstance, f),
    updatescheduleuserresponse: (f = msg.getUpdatescheduleuserresponse()) && proto.UpdateScheduleUserResponse.toObject(includeInstance, f),
    getscheduleuserrequest: (f = msg.getGetscheduleuserrequest()) && proto.GetScheduleUserRequest.toObject(includeInstance, f),
    getscheduleuserresponse: (f = msg.getGetscheduleuserresponse()) && proto.GetScheduleUserResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StationUserMessage}
 */
proto.StationUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StationUserMessage;
  return proto.StationUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StationUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StationUserMessage}
 */
proto.StationUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GetStationUserRequest;
      reader.readMessage(value,proto.GetStationUserRequest.deserializeBinaryFromReader);
      msg.setGetstationuserrequest(value);
      break;
    case 2:
      var value = new proto.GetStationUserResponse;
      reader.readMessage(value,proto.GetStationUserResponse.deserializeBinaryFromReader);
      msg.setGetstationuserresponse(value);
      break;
    case 3:
      var value = new proto.GetStationListUserRequest;
      reader.readMessage(value,proto.GetStationListUserRequest.deserializeBinaryFromReader);
      msg.setGetstationlistuserrequest(value);
      break;
    case 4:
      var value = new proto.GetStationListUserResponse;
      reader.readMessage(value,proto.GetStationListUserResponse.deserializeBinaryFromReader);
      msg.setGetstationlistuserresponse(value);
      break;
    case 5:
      var value = new proto.AddStationUserRequest;
      reader.readMessage(value,proto.AddStationUserRequest.deserializeBinaryFromReader);
      msg.setAddstationuserrequest(value);
      break;
    case 6:
      var value = new proto.AddStationUserResponse;
      reader.readMessage(value,proto.AddStationUserResponse.deserializeBinaryFromReader);
      msg.setAddstationuserresponse(value);
      break;
    case 7:
      var value = new proto.SetSignSettingUserRequest;
      reader.readMessage(value,proto.SetSignSettingUserRequest.deserializeBinaryFromReader);
      msg.setSetsignsettinguserrequest(value);
      break;
    case 8:
      var value = new proto.SetSignSettingUserResponse;
      reader.readMessage(value,proto.SetSignSettingUserResponse.deserializeBinaryFromReader);
      msg.setSetsignsettinguserresponse(value);
      break;
    case 9:
      var value = new proto.GetLatestFirmwareUserRequest;
      reader.readMessage(value,proto.GetLatestFirmwareUserRequest.deserializeBinaryFromReader);
      msg.setGetlatestfirmwareuserrequest(value);
      break;
    case 10:
      var value = new proto.GetLatestFirmwareUserResponse;
      reader.readMessage(value,proto.GetLatestFirmwareUserResponse.deserializeBinaryFromReader);
      msg.setGetlatestfirmwareuserresponse(value);
      break;
    case 11:
      var value = new proto.GetLogFileUserRequest;
      reader.readMessage(value,proto.GetLogFileUserRequest.deserializeBinaryFromReader);
      msg.setGetlogfileuserrequest(value);
      break;
    case 12:
      var value = new proto.GetLogFileUserResponse;
      reader.readMessage(value,proto.GetLogFileUserResponse.deserializeBinaryFromReader);
      msg.setGetlogfileuserresponse(value);
      break;
    case 13:
      var value = new proto.DeleteStationUserRequest;
      reader.readMessage(value,proto.DeleteStationUserRequest.deserializeBinaryFromReader);
      msg.setDeletestationuserrequest(value);
      break;
    case 14:
      var value = new proto.DeleteStationUserResponse;
      reader.readMessage(value,proto.DeleteStationUserResponse.deserializeBinaryFromReader);
      msg.setDeletestationuserresponse(value);
      break;
    case 15:
      var value = new proto.EditNumberCardUserRequest;
      reader.readMessage(value,proto.EditNumberCardUserRequest.deserializeBinaryFromReader);
      msg.setEditnumbercarduserrequest(value);
      break;
    case 16:
      var value = new proto.EditNumberCardUserResponse;
      reader.readMessage(value,proto.EditNumberCardUserResponse.deserializeBinaryFromReader);
      msg.setEditnumbercarduserresponse(value);
      break;
    case 17:
      var value = new proto.UpdateErrorHandlingUserRequest;
      reader.readMessage(value,proto.UpdateErrorHandlingUserRequest.deserializeBinaryFromReader);
      msg.setUpdateerrorhandlinguserrequest(value);
      break;
    case 18:
      var value = new proto.UpdateDiaryLogUserRequest;
      reader.readMessage(value,proto.UpdateDiaryLogUserRequest.deserializeBinaryFromReader);
      msg.setUpdatediaryloguserrequest(value);
      break;
    case 19:
      var value = new proto.UpdateDiaryLogUserResponse;
      reader.readMessage(value,proto.UpdateDiaryLogUserResponse.deserializeBinaryFromReader);
      msg.setUpdatediaryloguserresponse(value);
      break;
    case 20:
      var value = new proto.InsertAccessoryUserRequest;
      reader.readMessage(value,proto.InsertAccessoryUserRequest.deserializeBinaryFromReader);
      msg.setInsertaccessoryuserrequest(value);
      break;
    case 21:
      var value = new proto.InsertAccessoryUserResponse;
      reader.readMessage(value,proto.InsertAccessoryUserResponse.deserializeBinaryFromReader);
      msg.setInsertaccessoryuserresponse(value);
      break;
    case 22:
      var value = new proto.GetAccessoriesUserRequest;
      reader.readMessage(value,proto.GetAccessoriesUserRequest.deserializeBinaryFromReader);
      msg.setGetaccessoriesuserrequest(value);
      break;
    case 23:
      var value = new proto.GetAccessoriesUserResponse;
      reader.readMessage(value,proto.GetAccessoriesUserResponse.deserializeBinaryFromReader);
      msg.setGetaccessoriesuserresponse(value);
      break;
    case 24:
      var value = new proto.DeleteAccessoryUserRequest;
      reader.readMessage(value,proto.DeleteAccessoryUserRequest.deserializeBinaryFromReader);
      msg.setDeleteaccessoryuserrequest(value);
      break;
    case 25:
      var value = new proto.DeleteAccessoryUserResponse;
      reader.readMessage(value,proto.DeleteAccessoryUserResponse.deserializeBinaryFromReader);
      msg.setDeleteaccessoryuserresponse(value);
      break;
    case 26:
      var value = new proto.EditAccessoryUserRequest;
      reader.readMessage(value,proto.EditAccessoryUserRequest.deserializeBinaryFromReader);
      msg.setEditaccessoryuserrequest(value);
      break;
    case 27:
      var value = new proto.EditAccessoryUserResponse;
      reader.readMessage(value,proto.EditAccessoryUserResponse.deserializeBinaryFromReader);
      msg.setEditaccessoryuserresponse(value);
      break;
    case 28:
      var value = new proto.UpdateScheduleUserRequest;
      reader.readMessage(value,proto.UpdateScheduleUserRequest.deserializeBinaryFromReader);
      msg.setUpdatescheduleuserrequest(value);
      break;
    case 29:
      var value = new proto.UpdateScheduleUserResponse;
      reader.readMessage(value,proto.UpdateScheduleUserResponse.deserializeBinaryFromReader);
      msg.setUpdatescheduleuserresponse(value);
      break;
    case 30:
      var value = new proto.GetScheduleUserRequest;
      reader.readMessage(value,proto.GetScheduleUserRequest.deserializeBinaryFromReader);
      msg.setGetscheduleuserrequest(value);
      break;
    case 31:
      var value = new proto.GetScheduleUserResponse;
      reader.readMessage(value,proto.GetScheduleUserResponse.deserializeBinaryFromReader);
      msg.setGetscheduleuserresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StationUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StationUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StationUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StationUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetstationuserrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GetStationUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetstationuserresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.GetStationUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetstationlistuserrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.GetStationListUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetstationlistuserresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.GetStationListUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getAddstationuserrequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.AddStationUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getAddstationuserresponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.AddStationUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getSetsignsettinguserrequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.SetSignSettingUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetsignsettinguserresponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.SetSignSettingUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetlatestfirmwareuserrequest();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.GetLatestFirmwareUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetlatestfirmwareuserresponse();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.GetLatestFirmwareUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetlogfileuserrequest();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.GetLogFileUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetlogfileuserresponse();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.GetLogFileUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeletestationuserrequest();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.DeleteStationUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeletestationuserresponse();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.DeleteStationUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getEditnumbercarduserrequest();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.EditNumberCardUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getEditnumbercarduserresponse();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.EditNumberCardUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdateerrorhandlinguserrequest();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.UpdateErrorHandlingUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatediaryloguserrequest();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.UpdateDiaryLogUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatediaryloguserresponse();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.UpdateDiaryLogUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getInsertaccessoryuserrequest();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.InsertAccessoryUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getInsertaccessoryuserresponse();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.InsertAccessoryUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetaccessoriesuserrequest();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.GetAccessoriesUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetaccessoriesuserresponse();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      proto.GetAccessoriesUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeleteaccessoryuserrequest();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      proto.DeleteAccessoryUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeleteaccessoryuserresponse();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.DeleteAccessoryUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getEditaccessoryuserrequest();
  if (f != null) {
    writer.writeMessage(
      26,
      f,
      proto.EditAccessoryUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getEditaccessoryuserresponse();
  if (f != null) {
    writer.writeMessage(
      27,
      f,
      proto.EditAccessoryUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatescheduleuserrequest();
  if (f != null) {
    writer.writeMessage(
      28,
      f,
      proto.UpdateScheduleUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatescheduleuserresponse();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      proto.UpdateScheduleUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetscheduleuserrequest();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.GetScheduleUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetscheduleuserresponse();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      proto.GetScheduleUserResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetStationUserRequest getStationUserRequest = 1;
 * @return {?proto.GetStationUserRequest}
 */
proto.StationUserMessage.prototype.getGetstationuserrequest = function() {
  return /** @type{?proto.GetStationUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetStationUserRequest, 1));
};


/**
 * @param {?proto.GetStationUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetstationuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetstationuserrequest = function() {
  return this.setGetstationuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetstationuserrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetStationUserResponse getStationUserResponse = 2;
 * @return {?proto.GetStationUserResponse}
 */
proto.StationUserMessage.prototype.getGetstationuserresponse = function() {
  return /** @type{?proto.GetStationUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetStationUserResponse, 2));
};


/**
 * @param {?proto.GetStationUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetstationuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetstationuserresponse = function() {
  return this.setGetstationuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetstationuserresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GetStationListUserRequest getStationListUserRequest = 3;
 * @return {?proto.GetStationListUserRequest}
 */
proto.StationUserMessage.prototype.getGetstationlistuserrequest = function() {
  return /** @type{?proto.GetStationListUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetStationListUserRequest, 3));
};


/**
 * @param {?proto.GetStationListUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetstationlistuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetstationlistuserrequest = function() {
  return this.setGetstationlistuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetstationlistuserrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional GetStationListUserResponse getStationListUserResponse = 4;
 * @return {?proto.GetStationListUserResponse}
 */
proto.StationUserMessage.prototype.getGetstationlistuserresponse = function() {
  return /** @type{?proto.GetStationListUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetStationListUserResponse, 4));
};


/**
 * @param {?proto.GetStationListUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetstationlistuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetstationlistuserresponse = function() {
  return this.setGetstationlistuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetstationlistuserresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AddStationUserRequest addStationUserRequest = 5;
 * @return {?proto.AddStationUserRequest}
 */
proto.StationUserMessage.prototype.getAddstationuserrequest = function() {
  return /** @type{?proto.AddStationUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.AddStationUserRequest, 5));
};


/**
 * @param {?proto.AddStationUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setAddstationuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearAddstationuserrequest = function() {
  return this.setAddstationuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasAddstationuserrequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional AddStationUserResponse addStationUserResponse = 6;
 * @return {?proto.AddStationUserResponse}
 */
proto.StationUserMessage.prototype.getAddstationuserresponse = function() {
  return /** @type{?proto.AddStationUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.AddStationUserResponse, 6));
};


/**
 * @param {?proto.AddStationUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setAddstationuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearAddstationuserresponse = function() {
  return this.setAddstationuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasAddstationuserresponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SetSignSettingUserRequest setSignSettingUserRequest = 7;
 * @return {?proto.SetSignSettingUserRequest}
 */
proto.StationUserMessage.prototype.getSetsignsettinguserrequest = function() {
  return /** @type{?proto.SetSignSettingUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.SetSignSettingUserRequest, 7));
};


/**
 * @param {?proto.SetSignSettingUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setSetsignsettinguserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearSetsignsettinguserrequest = function() {
  return this.setSetsignsettinguserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasSetsignsettinguserrequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SetSignSettingUserResponse setSignSettingUserResponse = 8;
 * @return {?proto.SetSignSettingUserResponse}
 */
proto.StationUserMessage.prototype.getSetsignsettinguserresponse = function() {
  return /** @type{?proto.SetSignSettingUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.SetSignSettingUserResponse, 8));
};


/**
 * @param {?proto.SetSignSettingUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setSetsignsettinguserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearSetsignsettinguserresponse = function() {
  return this.setSetsignsettinguserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasSetsignsettinguserresponse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional GetLatestFirmwareUserRequest getLatestFirmwareUserRequest = 9;
 * @return {?proto.GetLatestFirmwareUserRequest}
 */
proto.StationUserMessage.prototype.getGetlatestfirmwareuserrequest = function() {
  return /** @type{?proto.GetLatestFirmwareUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetLatestFirmwareUserRequest, 9));
};


/**
 * @param {?proto.GetLatestFirmwareUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetlatestfirmwareuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetlatestfirmwareuserrequest = function() {
  return this.setGetlatestfirmwareuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetlatestfirmwareuserrequest = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional GetLatestFirmwareUserResponse getLatestFirmwareUserResponse = 10;
 * @return {?proto.GetLatestFirmwareUserResponse}
 */
proto.StationUserMessage.prototype.getGetlatestfirmwareuserresponse = function() {
  return /** @type{?proto.GetLatestFirmwareUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetLatestFirmwareUserResponse, 10));
};


/**
 * @param {?proto.GetLatestFirmwareUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetlatestfirmwareuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetlatestfirmwareuserresponse = function() {
  return this.setGetlatestfirmwareuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetlatestfirmwareuserresponse = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional GetLogFileUserRequest getLogFileUserRequest = 11;
 * @return {?proto.GetLogFileUserRequest}
 */
proto.StationUserMessage.prototype.getGetlogfileuserrequest = function() {
  return /** @type{?proto.GetLogFileUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetLogFileUserRequest, 11));
};


/**
 * @param {?proto.GetLogFileUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetlogfileuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetlogfileuserrequest = function() {
  return this.setGetlogfileuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetlogfileuserrequest = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional GetLogFileUserResponse getLogFileUserResponse = 12;
 * @return {?proto.GetLogFileUserResponse}
 */
proto.StationUserMessage.prototype.getGetlogfileuserresponse = function() {
  return /** @type{?proto.GetLogFileUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetLogFileUserResponse, 12));
};


/**
 * @param {?proto.GetLogFileUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetlogfileuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetlogfileuserresponse = function() {
  return this.setGetlogfileuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetlogfileuserresponse = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional DeleteStationUserRequest deleteStationUserRequest = 13;
 * @return {?proto.DeleteStationUserRequest}
 */
proto.StationUserMessage.prototype.getDeletestationuserrequest = function() {
  return /** @type{?proto.DeleteStationUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeleteStationUserRequest, 13));
};


/**
 * @param {?proto.DeleteStationUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setDeletestationuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearDeletestationuserrequest = function() {
  return this.setDeletestationuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasDeletestationuserrequest = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional DeleteStationUserResponse deleteStationUserResponse = 14;
 * @return {?proto.DeleteStationUserResponse}
 */
proto.StationUserMessage.prototype.getDeletestationuserresponse = function() {
  return /** @type{?proto.DeleteStationUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeleteStationUserResponse, 14));
};


/**
 * @param {?proto.DeleteStationUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setDeletestationuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearDeletestationuserresponse = function() {
  return this.setDeletestationuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasDeletestationuserresponse = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional EditNumberCardUserRequest editNumberCardUserRequest = 15;
 * @return {?proto.EditNumberCardUserRequest}
 */
proto.StationUserMessage.prototype.getEditnumbercarduserrequest = function() {
  return /** @type{?proto.EditNumberCardUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.EditNumberCardUserRequest, 15));
};


/**
 * @param {?proto.EditNumberCardUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setEditnumbercarduserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearEditnumbercarduserrequest = function() {
  return this.setEditnumbercarduserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasEditnumbercarduserrequest = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional EditNumberCardUserResponse editNumberCardUserResponse = 16;
 * @return {?proto.EditNumberCardUserResponse}
 */
proto.StationUserMessage.prototype.getEditnumbercarduserresponse = function() {
  return /** @type{?proto.EditNumberCardUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.EditNumberCardUserResponse, 16));
};


/**
 * @param {?proto.EditNumberCardUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setEditnumbercarduserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearEditnumbercarduserresponse = function() {
  return this.setEditnumbercarduserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasEditnumbercarduserresponse = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional UpdateErrorHandlingUserRequest updateErrorHandlingUserRequest = 17;
 * @return {?proto.UpdateErrorHandlingUserRequest}
 */
proto.StationUserMessage.prototype.getUpdateerrorhandlinguserrequest = function() {
  return /** @type{?proto.UpdateErrorHandlingUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.UpdateErrorHandlingUserRequest, 17));
};


/**
 * @param {?proto.UpdateErrorHandlingUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setUpdateerrorhandlinguserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearUpdateerrorhandlinguserrequest = function() {
  return this.setUpdateerrorhandlinguserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasUpdateerrorhandlinguserrequest = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional UpdateDiaryLogUserRequest updateDiaryLogUserRequest = 18;
 * @return {?proto.UpdateDiaryLogUserRequest}
 */
proto.StationUserMessage.prototype.getUpdatediaryloguserrequest = function() {
  return /** @type{?proto.UpdateDiaryLogUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.UpdateDiaryLogUserRequest, 18));
};


/**
 * @param {?proto.UpdateDiaryLogUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setUpdatediaryloguserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearUpdatediaryloguserrequest = function() {
  return this.setUpdatediaryloguserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasUpdatediaryloguserrequest = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional UpdateDiaryLogUserResponse updateDiaryLogUserResponse = 19;
 * @return {?proto.UpdateDiaryLogUserResponse}
 */
proto.StationUserMessage.prototype.getUpdatediaryloguserresponse = function() {
  return /** @type{?proto.UpdateDiaryLogUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.UpdateDiaryLogUserResponse, 19));
};


/**
 * @param {?proto.UpdateDiaryLogUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setUpdatediaryloguserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearUpdatediaryloguserresponse = function() {
  return this.setUpdatediaryloguserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasUpdatediaryloguserresponse = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional InsertAccessoryUserRequest insertAccessoryUserRequest = 20;
 * @return {?proto.InsertAccessoryUserRequest}
 */
proto.StationUserMessage.prototype.getInsertaccessoryuserrequest = function() {
  return /** @type{?proto.InsertAccessoryUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.InsertAccessoryUserRequest, 20));
};


/**
 * @param {?proto.InsertAccessoryUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setInsertaccessoryuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearInsertaccessoryuserrequest = function() {
  return this.setInsertaccessoryuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasInsertaccessoryuserrequest = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional InsertAccessoryUserResponse insertAccessoryUserResponse = 21;
 * @return {?proto.InsertAccessoryUserResponse}
 */
proto.StationUserMessage.prototype.getInsertaccessoryuserresponse = function() {
  return /** @type{?proto.InsertAccessoryUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.InsertAccessoryUserResponse, 21));
};


/**
 * @param {?proto.InsertAccessoryUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setInsertaccessoryuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearInsertaccessoryuserresponse = function() {
  return this.setInsertaccessoryuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasInsertaccessoryuserresponse = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional GetAccessoriesUserRequest getAccessoriesUserRequest = 22;
 * @return {?proto.GetAccessoriesUserRequest}
 */
proto.StationUserMessage.prototype.getGetaccessoriesuserrequest = function() {
  return /** @type{?proto.GetAccessoriesUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetAccessoriesUserRequest, 22));
};


/**
 * @param {?proto.GetAccessoriesUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetaccessoriesuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetaccessoriesuserrequest = function() {
  return this.setGetaccessoriesuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetaccessoriesuserrequest = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional GetAccessoriesUserResponse getAccessoriesUserResponse = 23;
 * @return {?proto.GetAccessoriesUserResponse}
 */
proto.StationUserMessage.prototype.getGetaccessoriesuserresponse = function() {
  return /** @type{?proto.GetAccessoriesUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetAccessoriesUserResponse, 23));
};


/**
 * @param {?proto.GetAccessoriesUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetaccessoriesuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetaccessoriesuserresponse = function() {
  return this.setGetaccessoriesuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetaccessoriesuserresponse = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional DeleteAccessoryUserRequest deleteAccessoryUserRequest = 24;
 * @return {?proto.DeleteAccessoryUserRequest}
 */
proto.StationUserMessage.prototype.getDeleteaccessoryuserrequest = function() {
  return /** @type{?proto.DeleteAccessoryUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeleteAccessoryUserRequest, 24));
};


/**
 * @param {?proto.DeleteAccessoryUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setDeleteaccessoryuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearDeleteaccessoryuserrequest = function() {
  return this.setDeleteaccessoryuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasDeleteaccessoryuserrequest = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional DeleteAccessoryUserResponse deleteAccessoryUserResponse = 25;
 * @return {?proto.DeleteAccessoryUserResponse}
 */
proto.StationUserMessage.prototype.getDeleteaccessoryuserresponse = function() {
  return /** @type{?proto.DeleteAccessoryUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeleteAccessoryUserResponse, 25));
};


/**
 * @param {?proto.DeleteAccessoryUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setDeleteaccessoryuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearDeleteaccessoryuserresponse = function() {
  return this.setDeleteaccessoryuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasDeleteaccessoryuserresponse = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional EditAccessoryUserRequest editAccessoryUserRequest = 26;
 * @return {?proto.EditAccessoryUserRequest}
 */
proto.StationUserMessage.prototype.getEditaccessoryuserrequest = function() {
  return /** @type{?proto.EditAccessoryUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.EditAccessoryUserRequest, 26));
};


/**
 * @param {?proto.EditAccessoryUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setEditaccessoryuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 26, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearEditaccessoryuserrequest = function() {
  return this.setEditaccessoryuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasEditaccessoryuserrequest = function() {
  return jspb.Message.getField(this, 26) != null;
};


/**
 * optional EditAccessoryUserResponse editAccessoryUserResponse = 27;
 * @return {?proto.EditAccessoryUserResponse}
 */
proto.StationUserMessage.prototype.getEditaccessoryuserresponse = function() {
  return /** @type{?proto.EditAccessoryUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.EditAccessoryUserResponse, 27));
};


/**
 * @param {?proto.EditAccessoryUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setEditaccessoryuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 27, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearEditaccessoryuserresponse = function() {
  return this.setEditaccessoryuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasEditaccessoryuserresponse = function() {
  return jspb.Message.getField(this, 27) != null;
};


/**
 * optional UpdateScheduleUserRequest updateScheduleUserRequest = 28;
 * @return {?proto.UpdateScheduleUserRequest}
 */
proto.StationUserMessage.prototype.getUpdatescheduleuserrequest = function() {
  return /** @type{?proto.UpdateScheduleUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.UpdateScheduleUserRequest, 28));
};


/**
 * @param {?proto.UpdateScheduleUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setUpdatescheduleuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 28, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearUpdatescheduleuserrequest = function() {
  return this.setUpdatescheduleuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasUpdatescheduleuserrequest = function() {
  return jspb.Message.getField(this, 28) != null;
};


/**
 * optional UpdateScheduleUserResponse updateScheduleUserResponse = 29;
 * @return {?proto.UpdateScheduleUserResponse}
 */
proto.StationUserMessage.prototype.getUpdatescheduleuserresponse = function() {
  return /** @type{?proto.UpdateScheduleUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.UpdateScheduleUserResponse, 29));
};


/**
 * @param {?proto.UpdateScheduleUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setUpdatescheduleuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearUpdatescheduleuserresponse = function() {
  return this.setUpdatescheduleuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasUpdatescheduleuserresponse = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional GetScheduleUserRequest getScheduleUserRequest = 30;
 * @return {?proto.GetScheduleUserRequest}
 */
proto.StationUserMessage.prototype.getGetscheduleuserrequest = function() {
  return /** @type{?proto.GetScheduleUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetScheduleUserRequest, 30));
};


/**
 * @param {?proto.GetScheduleUserRequest|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetscheduleuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetscheduleuserrequest = function() {
  return this.setGetscheduleuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetscheduleuserrequest = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional GetScheduleUserResponse getScheduleUserResponse = 31;
 * @return {?proto.GetScheduleUserResponse}
 */
proto.StationUserMessage.prototype.getGetscheduleuserresponse = function() {
  return /** @type{?proto.GetScheduleUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetScheduleUserResponse, 31));
};


/**
 * @param {?proto.GetScheduleUserResponse|undefined} value
 * @return {!proto.StationUserMessage} returns this
*/
proto.StationUserMessage.prototype.setGetscheduleuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StationUserMessage} returns this
 */
proto.StationUserMessage.prototype.clearGetscheduleuserresponse = function() {
  return this.setGetscheduleuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StationUserMessage.prototype.hasGetscheduleuserresponse = function() {
  return jspb.Message.getField(this, 31) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAccountsUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAccountsUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAccountsUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccountsUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    projectid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAccountsUserRequest}
 */
proto.GetAccountsUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAccountsUserRequest;
  return proto.GetAccountsUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAccountsUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAccountsUserRequest}
 */
proto.GetAccountsUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProjectid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAccountsUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAccountsUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAccountsUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccountsUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProjectid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string projectId = 1;
 * @return {string}
 */
proto.GetAccountsUserRequest.prototype.getProjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetAccountsUserRequest} returns this
 */
proto.GetAccountsUserRequest.prototype.setProjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GetAccountsUserResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetAccountsUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetAccountsUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetAccountsUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccountsUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    common_proto_pb.Account.toObject, includeInstance),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetAccountsUserResponse}
 */
proto.GetAccountsUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetAccountsUserResponse;
  return proto.GetAccountsUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetAccountsUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetAccountsUserResponse}
 */
proto.GetAccountsUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_proto_pb.Account;
      reader.readMessage(value,common_proto_pb.Account.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetAccountsUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetAccountsUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetAccountsUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetAccountsUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_proto_pb.Account.serializeBinaryToWriter
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Account accounts = 1;
 * @return {!Array<!proto.Account>}
 */
proto.GetAccountsUserResponse.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.Account>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.Account, 1));
};


/**
 * @param {!Array<!proto.Account>} value
 * @return {!proto.GetAccountsUserResponse} returns this
*/
proto.GetAccountsUserResponse.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Account=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Account}
 */
proto.GetAccountsUserResponse.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Account, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GetAccountsUserResponse} returns this
 */
proto.GetAccountsUserResponse.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetAccountsUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetAccountsUserResponse} returns this
*/
proto.GetAccountsUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetAccountsUserResponse} returns this
 */
proto.GetAccountsUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetAccountsUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteAccountUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteAccountUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteAccountUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteAccountUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteAccountUserRequest}
 */
proto.DeleteAccountUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteAccountUserRequest;
  return proto.DeleteAccountUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteAccountUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteAccountUserRequest}
 */
proto.DeleteAccountUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteAccountUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteAccountUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteAccountUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteAccountUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string userId = 1;
 * @return {string}
 */
proto.DeleteAccountUserRequest.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeleteAccountUserRequest} returns this
 */
proto.DeleteAccountUserRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeleteAccountUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeleteAccountUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeleteAccountUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteAccountUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeleteAccountUserResponse}
 */
proto.DeleteAccountUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeleteAccountUserResponse;
  return proto.DeleteAccountUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeleteAccountUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeleteAccountUserResponse}
 */
proto.DeleteAccountUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeleteAccountUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeleteAccountUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeleteAccountUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeleteAccountUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeleteAccountUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeleteAccountUserResponse} returns this
*/
proto.DeleteAccountUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeleteAccountUserResponse} returns this
 */
proto.DeleteAccountUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeleteAccountUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateNewAccountUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateNewAccountUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateNewAccountUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateNewAccountUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateNewAccountUserRequest}
 */
proto.CreateNewAccountUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateNewAccountUserRequest;
  return proto.CreateNewAccountUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateNewAccountUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateNewAccountUserRequest}
 */
proto.CreateNewAccountUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateNewAccountUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateNewAccountUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateNewAccountUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateNewAccountUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string userName = 1;
 * @return {string}
 */
proto.CreateNewAccountUserRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateNewAccountUserRequest} returns this
 */
proto.CreateNewAccountUserRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string password = 2;
 * @return {string}
 */
proto.CreateNewAccountUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.CreateNewAccountUserRequest} returns this
 */
proto.CreateNewAccountUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 role = 3;
 * @return {number}
 */
proto.CreateNewAccountUserRequest.prototype.getRole = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.CreateNewAccountUserRequest} returns this
 */
proto.CreateNewAccountUserRequest.prototype.setRole = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.CreateNewAccountUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.CreateNewAccountUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.CreateNewAccountUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateNewAccountUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.CreateNewAccountUserResponse}
 */
proto.CreateNewAccountUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.CreateNewAccountUserResponse;
  return proto.CreateNewAccountUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.CreateNewAccountUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.CreateNewAccountUserResponse}
 */
proto.CreateNewAccountUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.CreateNewAccountUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.CreateNewAccountUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.CreateNewAccountUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.CreateNewAccountUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.CreateNewAccountUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.CreateNewAccountUserResponse} returns this
*/
proto.CreateNewAccountUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.CreateNewAccountUserResponse} returns this
 */
proto.CreateNewAccountUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.CreateNewAccountUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangePasswordUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangePasswordUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangePasswordUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangePasswordUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    password: jspb.Message.getFieldWithDefault(msg, 1, ""),
    newpassword: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangePasswordUserRequest}
 */
proto.ChangePasswordUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangePasswordUserRequest;
  return proto.ChangePasswordUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangePasswordUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangePasswordUserRequest}
 */
proto.ChangePasswordUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewpassword(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangePasswordUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangePasswordUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangePasswordUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangePasswordUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNewpassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string password = 1;
 * @return {string}
 */
proto.ChangePasswordUserRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangePasswordUserRequest} returns this
 */
proto.ChangePasswordUserRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string newPassword = 2;
 * @return {string}
 */
proto.ChangePasswordUserRequest.prototype.getNewpassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ChangePasswordUserRequest} returns this
 */
proto.ChangePasswordUserRequest.prototype.setNewpassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ChangePasswordUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ChangePasswordUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ChangePasswordUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangePasswordUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ChangePasswordUserResponse}
 */
proto.ChangePasswordUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ChangePasswordUserResponse;
  return proto.ChangePasswordUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ChangePasswordUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ChangePasswordUserResponse}
 */
proto.ChangePasswordUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ChangePasswordUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ChangePasswordUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ChangePasswordUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ChangePasswordUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.ChangePasswordUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.ChangePasswordUserResponse} returns this
*/
proto.ChangePasswordUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ChangePasswordUserResponse} returns this
 */
proto.ChangePasswordUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ChangePasswordUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateAlertInfoUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateAlertInfoUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateAlertInfoUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAlertInfoUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateAlertInfoUserRequest}
 */
proto.UpdateAlertInfoUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateAlertInfoUserRequest;
  return proto.UpdateAlertInfoUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateAlertInfoUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateAlertInfoUserRequest}
 */
proto.UpdateAlertInfoUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateAlertInfoUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateAlertInfoUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateAlertInfoUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAlertInfoUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.UpdateAlertInfoUserRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateAlertInfoUserRequest} returns this
 */
proto.UpdateAlertInfoUserRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.UpdateAlertInfoUserRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateAlertInfoUserRequest} returns this
 */
proto.UpdateAlertInfoUserRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateAlertInfoUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateAlertInfoUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateAlertInfoUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAlertInfoUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateAlertInfoUserResponse}
 */
proto.UpdateAlertInfoUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateAlertInfoUserResponse;
  return proto.UpdateAlertInfoUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateAlertInfoUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateAlertInfoUserResponse}
 */
proto.UpdateAlertInfoUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateAlertInfoUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateAlertInfoUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateAlertInfoUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateAlertInfoUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.UpdateAlertInfoUserResponse.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateAlertInfoUserResponse} returns this
 */
proto.UpdateAlertInfoUserResponse.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.UpdateAlertInfoUserResponse.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateAlertInfoUserResponse} returns this
 */
proto.UpdateAlertInfoUserResponse.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.UpdateAlertInfoUserResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.UpdateAlertInfoUserResponse} returns this
*/
proto.UpdateAlertInfoUserResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateAlertInfoUserResponse} returns this
 */
proto.UpdateAlertInfoUserResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateAlertInfoUserResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AccountUserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.AccountUserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AccountUserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AccountUserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    getaccountsuserrequest: (f = msg.getGetaccountsuserrequest()) && proto.GetAccountsUserRequest.toObject(includeInstance, f),
    getaccountsuserresponse: (f = msg.getGetaccountsuserresponse()) && proto.GetAccountsUserResponse.toObject(includeInstance, f),
    deleteaccountuserrequest: (f = msg.getDeleteaccountuserrequest()) && proto.DeleteAccountUserRequest.toObject(includeInstance, f),
    deleteaccountuserresponse: (f = msg.getDeleteaccountuserresponse()) && proto.DeleteAccountUserResponse.toObject(includeInstance, f),
    createnewaccountuserrequest: (f = msg.getCreatenewaccountuserrequest()) && proto.CreateNewAccountUserRequest.toObject(includeInstance, f),
    createnewaccountuserresponse: (f = msg.getCreatenewaccountuserresponse()) && proto.CreateNewAccountUserResponse.toObject(includeInstance, f),
    changepassworduserrequest: (f = msg.getChangepassworduserrequest()) && proto.ChangePasswordUserRequest.toObject(includeInstance, f),
    changepassworduserresponse: (f = msg.getChangepassworduserresponse()) && proto.ChangePasswordUserResponse.toObject(includeInstance, f),
    updatealertinfouserrequest: (f = msg.getUpdatealertinfouserrequest()) && proto.UpdateAlertInfoUserRequest.toObject(includeInstance, f),
    updatealertinfouserresponse: (f = msg.getUpdatealertinfouserresponse()) && proto.UpdateAlertInfoUserResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AccountUserMessage}
 */
proto.AccountUserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AccountUserMessage;
  return proto.AccountUserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AccountUserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AccountUserMessage}
 */
proto.AccountUserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GetAccountsUserRequest;
      reader.readMessage(value,proto.GetAccountsUserRequest.deserializeBinaryFromReader);
      msg.setGetaccountsuserrequest(value);
      break;
    case 2:
      var value = new proto.GetAccountsUserResponse;
      reader.readMessage(value,proto.GetAccountsUserResponse.deserializeBinaryFromReader);
      msg.setGetaccountsuserresponse(value);
      break;
    case 3:
      var value = new proto.DeleteAccountUserRequest;
      reader.readMessage(value,proto.DeleteAccountUserRequest.deserializeBinaryFromReader);
      msg.setDeleteaccountuserrequest(value);
      break;
    case 4:
      var value = new proto.DeleteAccountUserResponse;
      reader.readMessage(value,proto.DeleteAccountUserResponse.deserializeBinaryFromReader);
      msg.setDeleteaccountuserresponse(value);
      break;
    case 5:
      var value = new proto.CreateNewAccountUserRequest;
      reader.readMessage(value,proto.CreateNewAccountUserRequest.deserializeBinaryFromReader);
      msg.setCreatenewaccountuserrequest(value);
      break;
    case 6:
      var value = new proto.CreateNewAccountUserResponse;
      reader.readMessage(value,proto.CreateNewAccountUserResponse.deserializeBinaryFromReader);
      msg.setCreatenewaccountuserresponse(value);
      break;
    case 7:
      var value = new proto.ChangePasswordUserRequest;
      reader.readMessage(value,proto.ChangePasswordUserRequest.deserializeBinaryFromReader);
      msg.setChangepassworduserrequest(value);
      break;
    case 8:
      var value = new proto.ChangePasswordUserResponse;
      reader.readMessage(value,proto.ChangePasswordUserResponse.deserializeBinaryFromReader);
      msg.setChangepassworduserresponse(value);
      break;
    case 9:
      var value = new proto.UpdateAlertInfoUserRequest;
      reader.readMessage(value,proto.UpdateAlertInfoUserRequest.deserializeBinaryFromReader);
      msg.setUpdatealertinfouserrequest(value);
      break;
    case 10:
      var value = new proto.UpdateAlertInfoUserResponse;
      reader.readMessage(value,proto.UpdateAlertInfoUserResponse.deserializeBinaryFromReader);
      msg.setUpdatealertinfouserresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AccountUserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AccountUserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AccountUserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AccountUserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetaccountsuserrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.GetAccountsUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetaccountsuserresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.GetAccountsUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeleteaccountuserrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.DeleteAccountUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeleteaccountuserresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.DeleteAccountUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getCreatenewaccountuserrequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.CreateNewAccountUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getCreatenewaccountuserresponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.CreateNewAccountUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getChangepassworduserrequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.ChangePasswordUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getChangepassworduserresponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.ChangePasswordUserResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatealertinfouserrequest();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.UpdateAlertInfoUserRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatealertinfouserresponse();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.UpdateAlertInfoUserResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional GetAccountsUserRequest getAccountsUserRequest = 1;
 * @return {?proto.GetAccountsUserRequest}
 */
proto.AccountUserMessage.prototype.getGetaccountsuserrequest = function() {
  return /** @type{?proto.GetAccountsUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetAccountsUserRequest, 1));
};


/**
 * @param {?proto.GetAccountsUserRequest|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setGetaccountsuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearGetaccountsuserrequest = function() {
  return this.setGetaccountsuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasGetaccountsuserrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GetAccountsUserResponse getAccountsUserResponse = 2;
 * @return {?proto.GetAccountsUserResponse}
 */
proto.AccountUserMessage.prototype.getGetaccountsuserresponse = function() {
  return /** @type{?proto.GetAccountsUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetAccountsUserResponse, 2));
};


/**
 * @param {?proto.GetAccountsUserResponse|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setGetaccountsuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearGetaccountsuserresponse = function() {
  return this.setGetaccountsuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasGetaccountsuserresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeleteAccountUserRequest deleteAccountUserRequest = 3;
 * @return {?proto.DeleteAccountUserRequest}
 */
proto.AccountUserMessage.prototype.getDeleteaccountuserrequest = function() {
  return /** @type{?proto.DeleteAccountUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeleteAccountUserRequest, 3));
};


/**
 * @param {?proto.DeleteAccountUserRequest|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setDeleteaccountuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearDeleteaccountuserrequest = function() {
  return this.setDeleteaccountuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasDeleteaccountuserrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DeleteAccountUserResponse deleteAccountUserResponse = 4;
 * @return {?proto.DeleteAccountUserResponse}
 */
proto.AccountUserMessage.prototype.getDeleteaccountuserresponse = function() {
  return /** @type{?proto.DeleteAccountUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeleteAccountUserResponse, 4));
};


/**
 * @param {?proto.DeleteAccountUserResponse|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setDeleteaccountuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearDeleteaccountuserresponse = function() {
  return this.setDeleteaccountuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasDeleteaccountuserresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional CreateNewAccountUserRequest createNewAccountUserRequest = 5;
 * @return {?proto.CreateNewAccountUserRequest}
 */
proto.AccountUserMessage.prototype.getCreatenewaccountuserrequest = function() {
  return /** @type{?proto.CreateNewAccountUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.CreateNewAccountUserRequest, 5));
};


/**
 * @param {?proto.CreateNewAccountUserRequest|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setCreatenewaccountuserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearCreatenewaccountuserrequest = function() {
  return this.setCreatenewaccountuserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasCreatenewaccountuserrequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional CreateNewAccountUserResponse createNewAccountUserResponse = 6;
 * @return {?proto.CreateNewAccountUserResponse}
 */
proto.AccountUserMessage.prototype.getCreatenewaccountuserresponse = function() {
  return /** @type{?proto.CreateNewAccountUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.CreateNewAccountUserResponse, 6));
};


/**
 * @param {?proto.CreateNewAccountUserResponse|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setCreatenewaccountuserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearCreatenewaccountuserresponse = function() {
  return this.setCreatenewaccountuserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasCreatenewaccountuserresponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional ChangePasswordUserRequest changePasswordUserRequest = 7;
 * @return {?proto.ChangePasswordUserRequest}
 */
proto.AccountUserMessage.prototype.getChangepassworduserrequest = function() {
  return /** @type{?proto.ChangePasswordUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.ChangePasswordUserRequest, 7));
};


/**
 * @param {?proto.ChangePasswordUserRequest|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setChangepassworduserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearChangepassworduserrequest = function() {
  return this.setChangepassworduserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasChangepassworduserrequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional ChangePasswordUserResponse changePasswordUserResponse = 8;
 * @return {?proto.ChangePasswordUserResponse}
 */
proto.AccountUserMessage.prototype.getChangepassworduserresponse = function() {
  return /** @type{?proto.ChangePasswordUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.ChangePasswordUserResponse, 8));
};


/**
 * @param {?proto.ChangePasswordUserResponse|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setChangepassworduserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearChangepassworduserresponse = function() {
  return this.setChangepassworduserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasChangepassworduserresponse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UpdateAlertInfoUserRequest updateAlertInfoUserRequest = 9;
 * @return {?proto.UpdateAlertInfoUserRequest}
 */
proto.AccountUserMessage.prototype.getUpdatealertinfouserrequest = function() {
  return /** @type{?proto.UpdateAlertInfoUserRequest} */ (
    jspb.Message.getWrapperField(this, proto.UpdateAlertInfoUserRequest, 9));
};


/**
 * @param {?proto.UpdateAlertInfoUserRequest|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setUpdatealertinfouserrequest = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearUpdatealertinfouserrequest = function() {
  return this.setUpdatealertinfouserrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasUpdatealertinfouserrequest = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UpdateAlertInfoUserResponse updateAlertInfoUserResponse = 10;
 * @return {?proto.UpdateAlertInfoUserResponse}
 */
proto.AccountUserMessage.prototype.getUpdatealertinfouserresponse = function() {
  return /** @type{?proto.UpdateAlertInfoUserResponse} */ (
    jspb.Message.getWrapperField(this, proto.UpdateAlertInfoUserResponse, 10));
};


/**
 * @param {?proto.UpdateAlertInfoUserResponse|undefined} value
 * @return {!proto.AccountUserMessage} returns this
*/
proto.AccountUserMessage.prototype.setUpdatealertinfouserresponse = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.AccountUserMessage} returns this
 */
proto.AccountUserMessage.prototype.clearUpdatealertinfouserresponse = function() {
  return this.setUpdatealertinfouserresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.AccountUserMessage.prototype.hasUpdatealertinfouserresponse = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UserMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    authusermessage: (f = msg.getAuthusermessage()) && proto.AuthUserMessage.toObject(includeInstance, f),
    deviceusermessage: (f = msg.getDeviceusermessage()) && proto.DeviceUserMessage.toObject(includeInstance, f),
    deviceonchangeusermessage: (f = msg.getDeviceonchangeusermessage()) && proto.DeviceOnchangeUserMessage.toObject(includeInstance, f),
    stationusermessage: (f = msg.getStationusermessage()) && proto.StationUserMessage.toObject(includeInstance, f),
    accountusermessage: (f = msg.getAccountusermessage()) && proto.AccountUserMessage.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserMessage}
 */
proto.UserMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserMessage;
  return proto.UserMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserMessage}
 */
proto.UserMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.AuthUserMessage;
      reader.readMessage(value,proto.AuthUserMessage.deserializeBinaryFromReader);
      msg.setAuthusermessage(value);
      break;
    case 2:
      var value = new proto.DeviceUserMessage;
      reader.readMessage(value,proto.DeviceUserMessage.deserializeBinaryFromReader);
      msg.setDeviceusermessage(value);
      break;
    case 3:
      var value = new proto.DeviceOnchangeUserMessage;
      reader.readMessage(value,proto.DeviceOnchangeUserMessage.deserializeBinaryFromReader);
      msg.setDeviceonchangeusermessage(value);
      break;
    case 4:
      var value = new proto.StationUserMessage;
      reader.readMessage(value,proto.StationUserMessage.deserializeBinaryFromReader);
      msg.setStationusermessage(value);
      break;
    case 5:
      var value = new proto.AccountUserMessage;
      reader.readMessage(value,proto.AccountUserMessage.deserializeBinaryFromReader);
      msg.setAccountusermessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthusermessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.AuthUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getDeviceusermessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeviceUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getDeviceonchangeusermessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.DeviceOnchangeUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getStationusermessage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.StationUserMessage.serializeBinaryToWriter
    );
  }
  f = message.getAccountusermessage();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.AccountUserMessage.serializeBinaryToWriter
    );
  }
};


/**
 * optional AuthUserMessage authUserMessage = 1;
 * @return {?proto.AuthUserMessage}
 */
proto.UserMessage.prototype.getAuthusermessage = function() {
  return /** @type{?proto.AuthUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.AuthUserMessage, 1));
};


/**
 * @param {?proto.AuthUserMessage|undefined} value
 * @return {!proto.UserMessage} returns this
*/
proto.UserMessage.prototype.setAuthusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserMessage} returns this
 */
proto.UserMessage.prototype.clearAuthusermessage = function() {
  return this.setAuthusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserMessage.prototype.hasAuthusermessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceUserMessage deviceUserMessage = 2;
 * @return {?proto.DeviceUserMessage}
 */
proto.UserMessage.prototype.getDeviceusermessage = function() {
  return /** @type{?proto.DeviceUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUserMessage, 2));
};


/**
 * @param {?proto.DeviceUserMessage|undefined} value
 * @return {!proto.UserMessage} returns this
*/
proto.UserMessage.prototype.setDeviceusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserMessage} returns this
 */
proto.UserMessage.prototype.clearDeviceusermessage = function() {
  return this.setDeviceusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserMessage.prototype.hasDeviceusermessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeviceOnchangeUserMessage deviceOnchangeUserMessage = 3;
 * @return {?proto.DeviceOnchangeUserMessage}
 */
proto.UserMessage.prototype.getDeviceonchangeusermessage = function() {
  return /** @type{?proto.DeviceOnchangeUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.DeviceOnchangeUserMessage, 3));
};


/**
 * @param {?proto.DeviceOnchangeUserMessage|undefined} value
 * @return {!proto.UserMessage} returns this
*/
proto.UserMessage.prototype.setDeviceonchangeusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserMessage} returns this
 */
proto.UserMessage.prototype.clearDeviceonchangeusermessage = function() {
  return this.setDeviceonchangeusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserMessage.prototype.hasDeviceonchangeusermessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional StationUserMessage stationUserMessage = 4;
 * @return {?proto.StationUserMessage}
 */
proto.UserMessage.prototype.getStationusermessage = function() {
  return /** @type{?proto.StationUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.StationUserMessage, 4));
};


/**
 * @param {?proto.StationUserMessage|undefined} value
 * @return {!proto.UserMessage} returns this
*/
proto.UserMessage.prototype.setStationusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserMessage} returns this
 */
proto.UserMessage.prototype.clearStationusermessage = function() {
  return this.setStationusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserMessage.prototype.hasStationusermessage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional AccountUserMessage accountUserMessage = 5;
 * @return {?proto.AccountUserMessage}
 */
proto.UserMessage.prototype.getAccountusermessage = function() {
  return /** @type{?proto.AccountUserMessage} */ (
    jspb.Message.getWrapperField(this, proto.AccountUserMessage, 5));
};


/**
 * @param {?proto.AccountUserMessage|undefined} value
 * @return {!proto.UserMessage} returns this
*/
proto.UserMessage.prototype.setAccountusermessage = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserMessage} returns this
 */
proto.UserMessage.prototype.clearAccountusermessage = function() {
  return this.setAccountusermessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserMessage.prototype.hasAccountusermessage = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * @enum {number}
 */
proto.MeterItemType = {
  METER_ITEM_TYPE_HOUR: 0,
  METER_ITEM_TYPE_DAY: 1,
  METER_ITEM_TYPE_MONTH: 2
};

goog.object.extend(exports, proto);
