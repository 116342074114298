export const SCHEDULE_FORM = {
  dateForm: [255, 255, 255, 255, 255, 255, 255],
  timeForm: [
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '06:00',
          isEnable: false,
          target: 0
        },
        {
          start: '18:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '06:00',
          isEnable: false,
          target: 0
        },
        {
          start: '18:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '06:00',
          isEnable: false,
          target: 0
        },
        {
          start: '18:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '06:00',
          isEnable: false,
          target: 0
        },
        {
          start: '18:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    }
  ],
  signalForm: [
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
  ],
  selectedTimeForm: 0,
  selectedSignalForm: 0,
}