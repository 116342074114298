export const config = {
  version: '2.1.0',
  os: 'win', // lin/mac/win
  // HOST_WS: 'ws://192.168.5.188:8443/websocket',
  // HOST_HTTP: 'http://192.168.5.188:8443',
  // HOST_WS: 'ws://192.168.1.41:8443/websocket',
  // HOST_HTTP: 'http://192.168.1.41:8443',
  // HOST_WS: 'ws://192.168.0.103:8443/websocket',
  // HOST_HTTP: 'http://192.168.0.103:8443',
  HOST_WS: 'wss://lighting.sdkd.com.vn/websocket',
  HOST_HTTP: 'https://lighting.sdkd.com.vn',
  // HOST_WS: 'wss://dev.hue-traffic.sitech.com.vn/websocket',
  // HOST_HTTP: 'https://dev.hue-traffic.sitech.com.vn',
  MAP_KEY: 'AIzaSyC1WFY7kqYrer5FxiXyCramtJ1vKCy9Dwg'
}