import logo from './logo.svg';
import './App.css';
import AppMain from './components/AppMain'
import React from "react";
const querystring = require('querystring');

// const ipcRenderer = require('electron').ipcRenderer;
// const { ipcRenderer } = window.require('electron');
// function openWindow () {
//   window.api.send('asynchronous-message', 'createNewWindow');
// }

function App() {
  let userId = null;
  let stationId = null;
  let user = 'noname';
  let query = querystring.parse(global.location.search);
  if (query !== null && query.hasOwnProperty('?data') && query['?data'] !== undefined) {
    let data = JSON.parse(query['?data'])
    if (data !== undefined) {
      console.log("userId = %s, stationId = %s", data.userId, data.stationId);
      userId = data.userId;
      stationId = data.stationId;
      user = data.user;
    }
    // let data = JSON.parse(query['?stationId'])
    // console.log("window data " + query['?stationId']);
  }
  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    // <button onClick={() => openWindow()}> click </button>
      <AppMain
        userId={userId}
        stationId={stationId}
        user={user}
      />
  );
}

export default App;
