import React from "react";
import {Row, Container, Col, Button, ButtonGroup, ToggleButton, Alert} from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// import faker from 'faker';
import './style.css';
import '../style.css';
import {FaWalking, FaCircle, FaTrafficLight} from 'react-icons/fa';
import {BiTime, BiChip, BiTimer, BiArrowBack, BiRightArrowAlt} from 'react-icons/bi';
import {AiOutlineControl, AiTwotoneSetting, AiOutlineSchedule, AiOutlineFileExclamation, AiOutlinePoweroff, AiOutlineDownload} from 'react-icons/ai';
import {RiLayoutMasonryFill, RiCloseCircleLine} from 'react-icons/ri';
import {GiUnplugged} from 'react-icons/gi';
import {MdFlashAuto} from 'react-icons/md';
import {HiOutlineHand} from 'react-icons/hi';

import avatar from "../../resources/admin-icon.jpg";
import CityTopView from '../../resources/city-top-view.jpg';
import ListGroup from "react-bootstrap/ListGroup";
import GaugeChart from 'react-gauge-chart';
import Draggable from "react-draggable";
import DateTimePicker from "react-datetime-picker";
import {config} from "../Config";
import {save} from "save-file";
import {Schedule} from "./Schedule";

// const { ipcRenderer } = require("electron");
//
// function openWindow () {
//   console.log("to be send to ipc")
//   ipcRenderer.send('asynchronous-message', 'createNewWindow');
// }

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}

const colorGREEN = '#66FF3F';
const colorRED = '#FF0909';
const colorYELLOW = '#FFD600';

export const SIGN_SETTING_DEFAULT = [
  {
    label: {
      text: 'Tuyến Đinh Tiên Hoàng',
      x: 68,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 220,
      y: 448
    },
    countdown: {
      channel: 1,
      x: 350,
      y: 448
    },
    walking: {
      channel: 1,
      x: 295,
      y: 393
    },
    railway: {
      channel: 1,
      x: 295,
      y: 338
    },
    turnLeft: {
      channel: 1,
      x: 350,
      y: 283
    },
    turnRight: {
      channel: 1,
      x: 350,
      y: 393
    },
    goAhead: {
      channel: 1,
      x: 350,
      y: 338
    },
  },
  {
    label: {
      text: 'Tuyến 2',
      x: 475,
      y: 655,
    },
    trafficLight: {
      channel: 1,
      x: 610,
      y: 563
    },
    countdown: {
      channel: 1,
      x: 610,
      y: 508
    },
    walking: {
      channel: 1,
      x: 555,
      y: 563
    },
    railway: {
      channel: 1,
      x: 500,
      y: 563
    },
    turnLeft: {
      channel: 1,
      x: 445,
      y: 508
    },
    turnRight: {
      channel: 1,
      x: 555,
      y: 508
    },
    goAhead: {
      channel: 1,
      x: 500,
      y: 508
    },
  },
  {
    label: {
      text: 'Tuyến 3',
      x: 885,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 720,
      y: 248
    },
    countdown: {
      channel: 1,
      x: 665,
      y: 248
    },
    walking: {
      channel: 1,
      x: 720,
      y: 303
    },
    railway: {
      channel: 1,
      x: 720,
      y: 358
    },
    turnLeft: {
      channel: 1,
      x: 665,
      y: 303
    },
    turnRight: {
      channel: 1,
      x: 665,
      y: 413
    },
    goAhead: {
      channel: 1,
      x: 665,
      y: 358
    },
  },
  {
    label: {
      text: 'Tuyến 4',
      x: 485,
      y: 20,
    },
    trafficLight: {
      channel: 1,
      x: 402,
      y: 68
    },
    countdown: {
      channel: 1,
      x: 402,
      y: 193
    },
    walking: {
      channel: 1,
      x: 457,
      y: 138
    },
    railway: {
      channel: 1,
      x: 512,
      y: 138
    },
    turnLeft: {
      channel: 1,
      x: 457,
      y: 193
    },
    turnRight: {
      channel: 1,
      x: 567,
      y: 193
    },
    goAhead: {
      channel: 1,
      x: 512,
      y: 193
    },
  }
]

export const STATION_VALUE_DEFAULT = {
  isOnline: false,
  stationId: null,
  walkingMode: 0,
  trainWayMode: 1,
  activeTimeBegin: '00:00',
  activeTimeEnd: '00:00',
  activeTimeBeginE: '00:00',
  activeTimeEndE: '00:00',
  control: {
    lane: 1,
    onOff: false,
  },
  mode: 0,
  meterData: {
    i1: 0,
    i2: 0,
    i3: 0,
    u1: 0,
    u2: 0,
    u3: 0,
    p1: 0,
    p2: 0,
    p3: 0,
    a1: 0,
    a2: 0,
    a3: 0,
  },
  firmware: '1.0.0',
  stationInfo: {
    stationName: 'Ngã tư Võ Thị Sáu',
    hardwareId: 'GW-12:23:34:45',
    type: 'DC 24V',
    numPhase: 2,
    isVarient: false,
    cpuTime: '12:34',
    activeTime: '00:00 23:00',
    signSetting: SIGN_SETTING_DEFAULT,
    backgroundSrc: null,
    nCard: 2,
  },
  chartData: {
    label: [],
    value: [],
  },
  outputSignal: [0, 0, 0, 0],
  timer: {
    setting: [
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      }
    ],
      current:
      [
        {
          index: 1,
          trafficLight: 'green',
          walking: 'red',
          mappingLight: 'green',
          counter: 23,
        },
        {
          index: 2,
          trafficLight: 'red',
          walking: 'green',
          mappingLight: 'red',
          counter: 25,
        },
      ]
  },
  logMessage: "[I] Device reached stable state",
  errorStatus: 0,
  noteList: [],
  diaryText: '',
  accessories: []
};

const ACTION_MODE = {
  NONE: 0,
  MONITOR: 1,
  STATION_CONFIG: 2,
  DIARY: 3,
  SCHEDULE: 4,
}

const SIGN_TYPE = {
  NONE: 0,
  COUNTDOWN: 1,
  TRAFFIC_LIGHT: 2,
  WALKING: 3,
  RAILWAY: 4,
  TURN_LEFT: 5,
  GO_AHEAD: 6,
  TURN_RIGHT: 7,
  LABEL: 8
};

let date = new Date();
date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
let timeToSet = date.toISOString().slice(0, 10);

const DEFAULT_ACCESSORY_FORM = {
  name: '',
  model: '',
  madeBy: '',
  position: '',
  installedPosition: '',
  installedDate: timeToSet,
  state: 'Đang hoạt động',
}

export class DashboardPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleTimeString(),
      isChangeScheduleMode: false,
      scheduleChangeModel: {
        green: [
          23,
          24
        ],
        yellow: 3,
        clearance: 3,
      },
      activeTimeBegin: '00:00',
      activeTimeEnd: '00:00',
      activeTimeBeginE: '00:00',
      activeTimeEndE: '00:00',
      mode: ACTION_MODE.MONITOR,
      signSetting: SIGN_SETTING_DEFAULT,
      file: null,
      nCard: 2,
      backgroundSrc: CityTopView,
      radioValue: '1',
      updateDiaryText: '',
      isAddNewDevice: false,
      accessoryForm: {...DEFAULT_ACCESSORY_FORM}
    }
    this.data = props.value || STATION_VALUE_DEFAULT;
    this.onControlLane = props.onControlLane || null;
    this.callbackClose = props.callbackClose || null;
    this.onChangeSchedule = props.onChangeSchedule || null;
    this.onSetActiveTime = props.onSetActiveTime || null;
    this.onSyncTime = props.onSyncTime || null;
    this.submitSignSetting = props.submitSignSetting || null;
    this.changeNumberCard = props.changeNumberCard || null;
    this.updateErrorStatus = props.updateErrorStatus || null;
    this.addAccessory = props.addAccessory || null;
    this.user = props.user || "Trần Thanh Lộc";
    this.submitDiary = props.submitDiary || null;
    this.deleteAccessory = props.deleteAccessory || null;
    this.onGetSchedule = props.onGetSchedule || null;

    this.setState(state => {
      state.activeTimeBegin = this.props.value.activeTimeBegin;
      state.activeTimeEnd = this.props.value.activeTimeEnd;
      state.activeTimeBeginE = this.props.value.activeTimeBeginE;
      state.activeTimeEndE = this.props.value.activeTimeEndE;
      return state;
    });

    this.isEditTime = false;

    this.radios = [
      { name: 'Lỗi', value: 1 },
      { name: 'Đã ghi nhận', value: 2 },
      { name: 'Đã xử lý', value: 3 },
    ];

    this.onControlSubmit = this.onControlSubmit.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickChangeSchedule = this.onClickChangeSchedule.bind(this);
    this.onChangeActiveTime = this.onChangeActiveTime.bind(this);
    this.onSubmitActiveTime = this.onSubmitActiveTime.bind(this);
    this.onClickSyncTime = this.onClickSyncTime.bind(this);
    this.onChangeActionMode = this.onChangeActionMode.bind(this);
    this.onSubmitSignSetting = this.onSubmitSignSetting.bind(this);
    this.onChangeSettingChannel = this.onChangeSettingChannel.bind(this);
    this.onFileChanged = this.onFileChanged.bind(this);
    this.onChangeNumberCard = this.onChangeNumberCard.bind(this);
    this.currentIndex = 0;
    this.onSubmitChangeNumberCard = this.onSubmitChangeNumberCard.bind(this);
    this.setRadioValue = this.setRadioValue.bind(this);
    this.onSubmitDiary = this.onSubmitDiary.bind(this);
    this.onDeleteAccessory = this.onDeleteAccessory.bind(this);

    console.log("time to set " + timeToSet);

    const interval = setInterval(() => {
      this.setState((state) => {
        let time = new Date();
        state.time = time.getHours() + ":" + time.getMinutes()
        return state;
      });
    }, 60000);
  }

  componentDidMount() {
    // if (this.props.value) {
    //   this.setState(state => state.signSetting = this.props.value.stationInfo.signSetting);
    // }
    this.data = this.props.value;
    console.log("Sign setting " + this.props.value.stationInfo.signSetting);
    this.setState(state => {
      state.activeTimeBegin = this.props.value.activeTimeBegin;
      state.activeTimeEnd = this.props.value.activeTimeEnd;
      state.activeTimeBeginE = this.props.value.activeTimeBeginE;
      state.activeTimeEndE = this.props.value.activeTimeEndE;
      return state;
    });
    this.isEditTime = false;
  }

  componentWillReceiveProps(nextProps) {
    // Any time props.email changes, update state.
    // if (nextProps.value !== this.props.value) {
    //   console.log("on uopdate")
      this.setState(
        state => {
          state.signSetting = nextProps.value.stationInfo.signSetting;
          if (this.isEditTime === false &&
            (this.state.activeTimeBegin !== nextProps.value.activeTimeBegin || this.state.activeTimeEnd !== nextProps.value.activeTimeEnd)) {
            state.activeTimeBegin = nextProps.value.activeTimeBegin;
            state.activeTimeEnd = nextProps.value.activeTimeEnd;
          }
          if (this.isEditTime === false &&
            (this.state.activeTimeBeginE !== nextProps.value.activeTimeBeginE || this.state.activeTimeEndE !== nextProps.value.activeTimeEndE)) {
            state.activeTimeBeginE = nextProps.value.activeTimeBeginE;
            state.activeTimeEndE = nextProps.value.activeTimeEndE;
          }
          console.log("type of " + nextProps.value.stationInfo.backgroundSrc);
          if (nextProps.value.stationInfo.backgroundSrc !== null &&
              nextProps.value.stationInfo.backgroundSrc.length !== 0) {
            state.backgroundSrc = nextProps.value.stationInfo.backgroundSrc;
          }
          if (this.state.nCard !== nextProps.value.stationInfo.nCard && this.state.mode !== ACTION_MODE.STATION_CONFIG) {
            state.nCard = nextProps.value.stationInfo.nCard;
          }

          return state;
        }
      );
    // }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  onControlSubmit(isPowerOn, index) {
    let curIndex = this.props.value.control.lane;
    console.log("Power " + isPowerOn + " index " + index)
    if (curIndex === index && index !== 0) {
      curIndex = 9;
    } else {
      curIndex = index;
    }
    console.log("on submit " + curIndex);
    if (this.onControlLane != null) {
      this.onControlLane(isPowerOn, curIndex);
    }
  }

  onClickChangeSchedule(command) {
    switch (command) {
      case 'change':
        console.log("on change schedule");
        this.setState(state => {
          state.isChangeScheduleMode = true;
          state.scheduleChangeModel.green[0] = this.props.value.timer.setting[0].greenTime;
          state.scheduleChangeModel.green[1] = this.props.value.timer.setting[1].greenTime;
          state.scheduleChangeModel.yellow = this.props.value.timer.setting[0].yellowTime;
          state.scheduleChangeModel.clearance = this.props.value.timer.setting[0].clearanceTime;
          return state;
        });
        break;
      case 'submit':
        console.log("on submit schedule");
        this.setState(state => state.isChangeScheduleMode = false);
        if (this.onChangeSchedule) {
          this.onChangeSchedule(this.state.scheduleChangeModel.green, this.state.scheduleChangeModel.yellow, this.state.scheduleChangeModel.clearance);
        }
        break;
      case 'cancel':
        console.log("on cancel schedule");
        this.setState(state => state.isChangeScheduleMode = false);
        break;
    }
    /*if (this.onChangeSchedule != null) {
      this.onChangeSchedule();
    }*/
  }

  onClickClose() {
    // openWindow();
    if (process.env.REACT_APP_MODE !== 'web') {
      window.close();
    } else {
      if (this.callbackClose != null) {
        this.callbackClose();
      }
    }
    this.isEditTime = false;
  }

  statusItem(isConnected, walkingState, trainWayState, powerOn, mode, forceDisable = false) {
    let curStyle = 'rgba(104, 214, 36, 0.79)';
    let text = 'Đang kết nối';
    if (isConnected === false) {
      curStyle = '#EB5757';
      text = 'Mất kết nối';
    }
    return (
      <div style={{minHeight: '5.1em', margin: 'auto'}}>
        <Row style={{padding: '0.5em 2em 0.5em 2em'}}>
          <Col xs={5} style={{
            padding: '0 0.5em 0 0em',
            margin: 'auto'
          }}>
            <Button style={{
              margin: 'auto',
              padding: '1em'
            }}
              variant={powerOn === true ? 'info' : 'secondary'}
              onClick={() => this.onControlSubmit(powerOn !== true, 0)}
              // disabled={forceDisable ? false : (this.props.role !== 1 && this.props.role !== 2)}
            >
              <AiOutlinePoweroff size={25} color={'white'}/>
              <p style={{
                margin: 'auto',
                fontSize: '18px',
                textAlign: 'center'
              }}>
                On / Off
              </p>
            </Button>
          </Col>
          <Col xs={7}>
            <Row>
              <Col style={{padding: '0em 0 0.5em'}}>
                <FaCircle size={25} color={curStyle}/>
                <p style={{
                  display: 'inline-table',
                  margin: 'auto',
                  paddingLeft: '0.8em',
                  fontSize: '18px',
                  textAlign: 'right'
                }}>
                  {text}
                </p>
                {/*<p style={{textAlign: 'center', margin: 'auto', fontSize: '18px'}}>{text}</p>*/}
              </Col>
            </Row>
            <Row style={{padding: '0'}}>
              <Col style={{padding: '0 0.2em'}}>
                <div className='round-icon'>
                  <MdFlashAuto size={30} color={mode === 1 ? 'green' : 'gray'}/>
                </div>
              </Col>
              <Col style={{padding: '0 0.2em'}}>
                <div className='round-icon'>
                  <GiUnplugged size={30} color={mode === 0 ? 'green' : 'gray'}/>
                </div>
              </Col>
              <Col style={{padding: '0 0.2em'}}>
                <div className='round-icon'>
                  <HiOutlineHand size={30} color={mode === 2 ? 'green' : 'gray'}/>
                </div>
              </Col>
            </Row>
            <Row>
              <Col style={{padding: '0', margin: '0', textAlign: 'center'}}>
                <p style={{fontSize: '0.7em', padding: '0', margin: '0'}}>
                  Tự động
                </p>
              </Col>
              <Col style={{padding: '0', margin: '0', textAlign: 'center'}}>
                <p style={{fontSize: '0.7em', padding: '0', margin: '0'}}>
                  Tắt
                </p>
              </Col>
              <Col style={{padding: '0', margin: '0', textAlign: 'center'}}>
                <p style={{fontSize: '0.7em', padding: '0', margin: '0'}}>
                  Khiển tay
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    )
  }

  onChangeActiveTime(channel = 0, isBegin, time) {
    this.isEditTime = true;
    if (channel === 0) {
      if (isBegin) {
        this.setState(state => state.activeTimeBegin = time);
      } else {
        this.setState(state => state.activeTimeEnd = time);
      }
    } else {
      if (isBegin) {
        this.setState(state => state.activeTimeBeginE = time);
      } else {
        this.setState(state => state.activeTimeEndE = time);
      }
    }
  }

  onSubmitActiveTime() {
    let begin = this.state.activeTimeBegin;
    let end = this.state.activeTimeEnd;
    let beginE = this.state.activeTimeBeginE;
    let endE = this.state.activeTimeEndE;
    try {
      let beginHour = begin.split(':')[0];
      let beginMinute = begin.split(':')[1];
      let endHour = end.split(':')[0];
      let endMinute = end.split(':')[1];

      let beginHourE = beginE.split(':')[0];
      let beginMinuteE = beginE.split(':')[1];
      let endHourE = endE.split(':')[0];
      let endMinuteE = endE.split(':')[1];
      if (this.onSetActiveTime) {
        this.onSetActiveTime(beginHour, beginMinute, endHour, endMinute, beginHourE, beginMinuteE, endHourE, endMinuteE);
      }
    } catch (e) {
      console.log(e);
    }
  }


  // 'https://i.ibb.co/XCqkLPs/city-top-view.jpg'

  messageItem(message) {
    return (
      <div>
        <Row style={{padding: '0.2em', paddingRight: '1em'}}>
          <Col xs={2}>
            <p style={{textAlign: 'right', marginTop: '0.5em', fontSize: '18px'}}>
              Tin nhắn:
            </p>
          </Col>
          <Col xs={10}>
            <div className='message-box'>
              <p className='message-text'>
                {(new Date()).toLocaleString() + ": " + message}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  onClickSyncTime() {
    if (this.onSyncTime) {
      this.onSyncTime();
    }
  }

  onSubmitChangeNumberCard() {
    if (this.changeNumberCard !== null) {
      this.changeNumberCard(this.props.value.stationId, this.state.nCard);
    }
  }

  setRadioValue(value) {
    /*this.setState(state => {
      state.radioValue = value;
      return state;
    })*/
    if (this.updateErrorStatus !== null) {
      this.updateErrorStatus(this.props.value.stationId, value)
    }
  }

  monitorItem(stationInfo, chartData, meterData, firmware, noteList, data) {
    return(
      <div style={{margin: 'auto'}}>
        <Container className='monitor-station-title'>
          <p className='monitor-station-title-text'>
            {stationInfo?.stationName}
          </p>
        </Container>
        <Container className='component-mobile monitor-card'>
          {this.statusItem(
            this.props.value.isOnline,
            this.props.value.walkingMode,
            this.props.value.trainWayMode,
            this.props.value.control.onOff,
            this.props.value.mode,
            true
          )}
        </Container>
        <Container className='monitor-card'>
          <BiChip size={30}/>
          <p className='monitor-card-title'>
            Thông tin CPU
          </p>
          <Row style={{marginTop: '0.5em'}}>
            <Col className='monitor-table-left' xs={5}>
              Mã thiết bị :
            </Col>
            <Col className='monitor-table-right' xs={7}>
              {stationInfo.hardwareId}
            </Col>
          </Row>
          <Row style={{marginTop: '0.5em'}}>
            <Col className='monitor-table-left' xs={5}>
              Model :
            </Col>
            <Col className='monitor-table-right' xs={7}>
              {/*{stationInfo.type}*/}
              SDKD-MLC-ESP
            </Col>
          </Row>
          <Row style={{marginTop: '0.5em'}}>
            <Col className='monitor-table-left' xs={5}>
              Firmware :
            </Col>
            <Col className='monitor-table-right' xs={7}>
              {firmware}
            </Col>
          </Row>
          <Row style={{marginTop: '0.5em'}}>
            <Col className='monitor-table-left' xs={5}>
              Thời gian :
            </Col>
            <Col xs={3} className='monitor-table-right' xs={4}>
              {this.state.time}
            </Col>
            <Col xs={3} style={{padding: '0'}} xs={3}>
              <Button
                variant='info'
                size={'sm'}
                className='btn-sync'
                onClick={() => this.onClickSyncTime()}
                disabled={this.props.role !== 1 && this.props.role !== 2}
              >
                Đồng bộ
              </Button>
            </Col>
          </Row>
          {/*<Row style={{marginTop: '0.5em'}}>*/}
          {/*</Row>*/}
        </Container>
        <Container className='component-mobile monitor-card'>
          <Row style={{padding: '0.5em 0', margin: '0px'}}>
            <Col style={{margin: 'auto', padding: '0'}}>
              <BiTime size={25} />
              <p className='monitor-card-title'>
                Thời gian hoạt động
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              Từ: <input
              className='monitor-input-time-active'
              style={{textAlign: 'center'}}
              type="time"
              id="fname"
              name="fname"
              value={this.state.activeTimeBegin}
              onChange={(event) => this.onChangeActiveTime(true, event.target.value)}
              // disabled={true}
            />
            </Col>
            <Col xs={1}><BiRightArrowAlt size={25} /></Col>
            <Col>
              Đến: <input
              className='monitor-input-time-active'
              style={{textAlign: 'center'}}
              type="time"
              id="fname"
              name="fname"
              value={this.state.activeTimeEnd}
              onChange={(event) => this.onChangeActiveTime(false, event.target.value)}
              // disabled={true}
            />
            </Col>
          </Row>
          <Row>
            <Col>
              Từ: <input
              className='monitor-input-time-active'
              style={{textAlign: 'center'}}
              type="time"
              id="fname"
              name="fname"
              value={this.state.activeTimeBeginE}
              onChange={(event) => this.onChangeActiveTime(1, true, event.target.value)}
              // disabled={true}
            />
            </Col>
            <Col xs={1}><BiRightArrowAlt size={25} /></Col>
            <Col>
              Đến: <input
              className='monitor-input-time-active'
              style={{textAlign: 'center'}}
              type="time"
              id="fname"
              name="fname"
              value={this.state.activeTimeEndE}
              onChange={(event) => this.onChangeActiveTime(1, false, event.target.value)}
              // disabled={true}
            />
            </Col>
          </Row>
          <Row>
            <Col style={{margin: 'auto', paddingTop: '1.2em'}}>
              <Button onClick={() => this.onSubmitActiveTime()}>
                Thiết lập
              </Button>
            </Col>
          </Row>
        </Container>
        <Container className='monitor-card mobile-sign-signal'>
          <FaTrafficLight size={25} />
          <p className='monitor-card-title'>
            Biểu đồ điện năng tiêu thụ (kWh)
          </p>
          <Row style={{padding: '1em'}}>
            <Bar
              data={{
                labels: chartData?.label,
                datasets: [
                  {
                    label: "Công suất tiêu thụ",
                    backgroundColor: [
                      "#3cba9f"
                    ],
                    data: chartData?.value
                  },
                ]
              }}
              options={{
                legend: { display: false },
                title: {
                  display: true,
                  text: "Predicted world population (millions) in 2050"
                }
              }}
            />
          </Row>
        </Container>
        <Container className='monitor-card mobile-sign-signal'>
          <FaTrafficLight size={25} />
          <p className='monitor-card-title'>
            Thông số điện tại tủ
          </p>
          <Row>
            <Col>
              <p style={{margin: 'auto', fontSize: '18px'}}>Pha 1:</p>
            </Col>
          </Row>
          <Row>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.u1.toFixed(2) + ' V'}
                              percent={meterData.u1 / 440}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Hiệu điện thế
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.i1.toFixed(2) + ' A'}
                              percent={meterData.i1 / 10}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Cường độ dòng điện
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.a1.toFixed(2)}
                              percent={meterData.a1 / 2}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Cos(a)
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.p1.toFixed(2) + ' kW'}
                              percent={meterData.p1 / 5000}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Công suất
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <p style={{margin: 'auto', fontSize: '18px'}}>Pha 2:</p>
            </Col>
          </Row>
          <Row>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.u2.toFixed(2) + ' V'}
                              percent={meterData.u2 / 440}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Hiệu điện thế
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.i2.toFixed(2) + ' A'}
                              percent={meterData.i2 / 10}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Cường độ dòng điện
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.a2.toFixed(2)}
                              percent={meterData.a2 / 2}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Cos(a)
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.p2.toFixed(2) + ' kW'}
                              percent={meterData.p2 / 5000}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Công suất
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <p style={{margin: 'auto', fontSize: '18px'}}>Pha 3:</p>
            </Col>
          </Row>
          <Row>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.u3.toFixed(2) + ' V'}
                              percent={meterData.u3 / 440}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Hiệu điện thế
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.i3.toFixed(2) + ' A'}
                              percent={meterData.i3 / 10}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Cường độ dòng điện
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.a3.toFixed(2)}
                              percent={meterData.a3 / 2}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Cos(a)
                </Col>
              </Row>
            </Col>
            <Col className={'gauge-chart'}>
              <Row>
                <Col>
                  <GaugeChart id="gauge-chart4"
                              className={'gauge-style'}
                              animate={false}
                              formatTextValue={value => meterData.p3.toFixed(2) + ' kW'}
                              percent={meterData.p3 / 5000}
                  />
                </Col>
              </Row>
              <Row>
                <Col style={{margin: 'auto'}}>
                  Công suất
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className='btn-mobile'>
          <Button variant='danger' style={{width: '6em'}} onClick={() => this.onClickClose()}>
            <Row style={{margin: '0', padding: '0'}}>
              {/*<Col style={{margin: '0', padding: '0'}} xs={2}>*/}
              {/*  <RiCloseCircleLine size={20}/>*/}
              {/*</Col>*/}
              <Col style={{margin: '0', padding: '0', textAlign: 'center'}}>
                Đóng
              </Col>
            </Row>
          </Button>
        </Container>
      </div>
    )
  }

  onChangeActionMode(mode) {
    switch (mode) {
      case ACTION_MODE.NONE:
        window.api.send('asynchronous-message', 'dialog', null, null, "Tính năng chưa được hỗ trợ !");
        break;
      case ACTION_MODE.MONITOR:
        this.setState(state => state.mode = mode);
        break;
      case ACTION_MODE.STATION_CONFIG:
        if (this.props.role !== 1) {
          if (process.env.REACT_APP_MODE !== 'web') {
            window.api.send('asynchronous-message', 'dialog', null, null, "Tính năng chỉ dành cho admin !");
          } else {
            alert("Tính năng chỉ dành cho admin !");
          }
          break;
        }
        this.setState(state => state.mode = mode);
        break;
      case ACTION_MODE.DIARY:
        if (this.props.role !== 1 && this.props.role !== 2) {
          if (process.env.REACT_APP_MODE !== 'web') {
            window.api.send('asynchronous-message', 'dialog', null, null, "Truy cập bị từ chối !");
          } else {
            alert("Truy cập bị từ chối !");
          }
          break;
        }
        this.setState(state => state.mode = mode);
        this.onSubmitDiary(this.props.value.stationId, '');
        break;
      case ACTION_MODE.SCHEDULE:
        if (this.props.role !== 1 && this.props.role !== 2) {
          if (process.env.REACT_APP_MODE !== 'web') {
            window.api.send('asynchronous-message', 'dialog', null, null, "Truy cập bị từ chối !");
          } else {
            alert("Truy cập bị từ chối !");
          }
          break;
        }
        this.setState(state => state.mode = mode);
        this.onGetSchedule(this.props.value.stationId, '');
        break;
    }
  }

  navBarView() {
    return(
      <div>
        <Container className='user'>
          <Row>
            <Col xs={2}>
              <img src={avatar} className='user-icon' />
            </Col>
            <Col xs={7} style={{margin: 'auto'}}>
              <p className='user-name'>
                {this.user}
              </p>
            </Col>
          </Row>
        </Container>
        <Container style={{margin: '0', padding: '0'}}>
          <ListGroup style={{padding: '1.5em 0em'}} defaultActiveKey='#1'>
            <ListGroup.Item className='nav-item' action href='#1' onClick={() => this.onChangeActionMode(ACTION_MODE.MONITOR)}>
              <Row>
                <Col xs={3}>
                  <RiLayoutMasonryFill size={30}/>
                </Col>
                <Col xs={9} style={{textAlign: 'left', margin: '0', padding: '0'}}>
                  Giám sát
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className='nav-item' action href='#2' onClick={() => this.onChangeActionMode(ACTION_MODE.STATION_CONFIG)}>
              <Row>
                <Col xs={3}>
                  <AiTwotoneSetting size={30}/>
                </Col>
                <Col xs={9} style={{textAlign: 'left', margin: '0', padding: '0'}}>
                  Thiết lập
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className='nav-item' action href='#3' onClick={() => this.onChangeActionMode(ACTION_MODE.SCHEDULE)}>
              <Row>
                <Col xs={3}>
                  <AiOutlineSchedule size={30}/>
                </Col>
                <Col xs={9} style={{textAlign: 'left', margin: '0', padding: '0'}}>
                  Lập lịch
                </Col>
              </Row>
            </ListGroup.Item>
            <ListGroup.Item className='nav-item' action href='#4' onClick={() => this.onChangeActionMode(ACTION_MODE.DIARY)}>
              <Row>
                <Col xs={3}>
                  <AiOutlineFileExclamation size={30}/>
                </Col>
                <Col xs={9} style={{textAlign: 'left', margin: '0', padding: '0'}} >
                  Báo cáo
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Container>
        <Container style={{bottom: '2em', position: 'absolute'}}>
          <Button variant='danger' style={{width: '6em'}} onClick={() => this.onClickClose()}>
            <Row style={{margin: '0', padding: '0'}}>
              {/*<Col style={{margin: '0', padding: '0'}} xs={2}>*/}
              {/*  <RiCloseCircleLine size={20}/>*/}
              {/*</Col>*/}
              <Col style={{margin: '0', padding: '0', textAlign: 'center'}}>
                Đóng
              </Col>
            </Row>
          </Button>
        </Container>
      </div>
    )
  }

  onChangeSettingChannel(index, type, value) {
    console.log("onChangeSettingChannel " + index + " " + type + " " + value);
    if (index < 0 || index >= this.state.signSetting.length) {
      return;
    }
    this.setState(state => {
      switch (type) {
        case SIGN_TYPE.COUNTDOWN:
          state.signSetting[index].countdown.channel = value;
          break;
        case SIGN_TYPE.TRAFFIC_LIGHT:
          state.signSetting[index].trafficLight.channel = value;
          break;
        case SIGN_TYPE.WALKING:
          state.signSetting[index].walking.channel = value;
          break;
        case SIGN_TYPE.TURN_LEFT:
          state.signSetting[index].turnLeft.channel = value;
          break;
        case SIGN_TYPE.GO_AHEAD:
          state.signSetting[index].goAhead.channel = value;
          break;
        case SIGN_TYPE.TURN_RIGHT:
          state.signSetting[index].turnRight.channel = value;
          break;
        case SIGN_TYPE.RAILWAY:
          state.signSetting[index].railway.channel = value;
          break;
        case SIGN_TYPE.LABEL:
          state.signSetting[index].label.text = value;
          break;
      }
      return state;
    })
  }

  signalSettingView() {
    const model = this.state.signSetting;
    let laneList = [];
    let count = 0;
    model.forEach((item, index) => {
      laneList.push(
        <Row className='light-setting-lane-component'>
          <Col>
            {"Trụ " + (index + 1)}
          </Col>
          <Col>
            <input
              type="text"
              style={{width: '6em'}}
              disabled={this.state.signSetting[index].trafficLight.channel === 0}
              value={item.label.text}
              onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.LABEL, event.target.value))}
            />
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.trafficLight.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.TRAFFIC_LIGHT, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].trafficLight.channel === 0}
                  value={this.state.signSetting[index].trafficLight.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.TRAFFIC_LIGHT, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.walking.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.WALKING, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].walking.channel === 0}
                  value={this.state.signSetting[index].walking.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.WALKING, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.turnLeft.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.TURN_LEFT, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].turnLeft.channel === 0}
                  value={this.state.signSetting[index].turnLeft.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.TURN_LEFT, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.goAhead.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.GO_AHEAD, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].goAhead.channel === 0}
                  value={this.state.signSetting[index].goAhead.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.GO_AHEAD, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}} xs={4}>
                <input
                  type="checkbox"
                  checked={item.turnRight.channel !== 0}
                  style={{width: '15px', height: '15px', float: 'right'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.TURN_RIGHT, event.target.checked ? 1 : 0)}
                />
              </Col>
              <Col style={{padding: '0em'}} xs={8}>
                <input
                  type="number"
                  max={8}
                  min={1}
                  disabled={this.state.signSetting[index].turnRight.channel === 0}
                  value={this.state.signSetting[index].turnRight.channel}
                  onChange={(event => this.onChangeSettingChannel(index, SIGN_TYPE.TURN_RIGHT, parseInt(event.target.value)))}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col style={{padding: '0.5em'}}>
                <input
                  type="checkbox"
                  checked={item.railway.channel !== 0}
                  style={{width: '15px', height: '15px'}}
                  onChange={(event) => this.onChangeSettingChannel(index, SIGN_TYPE.RAILWAY, event.target.checked ? 1 : 0)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )
      count++;
    })
    return (
      <Container className='light-setting-label'>
        <Row className='light-setting-lane-component'>
          <Col />
          <Col>
            Nhãn
          </Col>
          <Col>
            Đèn giao thông
          </Col>
          <Col>
            Đèn đi bộ
          </Col>
          <Col>
            Đèn rẽ trái
          </Col>
          <Col>
            Đèn đi thẳng
          </Col>
          <Col>
            Đèn rẽ phải
          </Col>
          <Col>
            Tín hiệu đường sắt
          </Col>
        </Row>
        {laneList}
      </Container>
    )
  }

  onSubmitSignSetting() {
    if (this.state.file == null) {
      if (this.submitSignSetting != null) {
        this.submitSignSetting(this.props.value.stationId, this.state.signSetting, null)
      }
      return;
    }
    const formData = new FormData();

    formData.append('image', this.state.file);

    fetch(
      'https://api.imgbb.com/1/upload?key=d5b08431cefce2d6bf84b339c9ca9aa3',
      {
        method: 'POST',
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log('Success:', result);
        if (result.status === 200) {
          this.setState(state => state.backgroundSrc = result?.data?.image?.url)
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        if (this.submitSignSetting != null) {
          this.submitSignSetting(this.props.value.stationId, this.state.signSetting, this.state.backgroundSrc)
        }
      })
    ;
  }

  onFileChanged(file) {
    this.setState(state => {
      state.file = file;
      // state.backgroundSrc = file;
      return state;
    })
  }

  onChangeNumberCard(nCard) {
    this.setState(state => {
      state.nCard = nCard;
      return state;
    });
  }

  onSubmitDiary(stationId, message) {
    if (this.submitDiary !== null) {
      this.submitDiary(stationId, message);
    }
  }

  onSubmitAccessory() {
    if (this.addAccessory !== null) {
      this.addAccessory(
        this.props.value.stationId,
        this.state.accessoryForm.name,
        this.state.accessoryForm.model,
        this.state.accessoryForm.madeBy,
        this.state.accessoryForm.position,
        this.state.accessoryForm.installedPosition,
        this.state.accessoryForm.installedDate,
        this.state.accessoryForm.state
      )
    }
  }

  onDeleteAccessory(accessoryId) {
    if (this.deleteAccessory !== null) {
      this.deleteAccessory(this.props.value.stationId, accessoryId);
    }
  }

  render() {
    return (
      <Container fluid className='container-center' style={{margin: '0px'}}>
        <Row>
          <Col className='col-right'>
            <Container className='component-right'>
              {this.monitorItem(
                this.props.value.stationInfo,
                this.props.value.chartData,
                this.props.value.meterData,
                this.props.value.firmware,
                this.props.value.noteList,
                this.props.value.timer.current
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    )
  }
}