import React from "react";

import {Row, Container, Col, Button, ButtonGroup, ToggleButton, Alert} from "react-bootstrap";
import {FaCircle} from "react-icons/fa";
import {BiRightArrowAlt} from "react-icons/bi";
import {AiOutlineDownload} from "react-icons/ai";
import {SCHEDULE_FORM} from "./ScheduleSampleData";

export class Schedule extends React.Component {
  constructor(props) {
    super();
    this.onUpdateSchedule = props.onUpdateSchedule || null;
    this.state = {
      schedule: {...SCHEDULE_FORM}
    };
    this.submitSchedule = this.submitSchedule.bind(this);
    this.onChangeSignalForm = this.onChangeSignalForm.bind(this);
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.schedule !== undefined) {
      this.setState(state => state.schedule = nextProps.schedule);
    }
  }

  submitSchedule() {
    if (this.onUpdateSchedule !== null) {
      this.onUpdateSchedule(this.props.stationId, this.state.schedule);
    }
  }

  onChangeSignalForm(target, index, value) {
    let selectedSignalForm = this.state.schedule.selectedSignalForm;
    switch (target ) {
      case 'green':
        this.setState(
          state => {
            state.schedule.signalForm[selectedSignalForm].greenTime[index] = value;
            state.schedule.signalForm[selectedSignalForm].isModified = true;
            return state;
          }
        );
        break;
      case 'yellow':
        this.setState(
          state => {
            state.schedule.signalForm[selectedSignalForm].yellowTime = value;
            state.schedule.signalForm[selectedSignalForm].isModified = true;
            return state;
          }
        );
        break;
      case 'clear':
        this.setState(
          state => {
            state.schedule.signalForm[selectedSignalForm].clearanceTime = value;
            state.schedule.signalForm[selectedSignalForm].isModified = true;
            return state;
          }
        );
        break;
    }
  }

  render() {
    let timeFormList = [];
    let selectedTimeForm = this.state.schedule.selectedTimeForm;
    Array(8).fill(0).map((_, index) => {
      timeFormList.push(
        <Row className={'timeline-row'}>
          <Col>{index + 1}</Col>
          <Col>
            <input
              className='monitor-input-time-active'
              style={{width: '7em'}}
              type="time"
              id="fname"
              name="fname"
              disabled={this.state.schedule.timeForm[selectedTimeForm].oneTime[index].isEnable === false}
              value={
                this.state.schedule.timeForm[selectedTimeForm].oneTime[index].isEnable === false ? '' :
                this.state.schedule.timeForm[selectedTimeForm].oneTime[index].start
              }
              onChange={(event) => {
                this.setState(state => state.schedule.timeForm[selectedTimeForm].oneTime[index].start = event.target.value)
              }}
            />
          </Col>
          <Col>
            <select
              value={this.state.schedule.timeForm[selectedTimeForm].oneTime[index].target}
              onChange={(event) => {
                this.setState(state => state.schedule.timeForm[selectedTimeForm].oneTime[index].target = event.target.value)
              }}
              disabled={this.state.schedule.timeForm[selectedTimeForm].oneTime[index].isEnable === false}
            >
              {
                Array(32).fill(0).map((_, i) => (
                  <option value={i}>Chiến lược {i + 1}</option>
                ))
              }
            </select>
          </Col>
          <Col>
            <input
              type={'checkbox'}
              checked={this.state.schedule.timeForm[selectedTimeForm].oneTime[index].isEnable}
              onChange={event => {
                this.setState(state => state.schedule.timeForm[selectedTimeForm].oneTime[index].isEnable = event.target.checked)
              }}
            />
          </Col>
        </Row>
      )
    })

    let nSide = this.state.schedule.signalForm[this.state.schedule.selectedSignalForm].nSide;
    let ratio = nSide === 2 ? 3 : 2;

    return(
        <Container className='component' style={{
          paddingTop: '1.5em',
          marginTop: '0.5em',
          backgroundColor: '#ececec'
        }}>
          <Row>
            <Col>
              <Container className='monitor-station-title'>
                <p className='monitor-station-title-text'>
                  Chiến lược theo ngày
                </p>
              </Container>
            </Col>
          </Row>

          <Row>
            <Col style={{
              maxWidth: '55em',
              margin: 'auto',
              maxHeight: '20em',
              padding: '1em',
            }}>
              <Row className={'timeline-row'}>
                <Col style={{fontWeight: 'bolder', borderRight: '1px solid'}}>Ngày</Col>
                <Col style={{fontWeight: 'bolder'}}>Thứ 2</Col>
                <Col style={{fontWeight: 'bolder'}}>Thứ 3</Col>
                <Col style={{fontWeight: 'bolder'}}>Thứ 4</Col>
                <Col style={{fontWeight: 'bolder'}}>Thứ 5</Col>
                <Col style={{fontWeight: 'bolder'}}>Thứ 6</Col>
                <Col style={{fontWeight: 'bolder'}}>Thứ 7</Col>
                <Col style={{fontWeight: 'bolder'}}>Chủ nhật</Col>
              </Row>
              <Row className={'timeline-row'}>
                <Col style={{borderRight: '1px solid'}}>Áp dụng</Col>
                {
                  Array(7).fill(0).map((item, index) => (
                    <Col style={{fontWeight: 'bolder'}}>
                      <select
                        value={this.state.schedule.dateForm[(index + 1) % 7]}
                        onChange={(event) => {
                          this.setState(state => state.schedule.dateForm[(index + 1) % 7] = event.target.value)
                        }}
                      >
                        <option value={255}>Không</option>
                        {
                          Array(8).fill(0).map((_, i) => (
                            <option value={i}>Mẫu {i + 1}</option>
                          ))
                        }
                      </select>
                    </Col>
                  ))
                }
              </Row>
            </Col>
          </Row>

          <Row style={{padding: '0.8em 0em 1em'}}>
            <Col>
              <Container className='monitor-station-title'>
                <p className='monitor-station-title-text'>
                  Biểu mẫu thời gian
                </p>
              </Container>
            </Col>
          </Row>

          <Row style={{paddingBottom: '1em'}}>
            <Col>
              <select
                value={this.state.schedule.selectedTimeForm}
                onChange={(event) => {
                this.setState(state => state.schedule.selectedTimeForm = event.target.value)
              }}
              >
                {
                  this.state.schedule.timeForm.map((item, i) => (
                    <option value={i}>Biểu mẫu số {i + 1}</option>
                  ))
                }
              </select>
            </Col>
          </Row>

          <Row>
            <Col style={{
              maxWidth: '45em',
              margin: 'auto',
              maxHeight: '15em',
              overflowY: 'scroll',
              border: '1px solid #bebebe',
              padding: '1em',
              borderRadius: '0.5em'
            }}>
              <Row className={'timeline-row'}>
                {/*<Col style={{fontWeight: 'bolder'}}>Mẫu thời gian</Col>*/}
                <Col style={{fontWeight: 'bolder'}}>Mốc thời gian</Col>
                <Col style={{fontWeight: 'bolder'}}>Thời gian bắt đầu</Col>
                <Col style={{fontWeight: 'bolder'}}>Chiến lược đèn</Col>
                <Col style={{fontWeight: 'bolder'}}>Sử dụng</Col>
              </Row>
              {timeFormList}
            </Col>
          </Row>

          <Row style={{paddingTop: '1.8em'}}>
            <Col>
              <Container className='monitor-station-title'>
                <p className='monitor-station-title-text'>
                  Chiến lược đèn tín hiệu
                </p>
              </Container>
            </Col>
          </Row>
          <Row style={{paddingTop: '1em'}}>
            <Col>
              <select onChange={(event) => {
                this.setState(state => state.schedule.selectedSignalForm = event.target.value)
              }}>
                {
                  this.state.schedule.signalForm.map((item, i) => (
                    <option value={i}>Chiến lược số {i + 1}</option>
                  ))
                }
              </select>
            </Col>
          </Row>
          <Row style={{padding: '1em'}}>
            <Col style={{margin: 'auto'}}>
              <Row style={{margin: 'auto', width: '35em', paddingBottom: '1em'}}>
                <Col xs={3}/>
                <Col xs={ratio} style={{fontWeight: 'bold'}}>Pha 1</Col>
                <Col xs={ratio} style={{fontWeight: 'bold'}}>Pha 2</Col>
                <Col xs={2} style={{fontWeight: 'bold'}}>{nSide >= 3 ? 'Pha 3' : ''}</Col>
                <Col xs={2} style={{fontWeight: 'bold'}}>{nSide === 4 ? 'Pha 4' : ''}</Col>
                {/*<Col style={{fontWeight: 'bold'}}>Pha 3</Col>*/}
              </Row>
              <Row style={{margin: 'auto', width: '35em'}}>
                <Col xs={3} style={{textAlign: 'right'}}>
                  <FaCircle size={25} color={'rgba(104, 214, 36, 0.79)'}/>
                  <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
                    Xanh
                  </p>
                </Col>
                {
                  Array(nSide).fill(0).map((_, index) => (
                    <Col xs={ratio}>
                      <input
                        className='monitor-input-time-normal'
                        type="number"
                        id="fname"
                        name="fname"
                        value={this.state.schedule.signalForm[this.state.schedule.selectedSignalForm].greenTime[index]}
                        min={5}
                        max={255}
                        onChange={(event) => this.onChangeSignalForm('green', index, event.target.value)}
                      />
                    </Col>
                  ))
                }
                {
                  Array(4 - nSide).fill(0).map((_, index) => (
                    <Col />
                  ))
                }
              </Row>
              <Row style={{margin: 'auto', width: '35em'}}>
                <Col xs={3} style={{textAlign: 'right'}}>
                  <FaCircle size={25} color={'#D1D84B'}/>
                  <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
                    Vàng
                  </p>
                </Col>
                <Col xs={nSide * ratio}>
                  <input
                    className='monitor-input-time-normal'
                    type="number"
                    id="fname"
                    name="fname"
                    min={2}
                    max={10}
                    value={this.state.schedule.signalForm[this.state.schedule.selectedSignalForm].yellowTime}
                    onChange={(event) => this.onChangeSignalForm('yellow', 0, event.target.value)}
                  />
                </Col>
              </Row>
              <Row style={{margin: 'auto', width: '35em'}}>
                <Col xs={3} style={{textAlign: 'right'}}>
                  <p style={{display: 'inline-table', marginLeft: '0.5em'}}>
                    Giải toả
                  </p>
                </Col>
                <Col xs={nSide * ratio}>
                  <input
                    className='monitor-input-time-normal'
                    type="number"
                    id="fname"
                    name="fname"
                    min={0}
                    max={10}
                    value={this.state.schedule.signalForm[this.state.schedule.selectedSignalForm].clearanceTime}
                    onChange={(event) => this.onChangeSignalForm('clear', 0, event.target.value)}
                  />
                </Col>
                {/*<Col />*/}
                {/*<Col />*/}
                {/*<Col />*/}
              </Row>
              <Row style={{paddingTop: '1em'}}>
                <Col>
                  <Button variant={'primary'} onClick={() => this.submitSchedule()}>
                    Xác nhận
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
    )
  }
}

export function convertScheduleModel(response) {
  let ret = {...SCHEDULE_FORM};
  ret.dateForm = [255, 255, 255, 255, 255, 255, 255];
  let cycle = response.getCycle() ? response.getCycle().getIndexList() : undefined;
  let days = response.getDays() ? response.getDays().getIndexList() : undefined;
  let time = response.getTimelampform() ? response.getTimelampform().getIndexList() : undefined;

  if (days !== undefined) {
    console.log(days, days.length)
  } else {
    console.log("invalid days")
  }
  if (days !== undefined && days.length > 0 && days.length <= ret.dateForm.length) {
    days.forEach((item, i) => {
      console.log("day:", item);
      ret.dateForm[i] = item;
    })
  }

  if (cycle !== undefined && cycle.length > 0 && cycle.length <= ret.signalForm.length) {
    cycle.forEach((item, i) => {
      ret.signalForm[i].nSide = item.getNumSideUsed();
      ret.signalForm[i].clearanceTime = item.getClearanceTimeCrossroads();
      for (let j = 0; j < Math.min(ret.signalForm[i].nSide, 4); j++) {
        ret.signalForm[i].greenTime[j] = item.getSideList()[j].getTGreen();
      }
      ret.signalForm[i].yellowTime = item.getSideList()[0].getTYellow();
      ret.signalForm[i].isModified = false;
    })
  }

  if (time !== undefined && time.length > 0 && time.length <= ret.signalForm.length) {
    time.forEach((item, i) => {
      if (item.getSelectpointList() !== undefined && item.getSelectpointList().length > 0) {
        item.getSelectpointList().forEach((point, index) => {
          console.log("[%d]: begin %d:%d, target: %d", index, point.getTBeginApply().getHour(), point.getTBeginApply().getMinute(), point.getIndexCycleForm())
          let hour = point.getTBeginApply().getHour();
          let minute = point.getTBeginApply().getMinute();

          ret.timeForm[i].oneTime[index].isEnable = point.getIndexCycleForm() < 32;

          if (hour > 23 || minute > 59) {
            ret.timeForm[i].oneTime[index].start = '';
          } else if (point.getIndexCycleForm() <= 32) {
            ret.timeForm[i].oneTime[index].start = hour.toString().padStart(2, '0') + ':' + minute.toString().padStart(2, '0');
          }

          if (point.getIndexCycleForm() < 32) {
            ret.timeForm[i].oneTime[index].target = point.getIndexCycleForm();
          }
        })
      }
    })
  }

  return ret;
}